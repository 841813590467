import React, { useState } from "react";
import Cross from "../../assets/images/icons/cross.png";
import { connect } from "react-redux";
import { addFormFields } from "../../redux/survey/SurveyActions";
import { TextField, Checkbox, Radio } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const FormBodyQs = ({ qtype, uId, addFormFields, form, page }) => {
  const [otherState, setOtherState] = useState({
    id: null,
    state: true,
  });

  const [data, setData] = useState(form);

  const onTextChange = (e, id) => {
    var newData = data;
    const index = data.formFields.findIndex((item) => item.id === id);
    newData.formFields[index].label = e.target.value;
    setData(newData);
    addFormFields(uId, newData.formFields, page);
  };

  const onOptionAdd = () => {
    let newOption;
    newOption = {
      id: uuidv4(),
      label: "",
    };
    if (data.formFields === null || data.formFields === undefined) {
      data.formFields = [newOption];
      setData(data);
    } else {
      data.formFields.push(newOption);
      setData(data);
    }
    addFormFields(uId, data.formFields, page);
  };

  const onOptionDelete = (id) => {
    let newQList = data;
    let index = newQList.formFields.findIndex((item) => item.id === id);
    newQList.formFields.splice(index, 1);
    addFormFields(uId, newQList.formFields, page);
    if (id === otherState.id) {
      setOtherState({ id: null, state: true });
    }
  };

  const onOtherCheck = (e) => {
    if (e.target.checked) {
      let newOption;
      newOption = {
        id: uuidv4(),
        label: "Other",
        disabled: true,
      };
      if (data.formFields === null) {
        data.formFields = [newOption];
        setData(data);
      } else {
        data.formFields.push(newOption);
        setData(data);
      }
      addFormFields(uId, data.formFields, page);
      setOtherState({ id: newOption.id, state: false });
    }
  };

  const handleOnDragEnd = (result, page) => {
    if (!result.destination) return;
    else {
      let defaultData = form;
      const items = Array.from(defaultData.formFields);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      defaultData.formFields = items;
      addFormFields(uId, defaultData.formFields, page);
    }
  };

  return (
    <React.Fragment>
      <DragDropContext onDragEnd={(result) => handleOnDragEnd(result, page)}>
        <Droppable droppableId="form-qs">
          {(provided) => (
            <div
              className="unique-qs-list"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {data &&
                data.formFields &&
                data.formFields.map((item, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className="qs"
                          key={item.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div
                            className="form-group-alt"
                            {...provided.placeholder}
                            {...provided.dragHandleProps}
                          >
                            {qtype === "dropdown" ? (
                              <DragIndicatorIcon
                                color="primary"
                                style={{ marginTop: "8px", marginRight: "5px" }}
                              />
                            ) : qtype === "checkbox" ? (
                              <Checkbox
                                {...provided.dragHandleProps}
                                color="primary"
                                size="small"
                                disabled
                              />
                            ) : (
                              <Radio
                                {...provided.dragHandleProps}
                                color="primary"
                                size="small"
                                disabled
                              />
                            )}
                            <TextField
                              className="qs-label"
                              placeholder={item.disabled ? "Other " : "Option "}
                              onChange={(e) => onTextChange(e, item.id)}
                              disabled={item.disabled}
                              defaultValue={item.label}
                            />
                            <div
                              className="cross-btn"
                              onClick={() => onOptionDelete(item.id)}
                            >
                              <img src={Cross} alt="icon" />
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="qs-activity">
        <h6 onClick={() => onOptionAdd()}>Add More</h6>
        {otherState.state && (
          <div className="qs-activity-alt">
            <input type="checkbox" onClick={(e) => onOtherCheck(e)} />
            <label>Other</label>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  survey: state.survey,
});

export default connect(mapStateToProps, { addFormFields })(FormBodyQs);
