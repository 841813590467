import React, { useEffect, useState } from "react";
import FormBodyQs from "./FormBodyQs";
import FormBodyFlexQs from "./FormBodyFlexQs";
import { connect, useDispatch } from "react-redux";
import { Checkbox, Grid, Box, TextField } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { addFormFields } from "../../redux/survey/SurveyActions";

const RatingScale = ({ uId, page, form }) => {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dispatch = useDispatch();

  const onScaleChange = (e, type) => {
    let val = e.target.value;

    if (type === "type") {
      let object = {
        type: val,
        number: form.formFields === null ? 0 : form.formFields.number,
      };
      dispatch(addFormFields(uId, object, page));
    } else {
      let object = {
        type: form.formFields === null ? "star" : form.formFields.type,
        number: val,
      };
      dispatch(addFormFields(uId, object, page));
    }
  };

  return (
    <div className="qstype-container">
      <div className="qs">
        <Grid component={Box} marginBottom="15px" container>
          <Grid item>
            <select
              className="fc-dropdown"
              defaultValue={form.formFields === null ? "star" : form.formFields.type}
              onChange={(e) => onScaleChange(e, "type")}
            >
              <option className="fc-dropdown-toggle" value="star">
                &#11088; Star
              </option>
              <option value="number"> &#x23; Number</option>
            </select>
          </Grid>
          <Grid item>
            <div className="form-group-alt">
              <label className="form-card-label range-label">Select Range</label>
              <select
                className="fc-dropdown"
                defaultValue={form.formFields === null ? 0 : form.formFields.number}
                onChange={(e) => onScaleChange(e, "number")}
              >
                <option className="fc-dropdown-toggle" value={0}>
                  0
                </option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
              </select>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const RangeSlider = ({ uId, page, form }) => {
  const [range, setRange] = useState({
    min: form.formFields === null ? 0 : form.formFields.min,
    max: form.formFields === null ? 0 : form.formFields.max,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => dispatch(addFormFields(uId, range, page)));
  }, [range]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="qstype-container">
      <div className="qs" style={{ marginBottom: "25px" }}>
        <Grid container>
          <Grid item xs={6}>
            <div className="form-group-alt">
              <label className="form-card-label">Start</label>
              <input
                type="number"
                min={0}
                defaultValue={form.formFields === null ? 0 : form.formFields.min}
                onChange={(e) => setRange({ ...range, min: e.target.value })}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="form-group-alt">
              <label className="form-card-label">End</label>
              <input
                type="number"
                min={0}
                defaultValue={form.formFields === null ? 0 : form.formFields.max}
                onChange={(e) => setRange({ ...range, max: e.target.value })}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const FormBody = ({ type, uId, form, page }) => {
  const [time, setTime] = useState();
  const [date, setDate] = useState();

  const handleDateChange = (date) => {
    setDate(date);
  };
  const handleTimeChange = (time) => {
    setTime(time);
  };

  if (type === "Multiple Choice") {
    return (
      <div className="qstype-container">
        <FormBodyQs qtype="radio" uId={uId} form={form} page={page} />
      </div>
    );
  } else if (type === "Checkbox") {
    return (
      <div className="qstype-container">
        <FormBodyQs qtype="checkbox" uId={uId} form={form} page={page} />
      </div>
    );
  } else if (type === "Dropdown") {
    return (
      <div className="qstype-container">
        <FormBodyQs qtype="dropdown" uId={uId} form={form} page={page} />
      </div>
    );
  } else if (type === "Range Slider") {
    return <RangeSlider uId={uId} page={page} form={form} />;
  } else if (type === "Rating Scale") {
    return <RatingScale uId={uId} page={page} form={form} />;
  } else if (type === "Text") {
    return (
      <div className="qstype-container">
        <div className="qs">
          <div className="form-group-alt">
            <label className="form-card-label">Enter word limitation</label>
            <TextField type="text" className="qs-text-input form-input-alt" placeholder=" Example : 250" />
          </div>
          <div className="form-group-alt">
            <label className="form-card-label">
              {" "}
              <Checkbox color="primary" size="small" /> No Limitation{" "}
            </label>
          </div>
        </div>
      </div>
    );
  } else if (type === "Date & Time") {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="qstype-container">
          <div className="qs">
            <Grid container justifyContent="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Date picker"
                format="MM/dd/yyyy"
                onChange={handleDateChange}
              />
              <KeyboardTimePicker margin="normal" id="time-picker" label="Time picker" onChange={handleTimeChange} />
            </Grid>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  } else if (type === "M-Multiple choice") {
    return (
      <div className="qstype-container">
        <FormBodyFlexQs qtype="radio" uId={uId} form={form} page={page} />
      </div>
    );
  } else if (type === "M-Checkbox") {
    return (
      <div className="qstype-container">
        <FormBodyFlexQs qtype="checkbox" uId={uId} form={form} page={page} />
      </div>
    );
  } else {
    return <div>Unsupported</div>;
  }
};

const mapStateToProps = (state) => ({
  survey: state.survey,
});
export default connect(mapStateToProps, { addFormFields })(FormBody);
