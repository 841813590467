import { Paper, Typography, Box, LinearProgress, Switch,useMediaQuery } from "@material-ui/core";
import Profile from "../../assets/images/icons/navbar-profile-icon.png";
import { useTheme } from "@material-ui/core/styles";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUser, deleteSubscription } from "../../redux/user/UserActions";

const ProfilePage = ({ user,getUser, deleteSubscription, history }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    getUser();
  },[])

  const onSwitchChange = (ev) => {
    if(ev.target.checked){
      history.push("/pricing")
    } else {
      deleteSubscription(user.user[0].subscriptions_user.subscription_id);
    }
  }

  return (
    <Paper style={{ padding: "25px", minHeight: "100vh" }}>
      {user.user !== null && (
        <Box style={{ marginRight: "auto", marginLeft: "auto", maxWidth: "1240px" }}>
          <Box paddingY={2}>
            <Typography style={{ fontSize: "24px", fontWeight: "700" }}>
              Profile Settings
            </Typography>
          </Box>
          <Box style={{ display: "flex", flexDirection : `${matches ? "column" : "row"}`, justifyContent: "center", alignItems : "center", textAlign: "center", marginLeft: "15px", marginTop: "30px" }}>
            <Box style={{ width: "100px" }}>
              <img src={Profile} style={{ height: "70px", width: "70px" }} alt="profile"></img>
            </Box>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontWeight: "bold", marginBottom: "8px" }}>
                {user.user[0].first_name + " " + user.user[0].last_name}
              </span>
              <span style={{ color: "#8094ae", fontWeight: "400" }}>{user.user[0].email}</span>
            </Box>
          </Box>

          <Box marginTop={5} marginBottom={5}>
            <Box>
              <span style={{ color: "#8094ae", letterSpacing: "0.2rem", fontWeight: "700" }}>SUBSCRIPTION PLAN</span>
              <div style={{ background: "#dbdfea", height: "1px", width: "100%" }}></div>
            </Box>
            <Box display={"flex"} justifyContent="space-between" marginY={3}>
              <Box display={"flex"} flexDirection="column">
                <Box display="flex" marginTop={1}>
                  <AllInboxIcon color="primary" />
                  <span style={{ fontWeight: "bold", marginLeft: "8px" }}>
                    {user.user[0].subscriptions_user === null
                      ? "Free"
                      : user.user[0].subscriptions_user.is_monthly_sub_active
                      ? "Monthly"
                      : user.user[0].subscriptions_user.is_monthly_sub_active ? "One Time " : "Yearly"}{" "}
                    Plan
                  </span>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Link
                  to={"/pricing"}
                  className="links"
                  style={{ color: "#0085a4", fontWeight: "500", marginRight: "25px" }}
                >
                  See all plans
                </Link>
              </Box>
            </Box>
          </Box>

          <Box>
            <Box>
              <span style={{ color: "#8094ae", letterSpacing: "0.2rem", fontWeight: "700" }}>PERSONAL INFORMATION</span>
              <div style={{ background: "#dbdfea", height: "1px", width: "100%" }}></div>
            </Box>
            <Box
              display="flex"
              flexDirection={matches ? "column" : "row"}
              justifyContent="space-between"
              width="100%"
              marginY={2}
              borderBottom="1px solid #dbdfea"
              paddingY={2}
            >
              <Box width="120px">Recovery Mail</Box>
              <Box textAlign="left">
                <span>{user.user[0].email}</span>
              </Box>
              <Box>
                {/* <Link
                  to={"/pricing"}
                  className="links"
                  style={{ color: "#0085a4", fontWeight: "500", marginRight: "25px" }}
                >
                  Change Mail
                </Link> */}
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection={matches ? "column" : "row"}
              justifyContent="space-between"
              width="100%"
              marginY={2}
              borderBottom="1px solid #dbdfea"
              paddingY={2}
            >
              <Box width="120px">Username</Box>
              <Box textAlign="left">
                {" "}
                <span>{user.user[0].username}</span>
              </Box>
              <Box>
                {/* <Link
                  to={"/pricing"}
                  className="links"
                  style={{ color: "#0085a4", fontWeight: "500", marginRight: "25px" }}
                >
                  Change username
                </Link> */}
              </Box>
            </Box>
            <Box display="flex" flexDirection={matches ? "column" : "row"} justifyContent="space-between" width="100%" marginY={2} paddingY={2}>
              <Box width="120px">Password</Box>
              <Box textAlign="left">
                <span>*********</span>
              </Box>
              <Box>
                <Link
                  to={"/change-password"}
                  className="links"
                  style={{ color: "#0085a4", fontWeight: "500", marginRight: "25px" }}
                >
                  Change Password
                </Link>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box>
              <span style={{ color: "#8094ae", letterSpacing: "0.2rem", fontWeight: "700" }}>ACCOUNT SETTINGS</span>
              <div style={{ background: "#dbdfea", height: "1px", width: "100%" }}></div>
            </Box>

            <Box display="flex" justifyContent="space-between" width="100%" marginY={2} paddingY={2}>
              <Box width="120px">Subscription</Box>
              <Box>
                <Switch color="primary" defaultChecked={user.user[0].subscriptions_user === null ? false : true} onChange={(ev ) => onSwitchChange(ev) }/>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, { getUser, deleteSubscription })(ProfilePage);
