import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from "../../redux/Utils";
import AuthNav from "../navbar/auth/AuthNav";

const PrivateRoute = ({
  exact,
  component: Component,
  auth,
  container,
  surveyPage,
  ...rest
}) => {
  return (
    <Route
      exact={exact ? true : false}
      rest
      render={(props) =>
        isLoggedIn() ? (
          <div>
            {!container && <AuthNav surveyPage={surveyPage} />}
            <Component {...props} {...rest}></Component>
          </div>
        ) : (
          <Redirect to="/login"></Redirect>
        )
      }
    ></Route>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, null)(PrivateRoute);
