export const ON_TITLE_CHANGE = "ON_TITLE_CHANGE";
export const ON_MAIN_TITLE = "ON_MAIN_TITLE";
export const ON_COVER_CHANGE = "ON_COVER_CHANGE";
export const ON_DESC_CHANGE = "ON_DESC_CHANGE";
export const DATA_CHANGE = "DATA_CHANGE";
export const FORM_TYPE_CHANGE = "FORM_TYPE_CHANGE";
export const ADD_FORM_FIELD = "ADD_FORM_FIELD";
export const ADD_FLEX_FORM = "ADD_FLEX_FORM";
export const ON_QUESTION_CHANGE = "ON_QUESTION_CHANGE";
export const POST_SURVEY_SUCCESS = "POST_SURVEY_SUCCESS";
export const POST_SURVEY_FAILED = "POST_SURVEY_FAILED";
export const DELETE_PAGE = "DELETE_PAGE";
export const LOADING = "LOADING";
export const GET_ALL_SURVEY = "GET_ALL_SURVEY";
export const GET_SURVEY = "GET_SURVEY";
export const ON_TAB_CHANGE = "ON_TAB_CHANGE";
export const SET_PUBLISH = "SET_PUBLISH";
export const ADD_PAGE = "ADD_PAGE";
export const SET_REQUIRED = "SET_REQUIRED";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const ADD_ATTACHMENT = "ADD_ATTACHMENT";
export const EDIT_SURVEY = "EDIT_SURVEY";
export const RESET_SLUG = "RESET_SLUG";
