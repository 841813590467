export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOG_OUT = "LOG_OUT";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILED = "SIGN_UP_FAILED";
export const LOADING = "LOADING";
export const AUTHENTICATED_SUCCESS = "AUTHENTICATED_SUCCESS";
export const AUTHENTICATED_FAILED = "AUTHENTICATED_FAILED";
export const RESET_PASS = "RESET_PASS";
export const RESET_ERROR = "RESET_ERROR";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
