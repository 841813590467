export const SET_SLUG = "SET_SLUG";
export const ADD_RESPONSE = "ADD_RESPONSE";
export const SET_IP = "SET_IP";
export const LOADING = "LOADING";
export const SUBMIT_RESPONSE = "SUBMIT_RESPONSE";
export const SET_TAB = "SET_TAB";
export const GET_RESPONSE = "GET_RESPONSE";
export const GET_ALL_RESPONSE = "GET_ALL_RESPONSE";
export const SUBMIT_RESPONSE_FAILURE = "SUBMIT_RESPONSE_FAILURE";
export const GET_SENTI = "GET_SENTI";
export const GET_WORD_CLOUD = "GET_WORD_CLOUD";
export const RESET_RESPONSE = "RESET_RESPONSE";
