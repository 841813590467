import React, { useState } from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { IconButton, MenuItem, Menu } from "@material-ui/core";
import { svQList } from "../../data/Data";
import { connect } from "react-redux";
import { addPage } from "../../redux/survey/SurveyActions";

const AddQuestionButton = ({ addQuestion, uId, initial, addPage, page }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="hover-item-container">
      <div
        className="dotted-hover"
        style={{ backgroundColor: "transparent" }}
      ></div>
      <div className="hover-add-form-btn">
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <AddCircleIcon color="primary" />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {svQList.map((item, index) => {
            return (
              <MenuItem
                key={index}
                value={item.text}
                onClick={
                  item.text === "Section"
                    ? () => {
                        addPage();
                        handleClose();
                      }
                    : () => {
                        addQuestion(item.text, uId, initial, page);
                        handleClose();
                      }
                }
              >
                <div className="fc-menu-item">
                  <span className="menu-item-icon">{item.icon}</span>
                  <span className="menu-item-text">{item.text}</span>
                </div>
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    </div>
  );
};

export default connect(null, { addPage })(AddQuestionButton);
