import React from "react";
import { Box, Select, MenuItem } from "@material-ui/core";

const QuestionSelect = ({ qList, qVal, setQval }) => {
  const handleChange = (e) => {
    const value = e.target.value;
    let found = qList.find((item) => item.question === value);
    setQval(found);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Select
        variant="outlined"
        color="primary"
        labelStyle={{ color: "green" }}
        style={{ height: "50px" }}
        defaultValue={qVal === undefined ? "" : qVal.question}
        onChange={handleChange}
      >
        {qList &&
          qList.map((item, key) => {
            return (
              <MenuItem key={key} value={item.question}>
                <div className="fc-menu-item">
                  <span>
                    Q{key + 1}.{item.question}
                  </span>
                </div>
              </MenuItem>
            );
          })}
      </Select>
    </Box>
  );
};

export default QuestionSelect;
