import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined";
import DragIndicatorOutlinedIcon from "@material-ui/icons/DragIndicatorOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import StarOutlineOutlinedIcon from "@material-ui/icons/StarOutlineOutlined";
import ArrowDropDownCircleOutlinedIcon from "@material-ui/icons/ArrowDropDownCircleOutlined";
import SubjectOutlinedIcon from "@material-ui/icons/SubjectOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import BarDot from "../assets/images/icons/range-slider.png";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { v4 as uuidv4 } from "uuid";

export const FormData = [
  {
    id: 1,
    page: 1,
    forms: [
      {
        id: uuidv4(),
        selectedDropdown: "Text",
        formFields: null,
      },
      {
        id: uuidv4(),
        selectedDropdown: "Text",
        formFields: null,
      },
    ],
  },
];

export const svQList = [
  {
    icon: (
      <FiberManualRecordOutlinedIcon
        className="responsive-icon"
        fontSize="small"
      />
    ),
    text: "Multiple Choice",
  },
  {
    icon: <CheckBoxOutlinedIcon className="responsive-icon" fontSize="small" />,
    text: "Checkbox",
  },
  {
    icon: <img src={BarDot} alt="" />,
    text: "Range Slider",
  },
  {
    icon: <SubjectOutlinedIcon className="responsive-icon" fontSize="small" />,
    text: "Text",
  },
  {
    icon: (
      <StarOutlineOutlinedIcon className="responsive-icon" fontSize="small" />
    ),
    text: "Rating Scale",
  },
  {
    icon: (
      <ArrowDropDownCircleOutlinedIcon
        className="responsive-icon"
        fontSize="small"
      />
    ),
    text: "Dropdown",
  },
  {
    icon: (
      <CalendarTodayOutlinedIcon className="responsive-icon" fontSize="small" />
    ),
    text: "Date & Time",
  },
  {
    icon: (
      <DragIndicatorOutlinedIcon className="responsive-icon" fontSize="small" />
    ),
    text: "M-Multiple choice",
  },
  {
    icon: (
      <DragIndicatorOutlinedIcon className="responsive-icon" fontSize="small" />
    ),
    text: "M-Checkbox",
  },
  {
    icon: <ListAltIcon className="responsive-icon" fontSize="small" />,
    text: "Section",
  },
];

export const bardata = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

export const piedata = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

export const pieoptions = {
  title: {
    display: true,
    text: "Chart.js Horizontal Bar Chart",
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right",
      labels: {
        fontColor: "black",
        boxWidth: 20,
        padding: 20,
      },
    },
  },
};

export const baroptions = {
  indexAxis: "y",
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: "Questions",
        font: {
          size: 15,
        },
      },
      grid: {
        display: false,
      },
    },
    y: {
      display: true,
      title: {
        display: true,
        text: "Responses",
        font: {
          size: 15,
        },
      },
      grid: {
        display: false,
      },
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "right",
    },
    title: {
      display: true,
      text: "Chart.js Horizontal Bar Chart",
    },
  },
};

export const defaultQuestion = [
  "Whose dog is barking ?",
  "Who is the current president of United States of America",
  "Who is the current president of United States of England",
];

export const boxPlotValues = [
  14, 15, 16, 16, 17, 17, 17, 17, 17, 18, 18, 18, 18, 18, 18, 19, 19, 19, 20,
  20, 20, 20, 20, 20, 21, 21, 22, 23, 24, 24, 29,
];

export const randomNumbers = [
  100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111,
];

export const dougnutData = {
  labels: ["Red", "Green", "Yellow"],
  datasets: [
    {
      data: [300, 50, 100],
      backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
    },
  ],
  text: "23%",
};

export const boxplotOptions = {
  series: [
    {
      type: "boxPlot",
      data: [
        {
          x: "",
          y: [54, 66, 69, 55, 88],
        },
      ],
    },
  ],
  chart: {
    type: "boxPlot",
    height: 350,
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    show: false,
  },
  yaxis: {
    show: false,
  },
  plotOptions: {
    boxPlot: {
      colors: {
        upper: "#0085a4",
        lower: "rgba(0, 133, 164, 0.15)",
      },
    },
  },
};
