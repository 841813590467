import {
  DATA_CHANGE,
  ON_COVER_CHANGE,
  ON_DESC_CHANGE,
  ON_TITLE_CHANGE,
  FORM_TYPE_CHANGE,
  ADD_FORM_FIELD,
  ON_QUESTION_CHANGE,
  ADD_FLEX_FORM,
  POST_SURVEY_SUCCESS,
  POST_SURVEY_FAILED,
  LOADING,
  GET_ALL_SURVEY,
  ON_TAB_CHANGE,
  ADD_PAGE,
  SET_PUBLISH,
  SET_CURRENT_PAGE,
  GET_SURVEY,
  ADD_ATTACHMENT,
  EDIT_SURVEY,
  RESET_SLUG,
  ON_MAIN_TITLE,
  DELETE_PAGE,
  SET_REQUIRED,
} from "./SurveyTypes";

const INITIAL_STATE = {
  slug: null,
  editSlug: null,
  published: false,
  mainTitle: "Survey title",
  currentPage: 1,
  forms: [
    {
      id: 1,
      page: 1,
      title: "",
      desc: "",
      coverImg: null,
      forms: [],
      attachment: null,
    },
  ],
  attachments: [],
  tab: 1,
  loading: false,
  allSurvey: null,
  survey: null,
};

export default function SurveyReducer(state = INITIAL_STATE, action) {
  var index;
  var defaultForms;
  var page;
  switch (action.type) {
    case ON_TAB_CHANGE:
      return {
        ...state,
        tab: action.payload,
      };
    case ON_MAIN_TITLE:
      return {
        ...state,
        mainTitle: action.payload,
      };
    case ON_TITLE_CHANGE:
      defaultForms = state.forms;
      page = defaultForms.find((item) => item.page === action.payload.page);
      page.title = action.payload.text;
      return {
        ...state,
        forms: defaultForms,
      };
    case ON_COVER_CHANGE:
      defaultForms = state.forms;
      page = defaultForms.find((item) => item.page === action.payload.page);
      page.coverImg = action.payload.file;
      return {
        ...state,
        forms: defaultForms,
      };
    case ON_DESC_CHANGE:
      defaultForms = state.forms;
      page = defaultForms.find((item) => item.page === action.payload.page);
      page.desc = action.payload.text;
      return {
        ...state,
        forms: defaultForms,
      };
    case DATA_CHANGE:
      return {
        ...state,
        forms: action.payload,
      };
    case FORM_TYPE_CHANGE:
      defaultForms = state.forms;
      var defaultPage = defaultForms.find(
        (item) => item.page === state.currentPage
      );
      index = defaultPage.forms.findIndex(
        (item) => item.id === action.payload.id
      );
      defaultPage.forms[index].selectedDropdown = action.payload.type;
      defaultPage.forms[index].formFields = null;

      return {
        ...state,
        forms: defaultForms,
      };
    case ADD_FORM_FIELD:
    case ADD_FLEX_FORM:
      defaultForms = state.forms;
      defaultPage = defaultForms.find(
        (item) => item.page === action.payload.page
      );
      index = defaultPage.forms.findIndex(
        (item) => item.id === action.payload.id
      );
      defaultPage.forms[index].formFields = action.payload.object;
      return {
        ...state,
        forms: defaultForms,
      };
    case ON_QUESTION_CHANGE:
      defaultForms = state.forms;
      defaultPage = defaultForms.find(
        (item) => item.page === action.payload.page
      );
      index = defaultPage.forms.findIndex(
        (item) => item.id === action.payload.id
      );
      defaultPage.forms[index].question = action.payload.value;
      return {
        ...state,
        forms: defaultForms,
      };
    case SET_REQUIRED:
      defaultForms = state.forms;
      defaultPage = defaultForms.find(
        (item) => item.page === action.payload.page
      );
      index = defaultPage.forms.findIndex(
        (item) => item.id === action.payload.id
      );
      defaultPage.forms[index].required = action.payload.value;
      return {
        ...state,
        forms: defaultForms,
      };
    case ADD_ATTACHMENT:
      defaultForms = state.forms;
      defaultPage = defaultForms.find(
        (item) => item.page === action.payload.page
      );
      index = defaultPage.forms.findIndex(
        (item) => item.id === action.payload.id
      );
      defaultPage.forms[index].attachment = action.payload.object;
      return {
        ...state,
        forms: defaultForms,
      };
    case POST_SURVEY_SUCCESS:
      return {
        ...state,
        slug: action.payload,
        loading: false,
      };
    case POST_SURVEY_FAILED:
      return {
        ...state,
        loading: false,
      };
    case EDIT_SURVEY:
      return {
        ...state,
        forms: action.payload.forms,
        editSlug: action.payload.slug,
        mainTitle: action.payload.title,
        loading: false,
      };
    case RESET_SLUG:
      return {
        ...state,
        slug: null,
        editSlug: null,
        published: false,
        mainTitle: "Survey title",
        currentPage: 1,
        forms: [
          {
            id: 1,
            page: 1,
            title: "",
            desc: "",
            coverImg: null,
            forms: [],
            attachment: null,
          },
        ],
      };
    case GET_ALL_SURVEY:
      return {
        ...state,
        allSurvey: action.payload,
        loading: false,
      };
    case SET_PUBLISH:
      return {
        ...state,
        published: true,
      };
    case ADD_PAGE:
      defaultForms = state.forms;
      var newPage = {
        title: "",
        desc: "",
        coverImg: null,
        id: defaultForms.length + 1,
        page: defaultForms.length + 1,
        forms: [],
        attachment: null,
      };
      defaultForms = [...defaultForms, newPage];
      return {
        ...state,
        forms: [...defaultForms],
      };
    case GET_SURVEY:
      const { form_data, form_image, form_title, form_desc } =
        action.payload.form_json;

      var formsPerPage = form_data.find(
        (item) => item.id === state.currentPage
      );

      return {
        ...state,
        surveyObj: {
          form_image,
          form_title,
          form_desc,
          form_page: formsPerPage,
          form_data: form_data,
        },
        loading: false,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
        loading: false,
      };
    case DELETE_PAGE:
      defaultForms = state.forms;
      let findPage = defaultForms.find((item) => item.page === action.payload);
      defaultForms = defaultForms.filter((item) => item.id !== findPage.id);
      return {
        ...state,
        forms: [...defaultForms],
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
