import React, { useState } from "react";
import { Form, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import axios from "axios";
import { url } from "../../redux/Utils";
import { toast } from "react-toastify";
import Lock from "../../assets/images/icons/reset-pass-lock.png";
import { Box, Grid, TextField, Button } from "@material-ui/core";

const VerifyEmail = () => {
  const { errors, handleSubmit, register } = useForm();
  const [loading, setLoading] = useState(false);
  const onHandleSubmit = (formData) => {
    setLoading(true);
    axios
      .post(
        url +
          "/rest-auth/registration/account-confirm-email/verify-email/again/",
        formData
      )
      .then((res) => {
        toast.info(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  return (
    <div
      className="at-block"
      style={{
        marginTop: 50,
      }}
    >
      <Box
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        className="reset-container"
      >
        <div className="reset-logo-container">
          <img src={Lock} alt="lock"></img>
        </div>
        <Box>
          <Box margin="50px 0px">
            <h4>Verify Email</h4>
            <p>We will send you a code, please check your provided email</p>
          </Box>
          <Form onSubmit={handleSubmit(onHandleSubmit)}>
            <div className="form-field">
              <TextField
                color="primary"
                size="small"
                name="email"
                variant="outlined"
                label="Email"
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
                fullWidth
                type="text"
                inputRef={register({
                  required: "Email is required.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
              />
            </div>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              size="large"
              fullWidth
            >
              {loading ? (
                <Spinner color="info" />
              ) : (
                <span className="btn-text">Send Link</span>
              )}
            </Button>
          </Form>
          <div className="return-link">
            <Link to="/" className="links">
              <p>Return to login</p>
            </Link>
          </div>
        </Box>
        <div className="form-footer">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="form-footer-grid"
          >
            <Grid item>
              <p className="footer-item">About RevSurvet</p>
            </Grid>
            <Grid item>
              <p className="footer-item">Privacy Notice</p>
            </Grid>
            <Grid item>
              <p className="footer-item">Condition of use</p>
            </Grid>
            <Grid item>
              <p className="footer-item">Need help?</p>
            </Grid>
          </Grid>
          <p className="footer-item"> Copyright &copy; 2021 RevSurvey</p>
        </div>
      </Box>
    </div>
  );
};

export default VerifyEmail;
