import React from "react";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import Slider from "react-slick";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";

const settings2 = {
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
};

const IndividualSlider = ({
  individualResponseId,
  setIndividualResponseId,
  response,
}) => {
  const match = useMediaQuery(`(max-width : 820px)`);
  const xsmatch = useMediaQuery(`(max-width : 460px)`);
  const settings = {
    speed: 500,
    slidesToShow: xsmatch ? 1 : response.length > 5 ? 5 : response.length,
    slidesToScroll: 1,
    centered: true,
    arrows: true,
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={`${match ? "space-between" : "space-evenly"}`}
      marginX={match ? 0 : 10}
    >
      <Box display={{ xs: "none", sm: "block" }}>
        <div
          style={{
            border: "2px solid #0085A4",
            padding: "2px",
            height: "50px",
            width: "50px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "60px",
          }}
        >
          <PersonRoundedIcon color="primary" fontSize="large" />
        </div>
      </Box>
      <Box
        style={{
          height: "35px",
          width: xsmatch ? "30vw" : "250px",
          border: "1px solid #E0E0E0",
          borderRadius: "5px",
          marginTop: "8px",
          padding: "2px 35px",
        }}
      >
        <Slider
          {...settings}
          afterChange={(index) => setIndividualResponseId(index)}
        >
          {response.map((item, index) => (
            <Box
              className={
                individualResponseId === index
                  ? "slider-selected"
                  : "slider-unselected"
              }
              onClick={() => setIndividualResponseId(index)}
            >
              <span
                className={
                  individualResponseId === index
                    ? "slider-text-active"
                    : "slider-text-inactive"
                }
              >
                {index + 1}
              </span>
            </Box>
          ))}
        </Slider>
      </Box>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Box
          style={{
            height: "27px",
            width: "40px",
            marginTop: "12px",
            borderBottom: "1px solid #E0E0E0",
          }}
        >
          <Box>
            <Slider
              {...settings2}
              afterChange={(index) => setIndividualResponseId(index)}
            >
              {response.map((item, index) => (
                <Box marginLeft="3px">
                  <Typography
                    color="primary"
                    style={{ color: "#0085a4", marginLeft: "10px" }}
                  >
                    {individualResponseId + 1}
                  </Typography>
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
        <p style={{ marginTop: "10px", marginLeft: "30px" }}>
          of {response.length}
        </p>
      </div>
    </Box>
  );
};

export default IndividualSlider;
