import { PDFDownloadLink, Page, Text, View, Document } from "@react-pdf/renderer";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

export const DownloadPdfLink = ({ data }) => {
  const ReportDoc = () => (
    <Document>
      <Page style={{ margin: "20px", textAlign: "center" }}>
        <View>
          <Text>Revsurvey Response</Text>
        </View>
        {data.map((item, idx) => {
          return (
            <View key={idx} style={{ marginVertical: "10px", textAlign: "left" }}>
              <Text>id: {item.id}</Text>
              <Text>Survey Time: {item.survey_time}</Text>
              <Text>User Ip: {item.user_ip}</Text>
              <Text>Response : {JSON.stringify(item.response)}</Text>
              <Text>Form Number: {item.form}</Text>
            </View>
          );
        })}
      </Page>
    </Document>
  );

  return (
    <PDFDownloadLink
      document={<ReportDoc />}
      fileName="reports.pdf"
      style={{ color: "#333333", textDecoration: "none", fontWeight: "none" }}
    >
      <div className="aside-block-items">
        <div className="aside-block-item-icon">
          <ExitToAppIcon />
        </div>
        <p>PDF</p>
      </div>
    </PDFDownloadLink>
  );
};
