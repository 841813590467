import React from "react";
import { Grid, Box, Button } from "@material-ui/core";
import { setCurrentPage } from "../../redux/survey/SurveyActions";
import { postResponse } from "../../redux/response/ResponseAction";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const Pagination = ({
  survey,
  setCurrentPage,
  forms,
  response,
  postResponse,
  history,
  noSubmit,
}) => {
  const { currentPage } = survey;

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <Box className="pagination-container">
      <Box component={Grid} container justifyContent="space-between">
        <Grid item>
          <span className="pagination-text">
            {currentPage}/{forms.length === 0 ? 1 : forms.length} page
          </span>
        </Grid>
        {forms.length === 0 ||
          (forms[forms.length - 1].page === currentPage &&
            response.slug &&
            !noSubmit && (
              <Grid item>
                <Button
                  color="primary"
                  style={{ margin: "0px 10px" }}
                  variant="contained"
                  onClick={() => postResponse(history)}
                >
                  <span className="btn-text">Submit</span>
                </Button>
              </Grid>
            ))}
        <Grid item>
          <Button
            color="default"
            disabled={currentPage === 1}
            style={{ margin: "0px 10px" }}
            variant="contained"
            onClick={() => prevPage()}
          >
            <span className="btn-text">Prev</span>
          </Button>
          <Button
            color="primary"
            disabled={
              forms.length === 0
                ? true
                : forms[forms.length - 1].page === currentPage
            }
            style={{ margin: "0px 10px" }}
            variant="contained"
            onClick={() => nextPage()}
          >
            <span className="btn-text">Next</span>
          </Button>
        </Grid>
      </Box>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  survey: state.survey,
  response: state.response,
});
export default withRouter(
  connect(mapStateToProps, { setCurrentPage, postResponse })(Pagination)
);
