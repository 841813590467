import React from "react";
import { Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { loginAction } from "../../redux/auth/AuthActions";
import GG from "../../assets/images/socials/Google.png";
import LI from "../../assets/images/socials/Linkedin.png";
import FB from "../../assets/images/socials/facebook.png";
import { Link } from "react-router-dom";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { useGoogleLogin } from "react-google-login";
import { socialLogin } from "../../redux/auth/AuthActions";
import Logo from "../../assets/images/icons/logo.png";
import BarLogo from "../../assets/images/preview/login-bar.png";
import GreenTick from "../../assets/images/icons/green-tick-fill.png";
import BarSilent from "../../assets/images/icons/bar-silent.png";
import LineSilent from "../../assets/images/icons/line-silent.png";
import DoughnutSilent from "../../assets/images/icons/dougnut-silent.png";
import PieSilent from "../../assets/images/icons/pie-silent.png";

import {
  Checkbox,
  Box,
  Grid,
  TextField,
  FormControlLabel,
  Button,
  CircularProgress,
  Paper,
} from "@material-ui/core";

const LoginPage = ({ auth, loginAction, history, socialLogin }) => {
  /*
  const responseFacebook = (res) => {
    socialLogin(res.accessToken);
  };
*/
  const onSuccess = (res) => {
    socialLogin(res.accessToken, history);
  };

  const onFailure = (res) => {
    //console.log(res);
  };

  const clientId =
    "35964755747-dtbjj15qt3bf392rmlqnnrpt5jpc7373.apps.googleusercontent.com";

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
  });

  const { errors, register, handleSubmit } = useForm();

  const onHandleSubmit = (formData) => {
    loginAction(formData, history);
  };

  return (
    <Paper>
      <Grid container>
        <Box
          component={Grid}
          item
          lg={6}
          display={{ xs: "none", md: "none", lg: "block" }}
        >
          <div className="login-cover-container">
            <div className="login-details">
              <Grid container>
                <Grid item xs={8}>
                  <div className="login-detail-text">
                    <h4>The ultimate survey experience.</h4>
                    <p>
                      Create an account to begin your best feedback analysis!
                    </p>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="login-bar-chart">
                    <img src={BarLogo} alt=""></img>
                  </div>
                </Grid>
              </Grid>
              <div className="login-activity-list">
                <ul className="activity-list-container">
                  <li className="activity-item">
                    <div className="activity-logo-container">
                      <img src={GreenTick} alt=""></img>
                    </div>
                    <p className="activity-text">
                      Compare yourself to industry leaders
                    </p>
                  </li>
                  <li className="activity-item">
                    <div className="activity-logo-container">
                      <img src={GreenTick} alt=""></img>
                    </div>
                    <p className="activity-text">
                      View text cloud data and powerful analysis
                    </p>
                  </li>
                  <li className="activity-item">
                    <div className="activity-logo-container">
                      <img src={GreenTick} alt=""></img>
                    </div>
                    <p className="activity-text">Sentiment data analysis.</p>
                  </li>
                  <li className="activity-item">
                    <div className="activity-logo-container">
                      <img src={GreenTick} alt=""></img>
                    </div>
                    <p className="activity-text">It’s free!!!</p>
                  </li>
                </ul>
              </div>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={8}
              >
                <Grid item>
                  <img src={PieSilent} alt="charts"></img>
                </Grid>
                <Grid item>
                  <img src={BarSilent} alt="charts"></img>
                </Grid>
                <Grid item>
                  <img src={LineSilent} alt="charts"></img>
                </Grid>
                <Grid item>
                  <img src={DoughnutSilent} alt="charts"></img>
                </Grid>
              </Grid>
            </div>
          </div>
        </Box>
        <Grid item lg={6} xs={12}>
          <div className="form-container">
            <Grid item xs={12}>
              <p className="form-title-text">Log In</p>
            </Grid>
            <Grid item xs={12} className="form-subtitle">
              <span>New to RevSurvey?</span>
              <Link to="/register" className="links">
                <span className="highlight-auth"> Sign up</span>
              </Link>
            </Grid>
            <Form
              className="form-inner"
              onSubmit={handleSubmit(onHandleSubmit)}
            >
              <div className="form-field">
                <TextField
                  label="Email"
                  variant="outlined"
                  color="primary"
                  name="email"
                  size="small"
                  inputRef={register({
                    required: "Email is required",
                  })}
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                  fullWidth
                />{" "}
              </div>
              <div className="form-field">
                <TextField
                  label="Password"
                  variant="outlined"
                  name="password"
                  color="primary"
                  type="password"
                  size="small"
                  inputRef={register({
                    required: "Password is required.",
                  })}
                  error={Boolean(errors.password)}
                  helperText={errors.password?.message}
                  fullWidth
                />
              </div>
              <FormControlLabel
                control={<Checkbox name="remember" color="primary" />}
                label="Remember Me"
              />
              <p
                className="fp-text"
                onClick={() => history.push("/reset-password")}
              >
                Forgot your password ?
              </p>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                size="large"
                fullWidth
              >
                {auth.loading ? (
                  <React.Fragment>
                    <CircularProgress color="primary" size="1rem" />
                    <span className="btn-text" style={{ marginLeft: "5px" }}>
                      Loading
                    </span>
                  </React.Fragment>
                ) : (
                  <span className="btn-text">Log in</span>
                )}
              </Button>
            </Form>

            <div className="social-container">
              <div className="social-login-subtile-container">
                <div></div>
                <p>Or login with</p>
                <div></div>
              </div>
              <div className="social-icon-container">
                <div className="socials">
                  <img src={FB} alt="socials"></img>
                </div>
                <div className="socials" onClick={signIn}>
                  <img src={GG} alt="socials" />
                </div>
                <div className="socials">
                  <LinkedIn
                    clientId="886tj59mak0ircn"
                    onFailure={onFailure}
                    onSuccess={onSuccess}
                    redirectUri="http://localhost:3000/"
                  >
                    <img src={LI} alt="socials" />
                  </LinkedIn>
                </div>
              </div>
            </div>
            <div className="form-footer">
              <img src={Logo} alt="logo"></img>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="form-footer-grid"
              >
                <Grid item>
                  <p className="footer-item">About RevSurvet</p>
                </Grid>
                <Grid item>
                  <p className="footer-item">Privacy Notice</p>
                </Grid>
                <Grid item>
                  <p className="footer-item">Condition of use</p>
                </Grid>
                <Grid item>
                  <p className="footer-item">Need help?</p>
                </Grid>
              </Grid>
              <p className="footer-item"> Copyright &copy; 2021 RevSurvey</p>
            </div>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { loginAction, socialLogin })(
  LoginPage
);
