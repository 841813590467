import React, { useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { BarChart, PieChart } from "@material-ui/icons";

import ResponseCardBody from "../response-card-body/ResponseCardBody";

const ResponseCard = ({ type, qindex, question, response, qVal }) => {
  const [chartTab, setChartTab] = useState(1);

  return (
    <Box className="response-card-container">
      <Box padding={2} borderBottom="1px solid rgba(142, 142, 147, 0.4)">
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Typography color="primary">{type}</Typography>
          </Grid>
          <Grid item>
            <Typography color="primary" style={{ fontWeight: "bold" }}>
              Question {qindex}
            </Typography>
          </Grid>
          <Grid item>
            {type !== "Range Slider" && type !== "Text" && (
              <Box display="flex" flexDirection="row">
                <Box
                  className="chart-tab-icons"
                  style={{
                    background: chartTab === 2 ? "#fff" : "#0085a4",
                    marginRight: "5px",
                  }}
                  onClick={() => setChartTab(1)}
                >
                  <BarChart
                    style={{
                      cursor: "pointer",
                      color: chartTab === 2 ? "#0085a4" : "#fff",
                    }}
                  />
                </Box>
                <Box
                  className="chart-tab-icons"
                  style={{
                    background: chartTab === 1 ? "#fff" : "#0085a4",
                  }}
                  onClick={() => setChartTab(2)}
                >
                  <PieChart
                    style={{
                      cursor: "pointer",
                      color: chartTab === 1 ? "#0085a4" : "#fff",
                    }}
                  />
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <Typography style={{ margin: "10px 20px", fontWeight: "bold" }}>
        Q{qindex}. {question}
      </Typography>
      <ResponseCardBody
        type={type}
        chartTab={chartTab}
        response={response}
        qVal={qVal}
      />
    </Box>
  );
};
export default ResponseCard;
