import {
  ON_COVER_CHANGE,
  ON_DESC_CHANGE,
  ON_TITLE_CHANGE,
  DATA_CHANGE,
  FORM_TYPE_CHANGE,
  ADD_FORM_FIELD,
  ON_QUESTION_CHANGE,
  ADD_FLEX_FORM,
  POST_SURVEY_SUCCESS,
  POST_SURVEY_FAILED,
  LOADING,
  GET_ALL_SURVEY,
  ON_TAB_CHANGE,
  ADD_PAGE,
  SET_PUBLISH,
  SET_CURRENT_PAGE,
  GET_SURVEY,
  ADD_ATTACHMENT,
  EDIT_SURVEY,
  ON_MAIN_TITLE,
  RESET_SLUG,
  SET_REQUIRED,
  DELETE_PAGE,
} from "./SurveyTypes";
import { url } from "../Utils";
import { addResponse } from "../response/ResponseAction";
import axios from "axios";

export const onTitleChange = (e, page) => (dispatch) => {
  const text = e.target.value;
  dispatch({
    type: ON_TITLE_CHANGE,
    payload: { text, page },
  });
};

export const onMainTitleChange = (e) => (dispatch) => {
  const text = e.target.value;
  dispatch({
    type: ON_MAIN_TITLE,
    payload: text,
  });
};

export const onCoverChange = (file, page) => (dispatch) => {
  dispatch({
    type: ON_COVER_CHANGE,
    payload: { file, page },
  });
};

export const onDescChange = (e, page) => (dispatch) => {
  const text = e.target.value;
  dispatch({
    type: ON_DESC_CHANGE,
    payload: { text, page },
  });
};

export const addQs = (object) => (dispatch) => {
  dispatch({
    type: DATA_CHANGE,
    payload: object,
  });
};

export const changeType = (id, type) => (dispatch) => {
  dispatch({
    type: FORM_TYPE_CHANGE,
    payload: { id, type },
  });
};

export const onQuestionChange = (e, id, page) => (dispatch) => {
  let value = e.target.value;
  dispatch({
    type: ON_QUESTION_CHANGE,
    payload: { id, value, page },
  });
};

export const setRequired = (value, id, page) => (dispatch) => {
  dispatch({
    type: SET_REQUIRED,
    payload: { id, value, page },
  });
};

export const addFormFields = (id, object, page) => (dispatch) => {
  dispatch({
    type: ADD_FORM_FIELD,
    payload: { id, object, page },
  });
};

export const addFlexFormFields = (id, list2, list1, page) => (dispatch) => {
  let object = { list1, list2 };
  dispatch({
    type: ADD_FLEX_FORM,
    payload: { id, object, page },
  });
};

export const postSurvey = () => (dispatch, getState) => {
  dispatch(loading);
  const state = getState();
  const { coverImg, desc, forms, title, mainTitle } = state.survey;
  const postBody = {
    form_json: {
      form_title: title,
      form_description: desc,
      form_image: coverImg,
      form_data: forms,
    },
    is_published: true,
    user: 1,
    title: mainTitle,
    form_creation_time: new Date(),
  };
  fetch(url + "form_builders/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify(postBody),
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch({
        type: POST_SURVEY_SUCCESS,
        payload: res.form_share_slug,
      });
      //postAttachment(res.form_share_slug);
      //toast.info("Survey has been created");
    })
    .catch((err) => {
      dispatch({
        type: POST_SURVEY_FAILED,
      });
    });
};

export const postTemplate = (categoryUrl) => (dispatch, getState) => {
  let c = categoryUrl.split("/");
  dispatch(loading);
  const state = getState();
  const { coverImg, desc, forms, title, mainTitle } = state.survey;
  const { allTemplateCategories } = state.template;

  let categoryId = allTemplateCategories.find(
    (item) => item.category_name === c[2]
  );

  const postBody = {
    form_json: {
      form_title: title,
      form_description: desc,
      form_image: coverImg,
      form_data: forms,
    },
    user: 1,
    category: categoryId.id,
    title: mainTitle,
    form_creation_time: new Date(),
  };

  fetch(url + "templates/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify(postBody),
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch({
        type: POST_SURVEY_SUCCESS,
        payload: res.form_share_slug,
      });
      //postAttachment(res.form_share_slug);
      //toast.info("Survey has been created");
    })
    .catch((err) => {
      dispatch({
        type: POST_SURVEY_FAILED,
      });
    });
};

export const resetSlug = () => {
  return {
    type: RESET_SLUG,
  };
};

export const postEditedSurvey = () => (dispatch, getState) => {
  dispatch(loading);
  const state = getState();
  const { mainTitle, coverImg, desc, forms, editSlug } = state.survey;
  const postBody = {
    form_json: {
      form_title: mainTitle,
      form_description: desc,
      form_image: coverImg,
      form_data: forms,
    },
    is_published: true,
    title: mainTitle,
    form_creation_time: new Date(),
  };
  fetch(url + `form_builders/${editSlug}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify(postBody),
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch({
        type: POST_SURVEY_SUCCESS,
        payload: res.form_share_slug,
      });
      //postAttachment(res.form_share_slug);
      //toast.info("Survey has been created");
    })
    .catch((err) => {
      dispatch({
        type: POST_SURVEY_FAILED,
      });
    });
};

export const postEditedTemplate = (categoryUrl) => (dispatch, getState) => {
  let c = categoryUrl.split("/");
  dispatch(loading);
  const state = getState();
  const { coverImg, desc, forms, title, mainTitle, editSlug } = state.survey;
  const { allTemplates } = state.template;

  let categoryId = allTemplates.find((item) => item.form_share_slug === c[3]);
  const postBody = {
    form_json: {
      form_title: title,
      form_description: desc,
      form_image: coverImg,
      form_data: forms,
    },
    user: 1,
    category: categoryId.category,
    title: mainTitle,
    form_creation_time: new Date(),
  };

  fetch(url + `templates/${editSlug}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify(postBody),
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch({
        type: POST_SURVEY_SUCCESS,
        payload: res.form_share_slug,
      });
      //postAttachment(res.form_share_slug);
      //toast.info("Survey has been created");
    })
    .catch((err) => {
      dispatch({
        type: POST_SURVEY_FAILED,
      });
    });
};

export const postAttachment = (slug) => (dispatch, getState) => {
  const state = getState();
  const { attachments } = state.survey;
  let defaultAttachment = { ...attachments, form: slug };
  const postBody = {
    defaultAttachment,
  };
  fetch(url + "form_builders_attachments/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify(postBody),
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) => {
      dispatch({
        type: POST_SURVEY_FAILED,
      });
    });
};

const addResponseToState = (data) => (dispatch) => {
  data.form_json.form_data.map((pages) => {
    pages.forms.map((item) => {
      dispatch(addResponse(item.id, "", item.selectedDropdown, item.required));
    });
  });
};

export const getSurvey = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(url + `survey_forms/${id}/`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      dispatch({
        type: GET_SURVEY,
        payload: res.data,
      });
      dispatch(addResponseToState(res.data));
    })
    .catch((err) => console.log(err));
};

export const getTemplate = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(url + `templates/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_SURVEY,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const loadTemplateForSurvey = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(url + `templates/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((res) => {
      dispatch({
        type: EDIT_SURVEY,
        payload: {
          title: res.data.title,
          forms: res.data.form_json.form_data,
          slug: res.data.form_share_slug,
        },
      });
    })
    .catch((err) => console.log(err));
};

export const editSurvey = (id) => (dispatch) => {
  dispatch(loading());
  axios
    .get(url + `survey_forms/${id}`)
    .then((res) => {
      dispatch({
        type: EDIT_SURVEY,
        payload: {
          title: res.data.title,
          forms: res.data.form_json.form_data,
          slug: res.data.form_share_slug,
        },
      });
    })
    .catch((err) => console.log(err));
};

export const deleteSurvey = (id) => (dispatch) => {
  axios
    .delete(url + `form_builders/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((res) => res.json)
    .catch((err) => console.log(err));
};

export const deletePage = (page) => {
  return {
    type: DELETE_PAGE,
    payload: page,
  };
};

export const getAllSurvey = () => (dispatch) => {
  dispatch(loading());
  axios
    .get(url + "form_builders/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((res) =>
      dispatch({
        type: GET_ALL_SURVEY,
        payload: res.data,
      })
    )
    .catch((err) => console.log(err));
};

export const getTab = (id) => {
  return {
    type: ON_TAB_CHANGE,
    payload: id,
  };
};

export const addPage = () => {
  return {
    type: ADD_PAGE,
  };
};

export const setCurrentPage = (page) => {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
};

export const publishSurvey = () => {
  return {
    type: SET_PUBLISH,
  };
};

export const addAttachment = (id, object, page) => {
  return {
    type: ADD_ATTACHMENT,
    payload: { id, object, page },
  };
};

const loading = () => {
  return {
    type: LOADING,
  };
};
