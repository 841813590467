import React, { useEffect, useState } from "react";
import { Grid, IconButton, Menu, MenuItem, Box } from "@material-ui/core";
import Education from "../../assets/images/workplace/school-bag.png";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SpellcheckOutlinedIcon from "@material-ui/icons/SpellcheckOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import { BarChart, AddCircleOutlined } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { editSurvey } from "../../redux/survey/SurveyActions";
import { connect } from "react-redux";
import { getResponseById } from "../../redux/response/ResponseAction";
import { Link } from "react-router-dom";
import { deleteSurvey } from "../../redux/survey/SurveyActions";
import { url } from "../../redux/Utils";
import { deleteTemplate } from "../../redux/template/templateAction";
import { dates } from "../../utils/dateFields";

const WorkplaceCards = ({ item, date, template, deleteSurvey, deleteTemplate }) => {
  console.log(date);
  const [responseNum, setResponseNum] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    fetch(url + `response?form=${item.form_share_slug}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setResponseNum(res);
      })
      .catch((err) => console.log(err));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteCurrentSurvey = () => {
    if (template) {
      deleteTemplate(item.form_share_slug);
    } else deleteSurvey(item.form_share_slug);
    window.location.reload();
  };

  return (
    <div className="workplace-item" key={item.form_share_slug}>
      <div
        className="workplace-card"
        style={{
          marginBottom: "20px",
        }}
      >
        <img src={item.form_image ? item.form_image : Education} alt="workplace" />
        <div className="workplace-text">
          <span className="user-survey-text">{item.title}</span>
          <span className="user-survey-text">
            {date.getDate() + " " + dates.months[date.getMonth()] + " " + date.getFullYear()}
          </span>
          <Grid container justifyContent="space-between">
            <Grid item>
              <span className="user-survey-response">{responseNum && responseNum.length} Response</span>
            </Grid>
            <Grid item>
              <Box style={{ position: "relative", bottom: "10px", left: "5px" }}>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  style={{ marginBottom: "18px" }}
                >
                  <MoreVertIcon style={{ transform: "scale(0.8)" }} />
                </IconButton>
                <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                  <Link className="links" to={`/${template ? "template/edit" : "edit-form"}/${item.form_share_slug}`}>
                    <MenuItem
                      value="edit"
                      onClick={() => {
                        handleClose();
                        //editSurvey(item.form_share_slug);
                      }}
                    >
                      <div className="fc-menu-item">
                        <span className="menu-item-icon">
                          <EditIcon />
                        </span>
                        <span className="menu-item-text">Edit</span>
                      </div>
                    </MenuItem>
                  </Link>
                  <Link className="links" to={`${template ? "/template/edit" : "/edit-form"}/${item.form_share_slug}`}>
                    <MenuItem value="rename" onClick={handleClose}>
                      <div className="fc-menu-item">
                        <span className="menu-item-icon">
                          <SpellcheckOutlinedIcon />
                        </span>
                        <span className="menu-item-text">Rename</span>
                      </div>
                    </MenuItem>
                  </Link>
                  <MenuItem
                    value="delete"
                    onClick={() => {
                      handleClose();
                      deleteCurrentSurvey();
                    }}
                  >
                    <div className="fc-menu-item">
                      <span className="menu-item-icon">
                        <DeleteSweepIcon />
                      </span>
                      <span className="menu-item-text">Delete</span>
                    </div>
                  </MenuItem>
                  {!template && (
                    <div>
                      <Link className="links" to={`/response/${item.form_share_slug}`}>
                        <MenuItem value="share" onClick={handleClose}>
                          <div className="fc-menu-item">
                            <span className="menu-item-icon">
                              <BarChart />
                            </span>
                            <span className="menu-item-text">Response</span>
                          </div>
                        </MenuItem>
                      </Link>

                      <Link
                        to={`${process.env.PUBLIC_URL}/${template ? "template" : "form"}/${item.form_share_slug}`}
                        target="_blank"
                        className="links"
                      >
                        <MenuItem value="View" onClick={handleClose}>
                          <div className="fc-menu-item">
                            <span className="menu-item-icon">
                              <VisibilityIcon />
                            </span>
                            <span className="menu-item-text">View</span>
                          </div>
                        </MenuItem>
                      </Link>
                    </div>
                  )}
                  {template && (
                    <div>
                      <Link className="links" to={`/template/create-form/${item.form_share_slug}`}>
                        <MenuItem value="share" onClick={handleClose}>
                          <div className="fc-menu-item">
                            <span className="menu-item-icon">
                              <AddCircleOutlined color="primary" />
                            </span>
                            <span className="menu-item-text">Create Survey</span>
                          </div>
                        </MenuItem>
                      </Link>

                      <Link
                        to={`${process.env.PUBLIC_URL}/${template ? "template" : "form"}/${item.form_share_slug}`}
                        target="_blank"
                        className="links"
                      >
                        <MenuItem value="View" onClick={handleClose}>
                          <div className="fc-menu-item">
                            <span className="menu-item-icon">
                              <VisibilityIcon />
                            </span>
                            <span className="menu-item-text">Preview</span>
                          </div>
                        </MenuItem>
                      </Link>
                    </div>
                  )}
                </Menu>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  response: state.response,
});
export default connect(mapStateToProps, {
  editSurvey,
  deleteSurvey,
  getResponseById,
  deleteTemplate,
})(WorkplaceCards);
