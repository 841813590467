import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
import Education from "../../assets/images/workplace/school-bag.png";
import FormPreviewCard from "../../components/form-preview-card/FormPreviewCard";
import { getAllSurvey } from "../../redux/survey/SurveyActions";
import Pagination from "../../components/partials/Pagination";
import { AttachFileOutlined } from "@material-ui/icons";

const SurveyPreview = ({ match, survey, getAllSurvey, previewType }) => {
  const [currentSurvey, setCurrentSurvey] = useState(null);

  useEffect(() => {
    if (previewType === "live") {
      let defaultForms = survey.forms;
      let item = defaultForms.find((item) => item.page === survey.currentPage);
      let defaultData = {
        form_image: item.coverImg,
        form_title: item.title,
        form_description: item.desc,
        form_data: item.forms,
      };
      setCurrentSurvey(defaultData);
    } else {
      getAllSurvey();
      let result = null;
      if (survey.allSurvey !== null) {
        setCurrentSurvey(result);
      }
    }
  }, [survey.currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAttachments = (id) => {
    let pageAttachement = survey.attachments.find(
      (item) => item.form_field_index === id
    );
    if (pageAttachement !== undefined) {
      return true;
    }
  };

  return (
    <div className="sv-block">
      {currentSurvey && (
        <React.Fragment>
          <div className="sv-block-header">
            <div className="sv-block-image-container">
              <img
                src={
                  currentSurvey.form_image
                    ? `data:${currentSurvey.form_image.type};base64,${currentSurvey.form_image.base64}`
                    : Education
                }
                className="preview-image"
                alt="survey"
              ></img>
            </div>
            <div className="header-form-group">
              <div className="sv-preview-header-text">
                <h6 className="sv-preview-title">{currentSurvey.form_title}</h6>
                <p className="sv-preview-desc">
                  {currentSurvey.form_description}
                </p>
              </div>
            </div>
          </div>
          <div className="form-cards">
            {currentSurvey.form_data.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  {item.selectedDropdown === "Section" ? (
                    <div className="fc-block-container">
                      <div className="sv-block-image-container">
                        <img
                          src={
                            item.formFields && item.formFields.image
                              ? item.formFields.image
                              : Education
                          }
                          className="preview-image"
                          alt="survey"
                        ></img>
                      </div>
                      <div className="header-form-group">
                        <div className="sv-preview-header-text">
                          <h6 className="sv-preview-title">
                            {item.formFields && item.formFields.title}
                          </h6>
                          <p className="sv-preview-desc">
                            {item.formFields && item.formFields.desc}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="fc-block-preview" key={idx}>
                      <div className="fc-block-header">
                        <h6 className="fc-preview-title">
                          {item.question ? item.question : "No question yet!"}
                        </h6>
                      </div>
                      <Box marginX="20px">
                        <FormPreviewCard
                          type={item.selectedDropdown}
                          uId={item.id}
                          data={item.formFields}
                        />
                        {getAttachments(item.id) === true && (
                          <Box
                            marginTop={3}
                            marginLeft={1}
                            display="flex"
                            flexDirection="row"
                          >
                            <AttachFileOutlined
                              style={{ transform: "scale(0.7)" }}
                            />
                            <p style={{ fontSize: "10px", marginTop: "5px" }}>
                              Attchment uploaded
                            </p>
                          </Box>
                        )}
                      </Box>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </React.Fragment>
      )}
      <Pagination forms={survey.forms} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  survey: state.survey,
});

export default withRouter(
  connect(mapStateToProps, { getAllSurvey })(SurveyPreview)
);
