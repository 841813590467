import { LOADING } from "./paymentTypes";
import { payUrl } from "../Utils";
import { toast } from "react-toastify";

export const onCheckout = (object, history) => (dispatch) => {
  dispatch(loading());
  fetch(payUrl + "payments/pay/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify(object),
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => {
      toast.success("Successfully subscribed");
      setTimeout(() => history.push("/"));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getPlan = (object) => (dispatch) => {
  dispatch(loading());
  fetch(payUrl + "payments/pay/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify(object),
    credentials: "include",
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

const loading = () => {
  return {
    type: LOADING,
  };
};
