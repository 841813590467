export var url = "";
if (process.env.NODE_ENV === "development") {
  url = "http://127.0.0.1:8000/api/v1/";
} else {
  url = "https://api.rev-survey.com/api/v1/";
}

export var payUrl = "";
if (process.env.NODE_ENV === "development") {
  payUrl = "http://127.0.0.1:8000/";
} else {
  payUrl = "https://api.rev-survey.com/";
}

export const isLoggedIn = () => {
  if (localStorage.getItem("accessToken")) {
    return true;
  } else return false;
};
