import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Redirect } from "react-router";
import { url } from "../../redux/Utils";
import { strengthIndicator } from "../../utils/password-strength";
import {
  Grid,
  TextField,
  Button,
  LinearProgress,
  Box,
} from "@material-ui/core";
import theme from "../../utils/theme";
import Lock from "../../assets/images/icons/reset-pass-lock.png";

const ResetPassConfirm = ({ location, history }) => {
  const [token, updateToken] = useState("");
  const [password1, setPassword] = useState("");

  let str = strengthIndicator(password1);

  useEffect(() => {
    const query = location.search;
    const token = query.split("=");
    updateToken(token[1]);

    axios
      .post(url + "rest-auth/password_reset/validate_token/", {
        token: token[1],
      })
      .then((res) => {
        if (res.data.status !== "OK") {
          toast.error("The token is not valid");
          setTimeout(<Redirect to="/" />, 2000);
        }
      })
      .catch((err) => {
        toast.error("The token is not valid");
        setTimeout(history.push("/"), 10000);
      });

    //updateToken(match.params.token);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { errors, handleSubmit, register, watch } = useForm();

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onHandleSubmit = (formData) => {
    const postBody = {
      password: formData.password,
      token: token,
    };
    axios
      .post(url + "rest-auth/password_reset/confirm/", postBody)
      .then((res) => {
        toast.info("Password has been changed");
        history.push("/");
      })
      .catch((err) => toast.error(err.response.data.password[0]));
  };

  const password = useRef();
  password.current = watch("password");

  return (
    <div
      className="at-block"
      style={{
        marginTop: 50,
      }}
    >
      <Box
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        className="reset-container"
      >
        <div className="reset-logo-container">
          <img src={Lock} alt="lock"></img>
        </div>
        <Box>
          <Box margin="50px 0px">
            <h4>Reset Password</h4>
            <p>We will send you a code, please check your provided email</p>
          </Box>
          <Form className="form-inner" onSubmit={handleSubmit(onHandleSubmit)}>
            <div className="form-field">
              <TextField
                label="Password"
                variant="outlined"
                size="small"
                type="password"
                color="secondary"
                name="password"
                onChange={(e) => onPasswordChange(e)}
                inputRef={register({
                  required: "Password is required.",
                })}
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                fullWidth
              ></TextField>
            </div>
            <div className="form-field">
              <TextField
                label="Confirm Password"
                variant="outlined"
                size="small"
                type="password"
                color="secondary"
                name="confirmPassword"
                inputRef={register({
                  required: "Confirm password is required.",
                  validate: (value) => value === password.current,
                })}
                error={Boolean(errors.confirmPassword)}
                helperText={errors.confirmPassword?.message}
                fullWidth
              ></TextField>
            </div>
            <div className="password-strength-container">
              <p>
                Use at least 8 alphanumeric characters. By clicking the sign up,
                you agree to Condition of use and Privacy notice.
              </p>
              <div className="password-strength-progress">
                <p>Password strength</p>
                <div className="password-progress">
                  <div className="progress-container">
                    <LinearProgress
                      variant="determinate"
                      value={100}
                      style={
                        str >= 1
                          ? theme.palette.error
                          : theme.palette.customGrey
                      }
                      color={`${theme.palette.error}`}
                    ></LinearProgress>
                  </div>
                  <div className="progress-container">
                    <LinearProgress
                      variant="determinate"
                      value={100}
                      style={
                        str >= 2
                          ? theme.palette.warning
                          : theme.palette.customGrey
                      }
                      color={`${theme.palette.warning}`}
                    >
                      {" "}
                    </LinearProgress>
                  </div>
                  <div className="progress-container">
                    <LinearProgress
                      variant="determinate"
                      value={100}
                      style={
                        str >= 4
                          ? theme.palette.success
                          : theme.palette.customGrey
                      }
                      color={`${theme.palette.success}`}
                    ></LinearProgress>
                  </div>
                </div>
              </div>
            </div>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              size="large"
              fullWidth
            >
              <span className="btn-text">Reset Password</span>
            </Button>
          </Form>
        </Box>
        <div className="form-footer">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="form-footer-grid"
          >
            <Grid item>
              <p className="footer-item">About RevSurvet</p>
            </Grid>
            <Grid item>
              <p className="footer-item">Privacy Notice</p>
            </Grid>
            <Grid item>
              <p className="footer-item">Condition of use</p>
            </Grid>
            <Grid item>
              <p className="footer-item">Need help?</p>
            </Grid>
          </Grid>
          <p className="footer-item"> Copyright &copy; 2021 RevSurvey</p>
        </div>
      </Box>
    </div>
  );
};

export default ResetPassConfirm;
