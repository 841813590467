import { url } from "../Utils";
import {
  GET_TEMPLATES,
  GET_TEMPLATE_CATEGORIES,
  LOADING,
} from "./templateTypes";
import axios from "axios";

export const getTemplateCategories = () => (dispatch) => {
  dispatch(loading());
  fetch(url + "template_categories/", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("accessToken")}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch({
        type: GET_TEMPLATE_CATEGORIES,
        payload: res,
      });
    })
    .catch((err) => console.log(err));
};

export const getTemplates = () => (dispatch) => {
  dispatch(loading());
  fetch(url + "templates/", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("accessToken")}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch({
        type: GET_TEMPLATES,
        payload: res,
      });
    })
    .catch((err) => console.log(err));
};

const loading = () => {
  return {
    type: LOADING,
  };
};

export const deleteTemplate = (id) => (dispatch) => {
  axios
    .delete(url + `templates/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("accessToken")}`,
      },
    })
    .then((res) => res.json)
    .catch((err) => console.log(err));
};
