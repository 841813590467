import { GET_TEMPLATES, GET_TEMPLATE_CATEGORIES } from "./templateTypes";

const INITIAL_STATE = {
  allTemplateCategories: [],
  allTemplates: [],
  loading: false,
};

export default function TemplateReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_TEMPLATES:
      return {
        ...state,
        allTemplates: action.payload,
        loading: false,
      };
    case GET_TEMPLATE_CATEGORIES:
      return {
        ...state,
        allTemplateCategories: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}
