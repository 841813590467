export const pricingData = [
  {
    id: 0,
    heading: "Free",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    pricing: {
      id: 0,
      month: 0,
      year: 0,
    },
    lists: ["Unlimited Survey", "Up to 200 responses per survey", "Limited Analytics"],
  },
  {
    id: 1,
    heading: "One Time Payment",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    pricing: {
      id: 'price_1KfVh9A9AMtCXbH5Elc3lNrf',
      month: 50,
      year: 500,
      mode: "payment"
    },
    stripeLink : "https://checkout.stripe.com/pay/cs_test_a1GjK6QLF0ZU5SUVAOsBLNEm6kXt5hzHEAuU48BUBT3NNnW73UDylaI8ak#fidkdWxOYHwnPyd1blpxYHZxWjZEb292U0ZKVU9zXTd1aURdPDFrZ3FjUzU1PWA1Mm1KYm0nKSd1aWxrbkB9dWp2YGFMYSc%2FJ3FgdnFaMG5ENzxtN1F9PTBiYWRyZkhIJyknd2BjYHd3YHcnPydtcXF1dj8qKmkrY2RmYGdqam4rZmpoKid4JSUl",
    lists: ["Unlimited Survey", "Up to 1000 responses per survey", "Unlimited Pages", "Advanced Analytics"],
  },
  {
    id: 2,
    heading: "Monthly",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    pricing: {
      id: 'price_1KbRtuA9AMtCXbH5zjmNpVtz',
      month: 30,
      year: 1500,
      mode: "subscription"
    },
    stripeLink: "https://checkout.stripe.com/pay/cs_test_a1BOZIx0Z7ZNHmBr1wpKBcjfNtOAjjPqr9IKyQPsEAd26rIAsHBbD4cVwd#fidkdWxOYHwnPyd1blpxYHZxWjZEb292U0ZKVU9zXTd1aURdPDFrZ3FjUzU1PWA1Mm1KYm0nKSd1aWxrbkB9dWp2YGFMYSc%2FJ3FgdnFaPXJIYjUyYXxnYkdINn9SYVRWJyknd2BjYHd3YHcnPydtcXF1dj8qKmkrY2RmYGdqam4rZmpoKid4JSUl",
    lists: [
      "Unlimited Survey",
      "Unlimited responses per survey",
      "Unlimited Pages",
      "Advanced Analytics",
      "24/7 Customer Support",
    ],
  },
  {
    id: 3,
    heading: "Yearly",
    desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    pricing: {
      id: 'price_1KbRuSA9AMtCXbH53Y16tRVu',
      month: 360,
      year: 1500,
      mode: "subscription"
    },
    stripeLink: "https://checkout.stripe.com/pay/cs_test_a12PcZpmB7hkPZKhI4c4qa7I7KvxmwIXfryxB8vR6bQne6emo8wD615utt#fidkdWxOYHwnPyd1blpxYHZxWjZEb292U0ZKVU9zXTd1aURdPDFrZ3FjUzU1PWA1Mm1KYm0nKSd1aWxrbkB9dWp2YGFMYSc%2FJ3FgdnFaZEBQNzxtNl1HZGFqZjN2Nz08Jyknd2BjYHd3YHcnPydtcXF1dj8qKmkrY2RmYGdqam4rZmpoKid4JSUl",
    lists: [
      "Unlimited Survey",
      "Unlimited responses per survey",
      "Unlimited Pages",
      "Advanced Analytics",
      "24/7 Customer Support",
    ],
  },
];
