import React, { useState } from "react";
import { connect } from "react-redux";
import Cross from "../../assets/images/icons/cross.png";
import { TextField, Grid } from "@material-ui/core";
import {
  addFlexFormFields,
  addFormFields,
} from "../../redux/survey/SurveyActions";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

const FormBodyFlexQs = ({ qtype, uId, addFormFields, form, page }) => {
  const [data, setData] = useState(form);

  const onTextChange = (e, id, list) => {
    var newData = data;
    if (list === "row") {
      const index = data.formFields.list1.findIndex((item) => item.id === id);
      newData.formFields.list1[index].label = e.target.value;
      setData(newData);
      addFormFields(uId, newData.formFields, page);
    } else {
      const index = data.formFields.list2.findIndex((item) => item.id === id);
      newData.formFields.list2[index].label = e.target.value;
      setData(newData);
      addFormFields(uId, newData.formFields, page);
    }
  };

  const onOptionAdd = (state) => {
    let newOption;
    if (state === "row") {
      newOption = {
        id: uuidv4(),
        label: "",
      };
      if (
        data.formFields === null ||
        data.formFields.list1 === undefined ||
        data.formFields.list1 === null
      ) {
        data.formFields = { ...data.formFields, list1: [newOption] };
        setData(data);
      } else {
        data.formFields.list1.push(newOption);
        setData(data);
      }
      addFormFields(uId, data.formFields, page);
    } else {
      newOption = {
        id: uuidv4(),
        label: "",
      };
      if (
        data.formFields === null ||
        data.formFields.list2 === undefined ||
        null
      ) {
        data.formFields = { ...data.formFields, list2: [newOption] };
        setData(data);
      } else {
        data.formFields.list2.push(newOption);
        setData(data);
      }
      addFormFields(uId, data.formFields, page);
    }
  };

  const onOptionDelete = (id, state) => {
    let newQList;
    if (state === "row") {
      newQList = data;
      let index = newQList.formFields.list1.findIndex((item) => item.id === id);
      newQList.formFields.list1.splice(index, 1);
      addFormFields(uId, newQList.formFields, page);
    } else {
      newQList = data;
      let index = newQList.formFields.list2.findIndex((item) => item.id === id);
      newQList.formFields.list2.splice(index, 1);
      addFormFields(uId, newQList.formFields, page);
    }
  };

  const handleOnDragEnd = (result, page, side) => {
    if (!result.destination) return;
    else {
      if (side === "row") {
        let defaultData = form;
        const items = Array.from(defaultData.formFields.list1);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        defaultData.formFields.list1 = items;
        addFormFields(uId, defaultData.formFields, page);
      } else {
        let defaultData = form;
        const items = Array.from(defaultData.formFields.list2);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        defaultData.formFields.list2 = items;
        addFormFields(uId, defaultData.formFields, page);
      }
    }
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={6}>
        <DragDropContext
          onDragEnd={(result) => handleOnDragEnd(result, page, "row")}
        >
          <Droppable droppableId="form-qs">
            {(provided) => (
              <div
                className="unique-qs-list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {data &&
                  data.formFields &&
                  data.formFields.list1 &&
                  data.formFields.list1.map((item, index) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="qs"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            key={item.id}
                          >
                            <div
                              className="form-group-alt"
                              {...provided.dragHandleProps}
                            >
                              {qtype === "checkbox" ? (
                                <DragIndicatorIcon
                                color="primary"
                                style={{ marginTop: "8px", marginRight: "5px" }}
                              />
                              ) : (
                                <DragIndicatorIcon
                                color="primary"
                                style={{ marginTop: "8px", marginRight: "5px" }}
                              />
                              )}
                              <TextField
                                placeholder={"Row "}
                                defaultValue={item.label}
                                onChange={(e) =>
                                  onTextChange(e, item.id, "row")
                                }
                              />
                              <div
                                className="cross-btn"
                                onClick={() => onOptionDelete(item.id, "row")}
                              >
                                <img src={Cross} alt="icon" />
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="qs-activity">
          <h6 onClick={() => onOptionAdd("row")}>Add Row</h6>
        </div>
      </Grid>

      <Grid item xs={6}>
        <DragDropContext
          onDragEnd={(result) => handleOnDragEnd(result, page, "column")}
        >
          <Droppable droppableId="form-qs">
            {(provided) => (
              <div
                className="unique-qs-list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {data &&
                  data.formFields &&
                  data.formFields.list2 &&
                  data.formFields.list2.map((item, index) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="qs"
                            key={item.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div
                              className="form-group-alt"
                              {...provided.placeholder}
                              {...provided.dragHandleProps}
                            >
                              {qtype === "checkbox" ? (
                                <DragIndicatorIcon
                                color="primary"
                                style={{ marginTop: "8px", marginRight: "5px" }}/>
                              ) : (
                                <DragIndicatorIcon
                                color="primary"
                                style={{ marginTop: "8px", marginRight: "5px" }} />
                              )}
                              <TextField
                                placeholder={"Column "}
                                defaultValue={item.label}
                                onChange={(e) =>
                                  onTextChange(e, item.id, "column")
                                }
                              />
                              <div
                                className="cross-btn"
                                onClick={() => onOptionDelete(item.id, "col")}
                              >
                                <img src={Cross} alt="icon" />
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="qs-activity">
          <h6 onClick={() => onOptionAdd("col")}>Add Column</h6>
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  survey: state.survey,
});

export default connect(mapStateToProps, { addFlexFormFields, addFormFields })(
  FormBodyFlexQs
);
