import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  TextField,
  Button,
  LinearProgress,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import GG from "../../assets/images/socials/Google.png";
import LI from "../../assets/images/socials/Linkedin.png";
import FB from "../../assets/images/socials/facebook.png";
import { Link } from "react-router-dom";
import { Form } from "reactstrap";
import { useForm } from "react-hook-form";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { useGoogleLogin } from "react-google-login";
import { socialLogin } from "../../redux/auth/AuthActions";
import Logo from "../../assets/images/icons/logo.png";
import theme from "../../utils/theme";
import RegistrationTab from "../../components/registration-tab/RegistrationTab";
import { strengthIndicator } from "../../utils/password-strength";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { registerAction, resetError } from "../../redux/auth/AuthActions";

const RegisterPage = ({ registerAction, resetError, auth }) => {
  const [password1, setPassword] = useState("");

  let str = strengthIndicator(password1);

  useEffect(() => {
    resetError();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const errorHandle = () => {
    let errorText = [];
    if (auth.error !== null && auth.error) {
      auth.error.username && errorText.push(auth.error.username[0]);
      auth.error.password1 && errorText.push(auth.error.password1[0]);
      auth.error.email && errorText.push(auth.error.email[0]);
      errorNotify(errorText);
    }
  };

  useEffect(() => {
    setTimeout(() => errorHandle());
  }, [auth.error]); // eslint-disable-line react-hooks/exhaustive-deps

  const errorNotify = (array) => {
    array.map((item) => {
      toast.error(item);
    });
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onSuccess = (res) => {
    socialLogin(res.accessToken);
  };

  const onFailure = (res) => {
    //console.log(res);
  };

  const clientId =
    "35964755747-dtbjj15qt3bf392rmlqnnrpt5jpc7373.apps.googleusercontent.com";

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
  });

  const onHandleSubmit = (formData) => {
    const apiFormData = {
      username: formData.email,
      email: formData.email,
      password1: formData.password,
      password2: formData.confirmPassword,
      first_name: formData.firstName,
      last_name: formData.lastName,
    };
    registerAction(apiFormData);
  };

  const { errors, watch, register, handleSubmit } = useForm();

  const password = useRef();
  password.current = watch("password");

  return (
      <Paper>
        <Grid container>
          <RegistrationTab />
          <Grid item xs={12} md={12} lg={6}>
            <div className="form-container">
              <Grid item xs={12}>
                <p className="form-title-text">Sign Up</p>
              </Grid>
              <Grid item xs={12} className="form-subtitle">
                <span>Already have an account?</span>
                <Link to="/" className="links">
                  <span className="highlight-auth"> Sign In </span>
                </Link>
              </Grid>
              <Form
                className="form-inner"
                onSubmit={handleSubmit(onHandleSubmit)}
              >
                <Grid container justifyContent="center" lg={14} spacing={2}>
                  <Grid item xs={12} lg={6} className="form-field">
                    <TextField
                      label="First Name"
                      variant="outlined"
                      size="small"
                      color="secondary"
                      name="firstName"
                      inputRef={register({
                        required: "First Name is required.",
                      })}
                      error={Boolean(errors.firstName)}
                      helperText={errors.firstName?.message}
                      fullWidth
                    ></TextField>
                  </Grid>
                  <Grid item xs={12} lg={6} className="form-field">
                    <TextField
                      label="Last Name"
                      variant="outlined"
                      size="small"
                      color="secondary"
                      name="lastName"
                      inputRef={register({
                        required: "Last Name is required.",
                      })}
                      error={Boolean(errors.lastName)}
                      helperText={errors.lastName?.message}
                      fullWidth
                    ></TextField>
                  </Grid>
                </Grid>
                <div className="form-field">
                  <TextField
                    label="Email"
                    variant="outlined"
                    size="small"
                    color="secondary"
                    name="email"
                    inputRef={register({
                      required: "Email is required.",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })}
                    error={Boolean(errors.email)}
                    helperText={errors.email?.message}
                    fullWidth
                  ></TextField>
                </div>
                <div className="form-field">
                  <TextField
                    label="Password"
                    variant="outlined"
                    size="small"
                    type="password"
                    color="secondary"
                    name="password"
                    onChange={(e) => onPasswordChange(e)}
                    inputRef={register({
                      required: "Password is required.",
                    })}
                    error={Boolean(errors.password)}
                    helperText={errors.password?.message}
                    fullWidth
                  ></TextField>
                </div>
                <div className="form-field">
                  <TextField
                    label="Confirm Password"
                    variant="outlined"
                    size="small"
                    type="password"
                    color="secondary"
                    name="confirmPassword"
                    inputRef={register({
                      required: "Confirm password is required.",
                      validate: (value) => value === password.current,
                    })}
                    error={Boolean(errors.confirmPassword)}
                    helperText={errors.confirmPassword?.message}
                    fullWidth
                  ></TextField>
                </div>
                <div className="password-strength-container">
                  <p>
                    Use at least 8 alphanumeric characters. By clicking the sign
                    up, you agree to Condition of use and Privacy notice.
                  </p>
                  <div className="password-strength-progress">
                    <p>Password strength</p>
                    <div className="password-progress">
                      <div className="progress-container">
                        <LinearProgress
                          variant="determinate"
                          value={100}
                          style={
                            str >= 1
                              ? theme.palette.error
                              : theme.palette.customGrey
                          }
                          color={`${theme.palette.error}`}
                        ></LinearProgress>
                      </div>
                      <div className="progress-container">
                        <LinearProgress
                          variant="determinate"
                          value={100}
                          style={
                            str >= 2
                              ? theme.palette.warning
                              : theme.palette.customGrey
                          }
                          color={`${theme.palette.warning}`}
                        >
                          {" "}
                        </LinearProgress>
                      </div>
                      <div className="progress-container">
                        <LinearProgress
                          variant="determinate"
                          value={100}
                          style={
                            str >= 4
                              ? theme.palette.success
                              : theme.palette.customGrey
                          }
                          color={`${theme.palette.success}`}
                        ></LinearProgress>
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  size="large"
                  fullWidth
                >
                  {auth.loading ? (
                    <React.Fragment>
                      <CircularProgress color="primary" size="1rem" />
                      <span className="btn-text" style={{ marginLeft: "5px" }}>
                        Creating ...
                      </span>
                    </React.Fragment>
                  ) : (
                    <span className="btn-text">Create account</span>
                  )}
                </Button>
              </Form>
              <div className="social-container">
                <div className="social-login-subtile-container">
                  <div></div>
                  <p>Or Signup with</p>
                  <div></div>
                </div>
                <div className="social-icon-container">
                  <div className="socials">
                    <img src={FB} alt="socials"></img>
                  </div>
                  <div className="socials" onClick={signIn}>
                    <img src={GG} alt="socials" />
                  </div>
                  <div className="socials">
                    <LinkedIn
                      clientId="886tj59mak0ircn"
                      onFailure={onFailure}
                      onSuccess={onSuccess}
                      redirectUri="http://localhost:3000/"
                    >
                      <img src={LI} alt="socials" />
                    </LinkedIn>
                  </div>
                </div>
              </div>
              <div className="form-footer">
                <img src={Logo} alt="logo"></img>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className="form-footer-grid"
                >
                  <Grid item>
                    <p className="footer-item">About RevSurvey</p>
                  </Grid>
                  <Grid item>
                    <p className="footer-item">Privacy Notice</p>
                  </Grid>
                  <Grid item>
                    <p className="footer-item">Condition of use</p>
                  </Grid>
                  <Grid item>
                    <p className="footer-item">Need help?</p>
                  </Grid>
                </Grid>
                <p className="footer-item"> Copyright &copy; 2021 RevSurvey</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { registerAction, resetError })(
  RegisterPage
);
