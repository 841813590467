import React,{useRef} from "react";
import { Form } from "reactstrap";
import { connect } from "react-redux";
import { changePassword } from "../../redux/auth/AuthActions";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Lock from "../../assets/images/icons/reset-pass-lock.png";
import { Box, Grid, TextField, Button, CircularProgress } from "@material-ui/core";

const ChangePassword = ({ changePassword, auth, history }) => {
  const { errors, handleSubmit, register, watch } = useForm();
  const onHandleSubmit = (formData) => {
    let payload = {
      "old_password": formData.currentPassword,
      "new_password1": formData.password,
      "new_password2": formData.confirmPassword
    }
    changePassword(payload, history);
  };

  const password = useRef();
  password.current = watch("password");

  return (
    <div
      className="at-block"
      style={{
        marginTop: 50,
      }}
    >
      <Box justifyContent="center" alignItems="center" textAlign="center" className="reset-container">
        <div className="reset-logo-container">
          <img src={Lock} alt="lock"></img>
        </div>
        <Box>
          <Box margin="50px 0px">
            <h4>Change Password</h4>
          </Box>
          <Form onSubmit={handleSubmit(onHandleSubmit)}>
            <div className="form-field">
              <TextField
                label="Current Password"
                variant="outlined"
                size="small"
                type="password"
                color="secondary"
                name="currentPassword"
                inputRef={register({
                  required: "Password is required.",
                })}
                error={Boolean(errors.currentPassword)}
                helperText={errors.currentPassword?.message}
                fullWidth
              ></TextField>
            </div>
            <div className="form-field">
              <TextField
                label="Password"
                variant="outlined"
                size="small"
                type="password"
                color="secondary"
                name="password"
                inputRef={register({
                  required: "Password is required.",
                })}
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                fullWidth
              ></TextField>
            </div>
            <div className="form-field">
              <TextField
                label="Confirm Password"
                variant="outlined"
                size="small"
                type="password"
                color="secondary"
                name="confirmPassword"
                inputRef={register({
                  required: "Confirm password is required.",
                  validate : (value) => value === password.current
                })}
                error={Boolean(errors.confirmPassword)}
                helperText={errors.confirmPassword ? errors.confirmPassword.type === "required" ? errors.confirmPassword.message : errors.confirmPassword.type === "validate" && "Passwords do not match" : ""}
                fullWidth
              ></TextField>
            </div>
            <Button type="submit" color="secondary" variant="contained" size="large" fullWidth>
              {auth.loading ? (
                <React.Fragment>
                  <CircularProgress color="primary" size="1rem" />
                  <span className="btn-text" style={{ marginLeft: "5px" }}>
                    Changing Password
                  </span>
                </React.Fragment>
              ) : (
                <span className="btn-text">Change Password</span>
              )}
            </Button>
          </Form>
          <div className="return-link">
            <Link className="links" to="/">
              <p>Return to Home</p>
            </Link>
          </div>
        </Box>
        <div className="form-footer">
          <Grid container direction="row" justifyContent="center" alignItems="center" className="form-footer-grid">
            <Grid item>
              <p className="footer-item">About RevSurvet</p>
            </Grid>
            <Grid item>
              <p className="footer-item">Privacy Notice</p>
            </Grid>
            <Grid item>
              <p className="footer-item">Condition of use</p>
            </Grid>
            <Grid item>
              <p className="footer-item">Need help?</p>
            </Grid>
          </Grid>
          <p className="footer-item"> Copyright &copy; 2021 RevSurvey</p>
        </div>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { changePassword })(ChangePassword);
