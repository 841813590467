import React, { useState, useMemo, useEffect } from "react";
import PaymentMethods from "../../assets/images/payment-methods.png";
import { CardNumberElement, CardExpiryElement, useElements, useStripe, CardCvcElement } from "@stripe/react-stripe-js";
import {
  Box,
  Paper,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  TextField,
  Button,
  useMediaQuery,
  Select,
  MenuItem,
} from "@material-ui/core";
import { pricingData } from "../../utils/pricingData";
import countryList from "react-select-country-list";
import { connect } from "react-redux";
import { onCheckout } from "../../redux/payments/paymentActions";

const stripeStyling = {
  style: {
    base: {
      fontSize: "16px",
    },
  },
};

const Payment = ({ onCheckout, history }) => {
  const [plan, setPlan] = useState();
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    country: "",
    zip: "",
    city: "",
    paymentInfo: "",
    cardName: "",
    cardNumber: "",
    expDate: "",
    securityCode: "",
    terms: false,
  });
  const matches = useMediaQuery("(min-width:1100px)");
  const options = useMemo(() => countryList().getData(), []);
  const stripe = useStripe();
  const elements = useElements();
  const smMatch = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const onInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    let chosenPlan = pricingData.find((item) => item.heading === id);
    setPlan(chosenPlan);
  }, []);

  // Handle real-time validation errors from the CardElement.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };
  // Hand

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    // const cardNumber = elements.getElement(CardNumberElement);

    // const {  } = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: cardNumber,
    // });

    // onCheckout(
    //   {
    //     email: formData.email,
    //     payment_method_id: paymentMethod.id,
    //     sub_type: mode,
    //   },
    //   history
    // );
  };

  return (
    <Paper>
      {plan && (
        <React.Fragment>
          <Box display="flex" justifyContent="center" alignItems="center" padding={3}>
            <h3>{plan.heading} Payment</h3>
          </Box>
          <Box justifyContent="center" alignItems="center" padding={3} paddingX={!matches ? 3 : 40}>
            <Typography style={{ fontWeight: "600", fontSize: "24px", textAlign: "left", color: "rgba(0, 0, 0, 0.8)" }}>
              Billing Details
            </Typography>
            <form style={{ margin: "20px 0px" }} onSubmit={handleSubmit}>
              <FormGroup style={{ margin: "20px 0px" }}>
                <label>Email</label>
                <TextField
                  variant="outlined"
                  type="text"
                  size="small"
                  value={formData.email}
                  name="email"
                  onChange={(e) => onInputChange(e)}
                ></TextField>
              </FormGroup>

              <div className="card-errors" role="alert">
                {error}
              </div>
              <FormGroup style={{ margin: "20px 0px" }}>
                <label>Country</label>
                <Select
                  name="country"
                  value={formData.country}
                  onChange={(e) => onInputChange(e)}
                  style={{ maxHeight: "45px", overflow: "hidden" }}
                  variant="outlined"
                  fullWidth
                >
                  {options.map((item) => (
                    <MenuItem value={item.label}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormGroup>
              <FormGroup style={{ margin: "20px 0px" }}>
                <label>Zip</label>
                <TextField
                  variant="outlined"
                  type="text"
                  size="small"
                  name="zip"
                  value={formData.zip}
                  onChange={(e) => onInputChange(e)}
                ></TextField>
              </FormGroup>
              <FormGroup style={{ margin: "20px 0px" }}>
                <label>City</label>
                <TextField
                  variant="outlined"
                  type="text"
                  size="small"
                  name="city"
                  value={formData.city}
                  onChange={(e) => onInputChange(e)}
                ></TextField>
              </FormGroup>
              <div style={{ height: "1px", background: "rgba(0, 0, 0, 0.12)", margin: "40px" }}></div>
              <Box display="flex" justifyContent="space-between">
                <Typography
                  style={{ fontWeight: "600", fontSize: "24px", textAlign: "left", color: "rgba(0, 0, 0, 0.8)" }}
                >
                  Payment Method
                </Typography>
                <Box>
                  <img src={PaymentMethods} alt="cards"></img>
                </Box>
              </Box>
              <Box style={{ marginTop: "25px" }}>
                <FormGroup style={{ margin: "20px 0px" }}>
                  <label>Payment Information</label>
                  <TextField
                    variant="outlined"
                    type="text"
                    size="small"
                    name="paymnentInfo"
                    disabled={true}
                    value={plan.heading}
                    onChange={(e) => onInputChange(e)}
                  ></TextField>
                </FormGroup>
                <FormGroup style={{ margin: "20px 0px" }}>
                  <label>Card Holder's Name</label>
                  <TextField
                    variant="outlined"
                    type="text"
                    size="small"
                    name="cardName"
                    value={formData.cardName}
                    onChange={(e) => onInputChange(e)}
                  >
                    {" "}
                  </TextField>
                </FormGroup>
                <FormGroup style={{ margin: "20px 0px" }}>
                  <label>Card Number</label>
                  <CardNumberElement id="card-number" onChange={handleChange} options={stripeStyling} />
                </FormGroup>
                <Box display="flex" flexDirection={smMatch ? "row" : "column"}>
                  <Box display="flex">
                    <FormGroup style={{ width: smMatch ? "200px" : "100%" }}>
                      <label>Exp. Date</label>
                      <CardExpiryElement id="card-exp" onChange={handleChange} options={stripeStyling} />
                    </FormGroup>
                  </Box>
                  <FormGroup style={{ margin: !smMatch ? "20px 0px" : "0px 20px" }}>
                    <label>Security Code</label>
                    <CardCvcElement id="card-cvc" onChange={handleChange} options={stripeStyling} />
                  </FormGroup>
                </Box>
                {/* <CardElement id="card-element" onChange={handleChange} options={stripeStyling} /> */}
              </Box>
              {/* {              <FormGroup style={{ margin: "20px 0px" }}>
                <label>Payment Information</label>
                <TextField
                  variant="outlined"
                  type="text"
                  size="small"
                  name="paymnentInfo"
                  value={formData.paymentInfo}
                  onChange={(e) => onInputChange(e)}
                ></TextField>
              </FormGroup>
              <FormGroup style={{ margin: "20px 0px" }}>
                <label>Card Holder's Name</label>
                <TextField
                  variant="outlined"
                  type="text"
                  size="small"
                  name="cardName"
                  value={formData.cardName}
                  onChange={(e) => onInputChange(e)}
                >
                  {" "}
                </TextField>
              </FormGroup>
              <FormGroup style={{ margin: "20px 0px" }}>
                <label>Card Number</label>
                <TextField
                  variant="outlined"
                  type="text"
                  size="small"
                  name="cardNumber"
                  value={formData.cardName}
                  onChange={(e) => onInputChange(e)}
                ></TextField>
              </FormGroup>
              <Box display="flex" flexDirection={smMatch ? "row" : "column"}>
                <Box display="flex" alignItems="center">
                  <FormGroup>
                    <label>Exp. Date</label>
                    <TextField
                      variant="outlined"
                      type=""
                      size="small"
                      name="expDate"
                      value={formData.expDate}
                      onChange={(e) => onInputChange(e)}
                    ></TextField>
                  </FormGroup>
                  <span style={{ paddingTop: "25px", margin: "0px 10px" }}> / </span>
                  <FormGroup style={{ paddingTop: "35px" }}>
                    <TextField variant="outlined" type="text" size="small"></TextField>
                  </FormGroup>
                </Box>
                <FormGroup style={{ margin: !smMatch ? "20px 0px" : "0px 20px" }}>
                  <label>Security Code</label>
                  <TextField
                    variant="outlined"
                    type="text"
                    size="small"
                    name="securityCode"
                    value={formData.securityCode}
                    onChange={(e) => onInputChange(e)}
                  ></TextField>
                </FormGroup>
              </Box>} */}
              <div style={{ height: "1px", background: "rgba(0, 0, 0, 0.12)", margin: "40px" }}></div>
              <Typography>
                By clicking ‘PROCESS MY PAYMENT, I confirm that I am signing up for a subscription product that will be
                billed in regular intervals. Continued use of the purchased product is contingent upon my payment of the
                subscription. Details about the price and frequency of the billing were shown to me in the shopping cart
                above. You may cancel this subscription at any time under the Terms of Service.
              </Typography>
              <Box marginY={3} display="flex">
                <FormControlLabel
                  control={<Checkbox defaultChecked color="primary" />}
                  name="terms"
                  value={formData.terms}
                  onChange={(e) => onInputChange(e)}
                  label="Yes. Please keep me informed by email about product updates, upgrades, special offers and other opportunities"
                />
              </Box>
              <Button color="primary" size="large" type="submit" variant="contained" fullWidth>
                Process my payment
              </Button>
            </form>
          </Box>
        </React.Fragment>
      )}
    </Paper>
  );
};

export default connect(null, { onCheckout })(Payment);
