import React, { useState } from "react";
import Icon from "../../../assets/images/icons/Logo 1.svg";
import LogoSm from "../../../assets/images/icons/logo.ico";
import Notification from "../../../assets/images/icons/notification-bell.png";
import Profile from "../../../assets/images/icons/navbar-profile-icon.png";
import { logoutAction } from "../../../redux/auth/AuthActions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { postSurvey } from "../../../redux/survey/SurveyActions";
import { Box, Button, Menu, MenuItem, Fade, Grid } from "@material-ui/core";
import SurveyNav from "../survey/SurveyNav";

const AuthNav = ({ history, logoutAction, auth, surveyPage }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container className="header">
      <Grid item xs={3}>
        <a className="links" href="/">
          <Box display={ {xs : "none", sm: "block"}} className="brand-container">
            <img src={Icon} alt="Brand" className="brand-icon" />
          </Box>
          <Box display={ {xs : "block", sm: "none"}} className="brand-container">
            <img src={LogoSm} alt="Brand" style={{height : "25px", width:"25px"}} />
          </Box>
        </a>
      </Grid>
      {surveyPage && (
        <Box marginTop={3.3} display={{ xs: "none", md: "block" }}>
          <SurveyNav />
        </Box>
      )}
      <div className="hnav-container">
        {auth.isAuthenticated ? (
          <React.Fragment>
            <Box margin={1} className="hnav">
              <img src={Profile} aria-controls="long-menu" onClick={handleClick} alt="" />
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{ style: { marginTop: "40px" } }}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={handleClose}>
                  <Link to="/profile" className="links">
                    {" "}
                    View Profile{" "}
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  {" "}
                  <Link to="/pricing" className="links">
                    Pricing and Bills
                  </Link>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    logoutAction(history);
                  }}
                >
                  {" "}
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box margin="15px 5px" className="hnav">
              <Link className="links" to="/login">
                <Button color="primary" size="large">
                  <span className="btn-text">Login</span>
                </Button>
              </Link>
            </Box>
            <Box margin="15px 5px" className="hnav">
              <Link className="links" to="/register">
                <Button color="primary" variant="contained" size="large">
                  <span className="btn-text">Register</span>
                </Button>
              </Link>
            </Box>
          </React.Fragment>
        )}
      </div>
    </Grid>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutAction, postSurvey })(AuthNav);
