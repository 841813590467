import React, { useEffect, useState } from "react";
import { getAllSurvey, postSurvey, resetSlug } from "../../redux/survey/SurveyActions";
import { connect } from "react-redux";
import { Box, TextField, InputAdornment, Grid, CircularProgress, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Link } from "react-router-dom";

import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import DescriptionIcon from "@material-ui/icons/Description";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import Education from "../../assets/images/workplace/school-bag.png";
import PlusBlack from "../../assets/images/icons/plus-black.png";
import TemplateIcon from "../../assets/images/icons/template-icon.png";
import Plus from "../../assets/images/icons/plus.png";
import Event from "../../assets/images/icons/event-note.png";
import WorkplaceCards from "../../components/workplace-card/WorkplaceCard";
import { resetResponse } from "../../redux/response/ResponseAction";
import { getTemplateCategories, getTemplates } from "../../redux/template/templateAction";
import TemplateList from "../../components/template-list/TemplateList";
import { getUser } from "../../redux/user/UserActions";

const Workplace = ({
  getAllSurvey,
  getTemplateCategories,
  getTemplates,
  template,
  survey,
  resetSlug,
  getUser,
  resetResponse,
  user,
}) => {
  const [tab, setTab] = useState(1);
  const [templateSelect, setTemplateSelect] = useState({
    templateName: "",
    templateId: "",
    state: false,
  });
  const [surveyData, setSurveyData] = useState([]);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    getUser();
    resetResponse();
    getAllSurvey();
    getTemplateCategories();
    getTemplates();
    resetSlug();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (survey) {
      setSurveyData(survey.allSurvey);
    }
  }, [survey]);

  useEffect(() => {
    let defaultData = surveyData;
    if (filterText !== "") {
      let filterData = defaultData.filter((item) => item.title.toLowerCase().includes(filterText.toLowerCase()));
      setSurveyData([...filterData]);
    } else {
      setSurveyData(survey.allSurvey);
    }
  }, [filterText]);

  return (
    <div className="at-block">
      <Grid container>
        <Grid // for sm tabs
          item
          component={Box}
          display={{ xs: "block", lg: "none" }}
          className="workplace-block-sm"
        >
          <div className="workplace-sm-title">
            <h5>Workspace</h5>
          </div>
          <Grid item container style={{ gap: 15, marginTop: 25 }}>
            <Grid
              item
              container
              justifyContent="center"
              alignItem="center"
              xs
              onClick={() => {
                setTab(1);
                setTemplateSelect({ ...templateSelect, state: false });
              }}
              className={`workplace-aside-block-items-sm ${tab === 1 ? "on" : null}`}
            >
              <div className="aside-block-item-icon">
                <InsertDriveFileIcon className="responsive-icon" />
              </div>
              <p className="workplace-item-text">Browse Item</p>
            </Grid>
            <Grid
              container
              item
              xs
              justifyContent="center"
              alignItem="center"
              onClick={() => {
                setTab(2);
                setTemplateSelect({ ...templateSelect, state: false });
              }}
              className={`workplace-aside-block-items-sm ${tab === 2 ? "on" : ""} `}
            >
              <div className="aside-block-item-icon">
                <DescriptionIcon className="responsive-icon" />
              </div>
              <p className="workplace-item-text">Recent Items</p>
            </Grid>
            <Grid
              item
              container
              xs
              justifyContent="center"
              alignItem="center"
              onClick={() => {
                setTab(3);
                setTemplateSelect({ ...templateSelect, state: false });
              }}
              className={`workplace-aside-block-items-sm ${tab === 3 ? "on" : ""} `}
            >
              <div className="aside-block-item-icon">
                <img src={TemplateIcon} alt="" />
              </div>
              <p className="workplace-item-text">Templates</p>
            </Grid>
          </Grid>
          {tab === 1 && (
            <React.Fragment>
              <div className="create-survey-container">
                <div className="create-survey">
                  <span>Create new file</span>
                  <Link to="/create-form" className="links">
                    <div className="create-survey-input">
                      <img className="create-survey-icon-sm" src={Plus} alt="sm" />
                    </div>
                  </Link>
                </div>
              </div>
              <Box marginTop={3}>
                <div className="workplace-title">
                  <h6 className="title">Recent</h6>
                </div>
                <Grid container className="workplace-cards">
                  {!survey.loading && survey.allSurvey ? (
                    survey.allSurvey.length === 0 ? (
                      <p>No created forms available</p>
                    ) : (
                      survey.allSurvey.map((item, index) => {
                        let date = new Date(item.form_creation_time);
                        return (
                          <WorkplaceCards
                            key={index}
                            serialNumber={index + 1}
                            item={item}
                            date={date}
                            survey={survey}
                          />
                        );
                      })
                    )
                  ) : (
                    <CircularProgress />
                  )}
                </Grid>
              </Box>
            </React.Fragment>
          )}
          {tab === 2 && (
            <React.Fragment>
              <Box marginTop={3}>
                <div className="workplace-title">
                  <h6 className="title">Recent</h6>
                </div>
                <Grid container className="workplace-cards">
                  {survey.allSurvey &&
                    survey.allSurvey.map((item, index) => {
                      let date = new Date(item.form_creation_time);
                      return (
                        <WorkplaceCards key={index} serialNumber={index + 1} item={item} date={date} survey={survey} />
                      );
                    })}
                </Grid>
              </Box>
            </React.Fragment>
          )}
          {tab === 3 && (
            <React.Fragment>
              <Box marginTop={3}>
                {template.allTemplateCategories.length > 0 ? (
                  template.allTemplateCategories.map((item, key) => {
                    return (
                      <React.Fragment key={key}>
                        <Grid
                          container
                          item
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          xs={2}
                          className="workspace-category-pill"
                        >
                          <Grid item>
                            <img className="category-pill-icon" src={Event} alt=""></img>
                          </Grid>
                          <Grid item>
                            <span className="category-pill-text">{item.category_name}</span>
                          </Grid>
                        </Grid>
                        <div className="workplace-block">
                          <Grid container className="workplace-cards">
                            {user.user && user.user[0].is_staff && (
                              <Link className="links" to={`/create-template/${item.category_name}`}>
                                <div className="workplace-item">
                                  <div className="workplace-card content-center">
                                    <div className="workplace-text content-center">
                                      <p className="template-header-create-survey">Create new template</p>
                                      <img className="create-survey-icon" src={PlusBlack} alt=""></img>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            )}
                            {template.allTemplates &&
                              template.allTemplates.map((template, index) => {
                                if (item.id === template.category) {
                                  let date = new Date(template.form_creation_time);

                                  return (
                                    <WorkplaceCards
                                      key={index}
                                      serialNumber={index + 1}
                                      item={template}
                                      template={true}
                                      date={date}
                                      survey={template}
                                    />
                                  );
                                } else {
                                  return <Typography></Typography>;
                                }
                              })}
                          </Grid>
                        </div>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <Typography>Currently no templates are available</Typography>
                )}
              </Box>
            </React.Fragment>
          )}
        </Grid>

        <Grid // main aside nav
          component={Box}
          container
          item
          display={{ xs: "none", lg: "block" }}
        >
          <Grid component={Box} display={{ xs: "none", lg: "block" }} item lg={3} xl={2} className="aside-container">
            <h3 className="aside-header">Workspace</h3>
            <Box margin={3}>
              <TextField
                type="text"
                variant="outlined"
                label="Search"
                size="small"
                onChange={(ev) => setFilterText(ev.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Box>
            <div className="aside-block">
              <h5 className="aside-header-block">Files</h5>
              <div className="aside-grid">
                <div
                  className="aside-block-items"
                  onClick={() => setTemplateSelect({ ...templateSelect, state: false })}
                >
                  <div className="aside-block-item-icon">
                    <InsertDriveFileIcon />
                  </div>
                  <p>Browse Item</p>
                </div>
                <div
                  className="aside-block-items"
                  onClick={() => setTemplateSelect({ ...templateSelect, state: false })}
                >
                  <div className="aside-block-item-icon">
                    <DescriptionIcon />
                  </div>
                  <p>Recent Item</p>
                </div>
              </div>
            </div>
            <div className="aside-block">
              <div className="aside-header-group">
                <h5 className="aside-header-block">Templates</h5>
                <p>See All</p>
              </div>
              <div className="aside-grid">
                {template.allTemplateCategories.length > 0 ? (
                  template.allTemplateCategories.map((item, key) => {
                    return (
                      <div
                        key={item.key}
                        className="aside-block-items"
                        onClick={() =>
                          setTemplateSelect({ templateName: item.category_name, templateId: item.id, state: true })
                        }
                      >
                        <div className="aside-block-item-icon">
                          <BusinessCenterIcon />
                        </div>
                        <p>{item.category_name}</p>
                      </div>
                    );
                  })
                ) : (
                  <Typography>Currently no templates are available</Typography>
                )}
              </div>
            </div>
          </Grid>
          {templateSelect.state ? (
            <TemplateList
              templateName={templateSelect.templateName}
              template={template}
              templateId={templateSelect.templateId}
            />
          ) : (
            <Grid
              component={Box}
              display={{ xs: "none", lg: "block" }}
              item
              lg={9}
              xl={10}
              className="workplace-container"
            >
              <div className="workplace-block">
                <div className="workplace-cards">
                  <Link className="links" to="/create-form">
                    <div className="workplace-item">
                      <div className="workplace-card content-center">
                        <div className="workplace-text content-center">
                          <p className="template-header-create-survey">Create new survey</p>
                          <img className="create-survey-icon" src={PlusBlack} alt=""></img>
                        </div>
                      </div>
                    </div>
                  </Link>
                  {template.allTemplateCategories.length > 0 &&
                    template.allTemplateCategories.map((item) => {
                      return (
                        <div className="workplace-item" key={item.category_name}>
                          <div className="workplace-card">
                            <img
                              src={Education}
                              alt="workplace"
                              onClick={() =>
                                setTemplateSelect({
                                  templateName: item.category_name,
                                  templateId: item.id,
                                  state: true,
                                })
                              }
                            />
                            <div className="workplace-text">
                              <p className="template-header">{item.category_name}</p>
                              <p className="template-type">Category</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="workplace-block">
                <div className="workplace-title">
                  <h6 className="title">Recent</h6>
                </div>
                <Grid container className="workplace-cards">
                  {surveyData && surveyData.length ? (
                    surveyData.map((item, index) => {
                      let date = new Date(item.form_creation_time);
                      return (
                        <WorkplaceCards key={index} serialNumber={index + 1} item={item} date={date} survey={survey} />
                      );
                    })
                  ) : (
                    <div>No surveys found</div>
                  )}
                </Grid>
              </div>
              <Box marginTop={3} className="workplace-block">
                <span>Templates</span>
                <Grid container direction="column" className="workspace-category-container">
                  {template.allTemplateCategories.length > 0 ? (
                    template.allTemplateCategories.map((item, key) => {
                      return (
                        <React.Fragment key={key}>
                          <Grid
                            container
                            item
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            xs={2}
                            className="workspace-category-pill"
                          >
                            <Grid item>
                              <img className="category-pill-icon" src={Event} alt=""></img>
                            </Grid>
                            <Grid item>
                              <span className="category-pill-text">{item.category_name}</span>
                            </Grid>
                          </Grid>
                          <div className="workplace-block">
                            <Grid container className="workplace-cards">
                              {template.allTemplates &&
                                template.allTemplates.map((template, index) => {
                                  if (item.id === template.category) {
                                    let date = new Date(template.form_creation_time);
                                    return (
                                      <WorkplaceCards
                                        key={index}
                                        serialNumber={index + 1}
                                        item={template}
                                        template={true}
                                        date={date}
                                        survey={template}
                                      />
                                    );
                                  } else {
                                    return <Typography></Typography>;
                                  }
                                })}
                            </Grid>
                          </div>
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <Typography>Currently no templates are available</Typography>
                  )}
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => ({
  survey: state.survey,
  template: state.template,
  user: state.user,
});
export default connect(mapStateToProps, {
  getAllSurvey,
  postSurvey,
  resetSlug,
  getTemplateCategories,
  getTemplates,
  getUser,
  resetResponse,
})(Workplace);
