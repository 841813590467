import React, { useEffect, useState } from "react";
import { Checkbox, Radio, Box, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { addResponse } from "../../redux/response/ResponseAction";

const FormPreviewQs = ({ qtype, data, uId, addResponse, required }) => {
  const [val, setVal] = useState("");
  const [checkboxVal, setCheckboxVal] = useState("");

  useEffect(() => {
    if (qtype === "checkbox") {
      addResponse(uId, checkboxVal, "Checkbox", required);
    }
  }, [checkboxVal]); // eslint-disable-line react-hooks/exhaustive-deps

  const setCheckedVal = (e, type) => {
    const value = e.target.value;
    setVal(value);
    addResponse(uId, value, type, required);
  };

  const setCheckboxValue = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    let defaultCheckbox = checkboxVal;

    if (value === "Other") {
      setVal("Other");
    }

    if (checked) {
      setCheckboxVal([...checkboxVal, value]);
    } else {
      let filteredResult = defaultCheckbox.filter((item) => item !== value);
      if (filteredResult.length === 0) {
        setCheckboxVal("");
      } else {
        setCheckboxVal([...filteredResult]);
      }
    }
  };

  const onTextChange = (e) => {
    const value = e.target.value;
    addResponse(uId, { other: value }, qtype, required);
  };

  return (
    <React.Fragment>
      {data &&
        data.map((item) => {
          return (
            <Box key={item.id}>
              {qtype === "checkbox" ? (
                <Box display="row">
                  <Checkbox
                    name="checkedA"
                    color="primary"
                    style={{
                      transform: "scale(0.8)",
                    }}
                    size="small"
                    onChange={setCheckboxValue}
                    value={item.label}
                  />
                  <span className="qs-preview-label">{item.label}</span>
                </Box>
              ) : (
                <Box display="row">
                  <Radio
                    color="primary"
                    style={{
                      transform: "scale(0.8)",
                    }}
                    onClick={(e) => setCheckedVal(e, "Multiple Choice")}
                    value={item.label}
                    checked={val === item.label}
                    size="small"
                  />
                  <span className="qs-preview-label">{item.label}</span>
                </Box>
              )}
            </Box>
          );
        })}
      {val === "Other" && (
        <Box margin="15px 0px">
          <TextField
            type="text"
            variant="standard"
            placeholder="Your Answer"
            onChange={onTextChange}
            fullWidth
          ></TextField>
        </Box>
      )}
    </React.Fragment>
  );
};
export default connect(null, { addResponse })(FormPreviewQs);
