import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Box, Grid } from "@material-ui/core";
import Education from "../../assets/images/workplace/school-bag.png";
import FormPreviewCard from "../../components/form-preview-card/FormPreviewCard";
import { getSurvey } from "../../redux/survey/SurveyActions";
import { getClientIp, getPreviewSlug, addResponse } from "../../redux/response/ResponseAction";
import Pagination from "../../components/partials/Pagination";
import AttachmentOutlinedIcon from "@material-ui/icons/AttachmentOutlined";
import { CircularProgressbar } from "react-circular-progressbar";
import { AttachFileOutlined } from "@material-ui/icons";

const SurveyPreviewWithId = ({ match, survey, getSurvey, response, getPreviewSlug, getClientIp }) => {
  const { surveyObj } = survey;
  const [pageContent, setPageContent] = useState();
  const [shareid] = useState(match.params.id);

  useEffect(() => {
    getPreviewSlug(shareid);
    getClientIp();
    getSurvey(shareid);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPageContent(surveyObj);
  }, [surveyObj]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    var formsPerPage;
    if (surveyObj !== undefined) {
      formsPerPage = surveyObj.form_data.find((item) => item.id === survey.currentPage);
      let defaultSurveyObject = {
        ...surveyObj,
        form_page: formsPerPage,
      };
      setPageContent(defaultSurveyObject);
    }
  }, [survey.currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDownload = (attachment) => {
    const link = attachment.attachment_file;
    const downloadLink = document.createElement("a");
    downloadLink.href = link;
    downloadLink.download = attachment.attachment_name;
    downloadLink.click();
  };

  const findEmptyResponse = () => {
    let count = 0;
    response.response.forEach((item) => {
      if (item.response !== "") {
        count = count + 1;
      }
    });

    let average = (count / response.response.length) * 100;
    return average.toFixed(0);
  };

  console.log(pageContent);
  return (
    <div className="sv-block" style={{ paddingBottom: "70px" }}>
      {pageContent && (
        <React.Fragment>
          <div className="sv-block-header">
            <div className="sv-block-image-container">
              <img
                src={
                  pageContent.form_page.coverImg
                    ? `data:${pageContent.form_page.coverImg.type};base64,${pageContent.form_page.coverImg.base64}`
                    : Education
                }
                className="preview-image"
                alt="survey"
              ></img>
            </div>
            <div className="header-form-group">
              <div className="sv-preview-header-text">
                <h6 className="sv-preview-title">{pageContent.form_page.title}</h6>
                <p className="sv-preview-desc">{pageContent.form_page.desc}</p>
              </div>
            </div>
          </div>
          <div className="form-cards">
            {pageContent.form_page.forms.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  {item.selectedDropdown === "Section" ? (
                    <div className="fc-block-container">
                      <div className="sv-block-image-container">
                        <img
                          src={
                            item.formFields && item.formFields.image
                              ? `data:${item.formFields.image.type};base64,${item.formFields.image.base64}`
                              : Education
                          }
                          className="preview-image"
                          alt="survey"
                        ></img>
                      </div>
                      <div className="header-form-group">
                        <div className="sv-preview-header-text">
                          <h6 className="sv-preview-title">{item.formFields && item.title}</h6>
                          <p className="sv-preview-desc">{item.formFields && item.desc}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="fc-block-preview" key={idx}>
                      <div className="fc-block-header">
                        <h6 className="fc-preview-title">
                          {item.required ? "*" : ""}
                          {item.question ? item.question : "No question yet!"}
                        </h6>
                      </div>
                      <Box marginX="20px">
                        <FormPreviewCard
                          type={item.selectedDropdown}
                          uId={item.id}
                          data={item.formFields}
                          required={item.required}
                        />
                        {item.attachment && (
                          <Box
                            marginTop={3}
                            display="flex"
                            flexDirection="row"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleDownload(item.attachment)}
                          >
                            <AttachFileOutlined style={{ transform: "scale(0.7)" }} />
                            <p style={{ fontSize: "12px", marginTop: "5px" }}>{item.attachment.attachment_name}</p>
                          </Box>
                        )}
                      </Box>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
            <Pagination forms={pageContent.form_data} />
          </div>
        </React.Fragment>
      )}
      <Box className="completion-state" display={{ xs: "none", lg: "block" }}>
        <Grid container direction="column">
          <Box component={Grid} item className="completion-link" justifyContent="center" alignItems="center">
            <AttachmentOutlinedIcon color="primary" />
          </Box>
          <Grid item>
            <Box item height="50px" width="50px" marginTop="20px">
              {pageContent && <CircularProgressbar value={findEmptyResponse()} text={`${findEmptyResponse()}%`} />}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="completion-state-sm" display={{ xs: "block", lg: "none" }}>
        <Grid container direction="row">
          <Grid item>
            <Box item height="50px" width="50px" marginRight="20px">
              <CircularProgressbar value={findEmptyResponse()} text={`${findEmptyResponse()}%`} />
            </Box>
          </Grid>
          <Box component={Grid} item className="completion-link" justifyContent="center" alignItems="center">
            <AttachmentOutlinedIcon color="primary" />
          </Box>
        </Grid>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  survey: state.survey,
  response: state.response,
});

export default withRouter(
  connect(mapStateToProps, {
    getSurvey,
    getPreviewSlug,
    getClientIp,
    addResponse,
  })(SurveyPreviewWithId)
);
