import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import PlusBlack from "../../assets/images/icons/plus-black.png";
import { Link } from "react-router-dom";
import WorkplaceCards from "../../components/workplace-card/WorkplaceCard";

const TemplateList = ({ template, templateName, templateId, user }) => {
  return (
    <Box marginTop={3} className="workplace-block">
      <h4>{templateName} Templates</h4>
      <Grid container direction="column" className="workspace-category-container">
        <div className="workplace-block">
          <Grid container className="workplace-cards">
            {user.user && user.user[0].is_staff && (
              <Link className="links" to={`/create-template/${templateName}`}>
                <div className="workplace-item">
                  <div className="workplace-card content-center">
                    <div className="workplace-text content-center">
                      <p className="template-header-create-survey">Create new template</p>
                      <img className="create-survey-icon" src={PlusBlack} alt=""></img>
                    </div>
                  </div>
                </div>
              </Link>
            )}
            {template.allTemplates &&
              template.allTemplates.map((template, index) => {
                if (templateId === template.category) {
                  let date = new Date(template.form_creation_time);
                  return (
                    <WorkplaceCards
                      key={index}
                      serialNumber={index + 1}
                      item={template}
                      template={true}
                      date={date}
                      survey={template}
                    />
                  );
                } else {
                  return <Typography></Typography>;
                }
              })}
          </Grid>
        </div>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(TemplateList);
