import React, { useEffect, useState } from "react";
import GroupDot from "../../assets/images/icons/qs-group.png";
import { svQList } from "../../data/Data";
import { connect } from "react-redux";
import { changeType, onQuestionChange, addAttachment, setRequired } from "../../redux/survey/SurveyActions";
import FormBody from "./FormBody";
import TextareaAutosize from "react-textarea-autosize";
import { Grid, MenuItem, Select, Switch, FormControlLabel, Box, useMediaQuery } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import AddQuestionButton from "../partials/AddQuestionButton";
import { AttachFileOutlined } from "@material-ui/icons";
import { toast } from "react-toastify";

const FormCard = ({
  sr,
  form,
  uId,
  changeType,
  onQuestionChange,
  onDeleteCard,
  onCopyForm,
  handleDrag,
  addAttachment,
  addQuestion,
  setRequired,
  page,
  survey,
  ...props
}) => {
  const { selectedDropdown, question } = form;
  const [type, setType] = useState(`${selectedDropdown}`);
  const [required, updateRequired] = useState(false);
  const lower = useMediaQuery("(max-width:720px)");

  useEffect(() => {
    if (form.required !== undefined) {
      updateRequired(form.required);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setRequired(required, uId, page);
  }, [required]); // eslint-disable-line react-hooks/exhaustive-deps

  const onTypeChange = (e) => {
    setType(e.target.value);
    changeType(uId, e.target.value);
  };

  const getAttachments = (id, page) => {
    let defaultForms = survey.forms;
    let defaultPage = defaultForms.find((item) => item.page === page);
    let item = defaultPage.forms.find((item) => item.id === id);
    if (item.attachment !== undefined) {
      return true;
    }
  };

  const fileClick = () => {
    document.getElementById(`selectAttachment ${uId}`).click();
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        let attachmentObject = {
          attachment_link: null,
          attachment_file: baseURL,
          attachment_name: file.name,
        };

        addAttachment(uId, attachmentObject, page);

        resolve(baseURL);
      };
    });
  };

  const handleFileInputChange = (e) => {
    let file = e.target.files[0];
    if (file.size === 75000) {
      toast.error("File size too big");
    } else {
      getBase64(file)
        .then((result) => {
          file["base64"] = result;
        })
        .catch((err) => {
          toast.error("File upload error, please try again");
        });
    }
  };

  return (
    <div className="fc-block-container">
      <div className="fc-block" {...props}>
        <div className="fc-block-header">
          <div className="sr-number" {...handleDrag}>
            <img src={GroupDot} alt="grouped dot"></img>
            <span>Q{sr + 1}</span>
          </div>
          <TextareaAutosize
            className="fc-question-input form-input-alt"
            placeholder="Question"
            onChange={(e) => onQuestionChange(e, uId, page)}
            defaultValue={question ? question : ""}
          ></TextareaAutosize>
        </div>
        <div className="fc-block-body">
          <FormBody type={type} uId={uId} form={form} page={page}></FormBody>
        </div>
        <div className="fc-block-footer">
          <Grid container direction={`${lower ? "column" : "row"}`} justifyContent="space-between">
            <Grid item md={6} justifyContent={`${lower}`} className="fc-block-footer-grid">
              <Select
                value={type}
                disableUnderline
                className="fc-select"
                variant="standard"
                size={`${lower ? "small" : "medium"}`}
                onChange={(e) => onTypeChange(e, uId)}
              >
                {svQList.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.text}>
                      <div className="fc-menu-item">
                        <span className="menu-item-icon">{item.icon}</span>
                        <span className="menu-item-text">{item.text}</span>
                      </div>
                    </MenuItem>
                  );
                })}
              </Select>

              {getAttachments(uId, page) === true && (
                <Box marginTop={3} display="flex" flexDirection="row">
                  <AttachFileOutlined style={{ transform: "scale(0.7)" }} />
                  <p style={{ fontSize: "10px", marginTop: "5px" }}>Attchment uploaded</p>
                </Box>
              )}
            </Grid>
            <Grid item className="fc-block-footer-grid" md={6}>
              <div className="footer-icon-container content-center" onClick={() => onDeleteCard(uId, page)}>
                <DeleteOutlineOutlinedIcon size={`${lower ? "small" : "medium"}`} />
              </div>
              <div className="footer-icon-container content-center" onClick={() => fileClick()}>
                <PublishOutlinedIcon size={`${lower ? "small" : "medium"}`} />
                <input
                  type="file"
                  id={`selectAttachment ${uId}`}
                  name="file-up"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileInputChange(e)}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
              </div>
              <div className="footer-icon-container content-center" onClick={() => onCopyForm(uId, page)}>
                <FileCopyOutlinedIcon size={`${lower ? "small" : "medium"}`} />
              </div>
              <div className="footer-switch">
                <p className="required-checkbox">
                  <span className={required ? "required-astirk" : ""}>*</span> Required
                </p>
                <FormControlLabel
                  style={{ marginBottom: "15px" }}
                  control={
                    <Switch
                      color="primary"
                      defaultChecked={form.required !== undefined ? form.required : required}
                      size={`${lower ? "small" : "medium"}`}
                    />
                  }
                  onChange={(e) => updateRequired(e.target.checked)}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <AddQuestionButton addQuestion={addQuestion} uId={uId} page={page} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  survey: state.survey,
});
export default connect(mapStateToProps, {
  changeType,
  onQuestionChange,
  addAttachment,
  setRequired,
})(FormCard);
