import { createMuiTheme } from "@material-ui/core";
export default createMuiTheme({
  palette: {
    primary: {
      main: "#0085A4",
    },
    secondary: {
      main: "#FF2D55",
    },
    error: {
      main: "#d9534f",
      backgroundColor: "#d9534f",
    },
    success: {
      main: "#5cb85c",
      backgroundColor: "#5cb85c",
    },
    warning: {
      main: "#f0ad4e",
      backgroundColor: "#f0ad4e",
    },
    customGrey: {
      main: "#C4C4C4",
      backgroundColor: "#C4C4C4",
    },
  },
});
