import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Box } from "@material-ui/core";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";

const Doughnut = ({ pathColor, data, trailColor, emotion }) => {
  const circularStyle = {
    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
    strokeLinecap: "round",
    // Text size
    textSize: "14px",
    // How long animation takes to go from one percentage to another, in seconds
    pathTransitionDuration: 0.5,
    // Can specify path transition in more detail, or remove it entirely
    // pathTransition: 'none',
    // Colors
    pathColor: pathColor,
    textColor: "rgba(99, 99, 102, 1)",
    trailColor: trailColor,
    backgroundColor: "#3e98c7",
  };
  return (
    <Box
      style={{
        border: "1px solid rgba(99, 99, 102, 1)",
        borderRadius: "5px",
        padding: "5px 25px 25px",
        margin: "15px",
      }}
    >
      {emotion === "happy" ? (
        <Box margin="5px 0px 15px">
          <InsertEmoticonIcon
            style={{ background: pathColor, borderRadius: "20px" }}
          />{" "}
          Happy
        </Box>
      ) : emotion === "normal" ? (
        <Box margin="5px 0px 15px">
          <SentimentSatisfiedIcon
            style={{ background: pathColor, borderRadius: "20px" }}
          />{" "}
          Neutral
        </Box>
      ) : (
        <Box margin="5px 0px 15px">
          <SentimentVeryDissatisfiedIcon
            style={{ background: pathColor, borderRadius: "20px" }}
          />{" "}
          Angry
        </Box>
      )}
      <CircularProgressbar
        text={`${data}%`}
        value={data}
        strokeWidth={15}
        styles={buildStyles(circularStyle)}
      />
    </Box>
  );
};
export default Doughnut;
