import React, { useState } from "react";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import {
  Box,
  Grid,
  Typography,
  withStyles,
  LinearProgress,
} from "@material-ui/core";
import Chart from "react-apexcharts";
import {
  ArrowDownward,
  ArrowUpward,
  ArrowBack,
  ArrowForward,
} from "@material-ui/icons";
import { Pie, Bar } from "react-chartjs-2";
import { baroptions, pieoptions, boxplotOptions } from "../../data/Data";
import Doughnut from "../charts/dougnut/Dougnut";
import StarIcon from "@material-ui/icons/Star";
import Rating from "@material-ui/lab/Rating";
import AnalyticsTable from "../analytics-table/AnalyticsTable";
import { useSelector } from "react-redux";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 11,
    borderRadius: 5,
    marginTop: 5,
    marginLeft: 25,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#0085a4",
  },
}))(LinearProgress);

export const CheckBoxBody = ({ chartTab, qVal, response }) => {
  const [collapse, setCollapse] = useState(false);
  const returnLabel = () => {
    let concat = [];
    qVal.formFields.map((item) => concat.push(item.label));
    return concat;
  };

  const calcVotes = () => {
    let p = [];
    response.map((el) => {
      el.response.map((item) => {
        if (item.id === qVal.id) {
          if (item.response.length > 0) {
            item.response.map((e) => {
              p.push(e);
            });
          }
        }
      });
    });

    return p;
  };

  const countVotes = (arr) => {
    const counts = {};

    for (const num of arr) {
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }

    let countArr = [];
    Object.keys(counts).map((item) => {
      countArr.push(counts[item]);
    });
    return countArr;
  };

  const bardata = {
    labels: returnLabel(),
    datasets: [
      {
        label: "# of Votes",
        data: countVotes(calcVotes()),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const piedata = {
    labels: returnLabel(),
    datasets: [
      {
        label: "# of Votes",
        data: countVotes(calcVotes()),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <React.Fragment>
      <Box margin={2} padding={2} border="1px solid rgba(142, 142, 147, 0.4)">
        {chartTab === 1 ? (
          <div>
            <h5>Bar Chart</h5>
            <Bar data={bardata} options={baroptions} />
          </div>
        ) : (
          <Box style={{ height: "345px", paddingBottom: "25px" }}>
            <h5>Pie Chart</h5>
            <Pie data={piedata} options={pieoptions} />
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end" margin={2}>
        <Typography
          style={{ cursor: "pointer" }}
          onClick={() => setCollapse(!collapse)}
        >
          Statistical Overview{" "}
          {collapse ? (
            <ArrowUpward fontSize="small" style={{ marginBottom: "5px" }} />
          ) : (
            <ArrowDownward fontSize="small" style={{ marginBottom: "5px" }} />
          )}
        </Typography>
      </Box>
      {collapse && (
        <Box padding={2}>
          <AnalyticsTable
            count={countVotes(calcVotes())}
            response={response}
            labels={returnLabel()}
          />
        </Box>
      )}
    </React.Fragment>
  );
};

export const MutipleChoiceBody = ({ chartTab, qVal, response }) => {
  const [collapse, setCollapse] = useState(false);
  const returnLabel = () => {
    let concat = [];
    qVal.formFields.map((item) => concat.push(item.label));
    return concat;
  };

  const calcVotes = () => {
    let p = [];
    response.map((el) => {
      el.response.map((item) => {
        if (item.id === qVal.id) {
          p.push(item.response);
        }
      });
    });

    return p;
  };

  const countVotes = (arr) => {
    const counts = {};

    for (const num of arr) {
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }

    let countArr = [];
    Object.keys(counts).map((item) => {
      countArr.push(counts[item]);
    });
    return countArr;
  };

  const bardata = {
    labels: returnLabel(),
    datasets: [
      {
        label: "# of Votes",
        data: countVotes(calcVotes()),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const piedata = {
    labels: returnLabel(),
    datasets: [
      {
        label: "# of Votes",
        data: countVotes(calcVotes()),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <React.Fragment>
      <Box margin={2} padding={2} border="1px solid rgba(142, 142, 147, 0.4)">
        {chartTab === 1 ? (
          <div>
            <h5>Bar Chart</h5>
            <Bar data={bardata} options={baroptions} />
          </div>
        ) : (
          <Box style={{ height: "345px", paddingBottom: "25px" }}>
            <h5>Pie Chart</h5>
            <Pie data={piedata} options={pieoptions} />
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end" margin={2}>
        <Typography
          style={{ cursor: "pointer" }}
          onClick={() => setCollapse(!collapse)}
        >
          Statistical Overview{" "}
          {collapse ? (
            <ArrowUpward fontSize="small" style={{ marginBottom: "5px" }} />
          ) : (
            <ArrowDownward fontSize="small" style={{ marginBottom: "5px" }} />
          )}
        </Typography>
      </Box>
      {collapse && (
        <Box padding={2}>
          <AnalyticsTable
            count={countVotes(calcVotes())}
            response={response}
            labels={returnLabel()}
          />
        </Box>
      )}
    </React.Fragment>
  );
};

export const DropdownBody = ({ chartTab, qVal, response }) => {
  const [collapse, setCollapse] = useState(false);

  const returnLabel = () => {
    let concat = [];
    qVal.formFields.map((item) => concat.push(item.label));
    return concat;
  };

  const calcVotes = () => {
    let p = [];
    response.map((el) => {
      el.response.map((item) => {
        if (item.id === qVal.id) {
          p.push(item.response);
        }
      });
    });

    return p;
  };

  const countVotes = (arr) => {
    const counts = {};

    for (const num of arr) {
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }
    let countArr = [];
    Object.keys(counts).map((item) => {
      countArr.push(counts[item]);
    });
    return countArr;
  };

  const bardata = {
    labels: returnLabel(),
    datasets: [
      {
        label: "# of Votes",
        data: countVotes(calcVotes()),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const piedata = {
    labels: returnLabel(),
    datasets: [
      {
        label: "# of Votes",
        data: countVotes(calcVotes()),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <React.Fragment>
      <Box margin={2} padding={2} border="1px solid rgba(142, 142, 147, 0.4)">
        {chartTab === 1 ? (
          <div>
            <h5>Bar Chart</h5>
            <Bar data={bardata} options={baroptions} />
          </div>
        ) : (
          <Box style={{ height: "345px", paddingBottom: "25px" }}>
            <h5>Pie Chart</h5>
            <Pie data={piedata} options={pieoptions} />
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end" margin={2}>
        <Typography
          style={{ cursor: "pointer" }}
          onClick={() => setCollapse(!collapse)}
        >
          Statistical Overview{" "}
          {collapse ? (
            <ArrowUpward fontSize="small" style={{ marginBottom: "5px" }} />
          ) : (
            <ArrowDownward fontSize="small" style={{ marginBottom: "5px" }} />
          )}
        </Typography>
      </Box>
      {collapse && (
        <Box padding={2}>
          <AnalyticsTable
            count={countVotes(calcVotes())}
            response={response}
            labels={returnLabel()}
          />
        </Box>
      )}
    </React.Fragment>
  );
};

export const RatingScaleBody = ({ chartTab, qVal, response, matches }) => {
  const [collapse, setCollapse] = useState(false);
  const returnLabel = () => {
    let concat = [];
    concat = [...Array.from(Array(Number(qVal.formFields.number)).keys())];
    return concat;
  };

  const calcVotes = () => {
    let p = [];
    response.map((el) => {
      el.response.map((item) => {
        if (item.id === qVal.id) {
          p.push(item.response);
        }
      });
    });

    return p;
  };

  const countVotes = (arr) => {
    const counts = {};

    for (const num of arr) {
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }
    return counts;
  };

  const totalVotes = () => {
    let result = 0;
    calcVotes().forEach((item) => {
      if (item !== "") {
        result = result + 1;
      }
    });
    return result;
  };

  const averageRating = () => {
    let result = 0;
    calcVotes().forEach((item) => {
      if (item !== "") {
        result = result + Number(item);
      }
    });
    let average = result / totalVotes();
    return average.toFixed(1);
  };

  const getCount = (key) => {
    let res = countVotes(calcVotes())[key];
    if (res === undefined) {
      return 0;
    } else return res;
  };

  const setGraphData = () => {
    let p = [];
    returnLabel().map((item) => {
      p.push(getCount(item + 1));
    });
    return p;
  };

  const bardata = {
    labels: returnLabel().map((item) => item + 1),
    datasets: [
      {
        label: "# of Votes",
        data: setGraphData(),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const piedata = {
    labels: returnLabel().map((item) => item + 1),
    datasets: [
      {
        label: "# of Votes",
        data: setGraphData(),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <React.Fragment>
      <Box margin={2} padding={2} border="1px solid rgba(142, 142, 147, 0.4)">
        {chartTab === 1 ? (
          <div>
            <h5>Bar Chart</h5>
            <Bar data={bardata} options={baroptions} />
          </div>
        ) : (
          <Box style={{ height: "345px", paddingBottom: "25px" }}>
            <h5>Pie Chart</h5>
            <Pie data={piedata} options={pieoptions} />
          </Box>
        )}
      </Box>
      <Grid container>
        <Grid item sm={3} xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            style={{ margin: "5px 40px" }}
          >
            <h6 style={{ textAlign: "left", color: "rgba(72, 72, 74, 1)" }}>
              Ratings
            </h6>
            <Box
              display="flex"
              flexDirection={matches ? "row" : "column"}
              justifyContent={matches ? "space-between" : "center"}
              width="100%"
              marginBottom={matches ? "8px" : "0px"}
            >
              <Box>
                <span
                  style={{
                    textAlign: "left",
                    fontSize: "35px",
                    fontWeight: "500",
                    color: "rgba(72, 72, 74, 1)",
                  }}
                >
                  {averageRating()}
                </span>
              </Box>
              <Box marginTop={matches ? "12px" : "0"}>
                <Rating
                  size="medium"
                  name="rating"
                  defaultValue={averageRating()}
                  precision={0.5}
                  emptyIcon={
                    <StarBorderIcon color="primary" fontSize="medium" />
                  }
                  icon={<StarIcon color="primary" fontSize="medium" />}
                  max={5}
                  onChange={(e) => e.preventDefault()}
                  onClick={(e) => e.preventDefault()}
                />
              </Box>
              <span
                style={{
                  fontSize: "15px ",
                  color: "rgba(72, 72, 74, 1)",
                  marginTop: matches ? "12px" : "0",
                }}
              >
                {response.length} people
              </span>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={9} xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {returnLabel().map((item) => {
              return (
                <Box display="flex" flexDirection="row" marginY={0.3}>
                  <Typography color="primary">{item + 1}</Typography>
                  <BorderLinearProgress
                    variant="determinate"
                    style={{ width: "350px" }}
                    value={(getCount(item + 1) / totalVotes()) * 100}
                  />
                </Box>
              );
            })}{" "}
          </Box>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end" margin={2}>
        <Typography
          style={{ cursor: "pointer" }}
          onClick={() => setCollapse(!collapse)}
        >
          Statistical Overview{" "}
          {collapse ? (
            <ArrowUpward fontSize="small" style={{ marginBottom: "5px" }} />
          ) : (
            <ArrowDownward fontSize="small" style={{ marginBottom: "5px" }} />
          )}
        </Typography>
      </Box>
      {collapse && (
        <Box padding={2}>
          <AnalyticsTable
            count={setGraphData()}
            response={response}
            noIndex
            labels={returnLabel().map((item) => {
              return item + 1;
            })}
          />
        </Box>
      )}
    </React.Fragment>
  );
};

export const MCheckBoxBody = ({ chartTab, qVal, response }) => {
  const [tab, setTab] = useState(0);
  const [collapse, setCollapse] = useState(false);

  const returnLabel = () => {
    let concat = [];
    qVal.formFields.list2.map((item) => concat.push(item.label));
    return concat;
  };

  const calcVotes = () => {
    let p = [];
    response.map((el) => {
      el.response.map((item) => {
        if (item.id === qVal.id) {
          item.response.forEach((e) => {
            if (e.row === qVal.formFields.list1[tab].label) {
              p.push(e.column);
            }
          });
        }
      });
    });
    return p;
  };

  const countVotes = (arr) => {
    const counts = {};

    for (const num of arr) {
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }
    let countArr = [];
    Object.keys(counts).map((item) => {
      countArr.push(counts[item]);
    });
    return countArr;
  };

  const bardata = {
    labels: returnLabel(),
    datasets: [
      {
        label: "# of Votes",
        data: countVotes(calcVotes()),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const piedata = {
    labels: returnLabel(),
    datasets: [
      {
        label: "# of Votes",
        data: countVotes(calcVotes()),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Box>
      {qVal.formFields.list1.map((row, index) => {
        if (index === tab) {
          return (
            <Box>
              {" "}
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Box style={{ marginLeft: "15px" }}>
                    <span style={{ fontWeight: 350 }}>
                      {tab + 1}/{qVal.formFields.list1.length} total rows
                    </span>
                  </Box>
                </Grid>
                <Grid item>
                  <Box flexDirection="row" style={{ marginRight: "15px" }}>
                    <ArrowBack
                      color="primary"
                      onClick={() =>
                        tab + 1 !== 1
                          ? setTab((prevState) => prevState - 1)
                          : null
                      }
                      style={{
                        transform: " scale(0.8)",
                        cursor: "pointer",
                        marginRight: "12px",
                      }}
                    />
                    <ArrowForward
                      color="primary"
                      onClick={() =>
                        qVal.formFields.list1.length !== tab + 1
                          ? setTab((prevState) => prevState + 1)
                          : null
                      }
                      style={{ transform: " scale(0.8)", cursor: "pointer" }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box
                margin={2}
                padding={2}
                border="1px solid rgba(142, 142, 147, 0.4)"
              >
                {chartTab === 1 ? (
                  <Box>
                    <h5>Bar Chart</h5>
                    <Bar data={bardata} options={baroptions} />
                    <Typography
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        fontDecoration: "italic",
                        fontWeight: "400",
                      }}
                    >
                      {qVal.formFields.list1[tab].label}
                    </Typography>
                  </Box>
                ) : (
                  <Box style={{ height: "345px", paddingBottom: "60px" }}>
                    <h5>Pie Chart</h5>
                    <Pie data={piedata} options={pieoptions} />
                    <Typography
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        fontDecoration: "italic",
                        fontWeight: "400",
                      }}
                    >
                      {qVal.formFields.list1[tab].label}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          );
        }
      })}
      <Box display="flex" justifyContent="flex-end" margin={2}>
        <Typography
          style={{ cursor: "pointer" }}
          onClick={() => setCollapse(!collapse)}
        >
          Statistical Overview{" "}
          {collapse ? (
            <ArrowUpward fontSize="small" style={{ marginBottom: "5px" }} />
          ) : (
            <ArrowDownward fontSize="small" style={{ marginBottom: "5px" }} />
          )}
        </Typography>
      </Box>
      ;
      {collapse && (
        <Box padding={2}>
          <AnalyticsTable
            count={countVotes(calcVotes())}
            response={response}
            labels={returnLabel()}
            noIndex
          />
        </Box>
      )}
    </Box>
  );
};

export const MMultipleBody = ({ chartTab, qVal, response }) => {
  const [tab, setTab] = useState(0);
  const [collapse, setCollapse] = useState(false);

  const returnLabel = () => {
    let concat = [];
    qVal.formFields.list2.map((item) => concat.push(item.label));
    return concat;
  };

  const calcVotes = () => {
    let p = [];
    response.map((el) => {
      el.response.map((item) => {
        if (item.id === qVal.id) {
          item.response.forEach((e) => {
            if (e.row === qVal.formFields.list1[tab].label) {
              p.push(e.column);
            }
          });
        }
      });
    });
    return p;
  };

  const countVotes = (arr) => {
    const counts = {};

    for (const num of arr) {
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }
    let countArr = [];
    Object.keys(counts).map((item) => {
      countArr.push(counts[item]);
    });
    return countArr;
  };

  const bardata = {
    labels: returnLabel(),
    datasets: [
      {
        label: "# of Votes",
        data: countVotes(calcVotes()),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const piedata = {
    labels: returnLabel(),
    datasets: [
      {
        label: "# of Votes",
        data: countVotes(calcVotes()),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Box>
      {qVal.formFields.list1.map((row, index) => {
        if (index === tab) {
          return (
            <Box>
              {" "}
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Box style={{ marginLeft: "15px" }}>
                    <span style={{ fontWeight: 350 }}>
                      {tab + 1}/{qVal.formFields.list1.length} total rows
                    </span>
                  </Box>
                </Grid>
                <Grid item>
                  <Box flexDirection="row" style={{ marginRight: "15px" }}>
                    <ArrowBack
                      color="primary"
                      onClick={() =>
                        tab + 1 !== 1
                          ? setTab((prevState) => prevState - 1)
                          : null
                      }
                      style={{
                        transform: " scale(0.8)",
                        cursor: "pointer",
                        marginRight: "12px",
                      }}
                    />
                    <ArrowForward
                      color="primary"
                      onClick={() =>
                        qVal.formFields.list1.length !== tab + 1
                          ? setTab((prevState) => prevState + 1)
                          : null
                      }
                      style={{ transform: " scale(0.8)", cursor: "pointer" }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box
                margin={2}
                padding={2}
                border="1px solid rgba(142, 142, 147, 0.4)"
              >
                {chartTab === 1 ? (
                  <Box>
                    <h5>Bar Chart</h5>
                    <Bar data={bardata} options={baroptions} />
                    <Typography
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        fontDecoration: "italic",
                        fontWeight: "400",
                      }}
                    >
                      {qVal.formFields.list1[tab].label}
                    </Typography>
                  </Box>
                ) : (
                  <Box style={{ height: "345px", paddingBottom: "60px" }}>
                    <h5>Pie Chart</h5>
                    <Pie data={piedata} options={pieoptions} />
                    <Typography
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        fontDecoration: "italic",
                        fontWeight: "400",
                      }}
                    >
                      {qVal.formFields.list1[tab].label}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          );
        }
      })}
      <Box display="flex" justifyContent="flex-end" margin={2}>
        <Typography
          style={{ cursor: "pointer" }}
          onClick={() => setCollapse(!collapse)}
        >
          Statistical Overview{" "}
          {collapse ? (
            <ArrowUpward fontSize="small" style={{ marginBottom: "5px" }} />
          ) : (
            <ArrowDownward fontSize="small" style={{ marginBottom: "5px" }} />
          )}
        </Typography>
      </Box>
      ;
      {collapse && (
        <Box padding={2}>
          <AnalyticsTable
            count={countVotes(calcVotes())}
            response={response}
            labels={returnLabel()}
            noIndex
          />
        </Box>
      )}
    </Box>
  );
};

export const TextBody = ({ qVal }) => {
  const wordCloud = useSelector((state) => state.response.wordCloud);
  const senti = useSelector((state) => state.response.senti);

  const countVotes = (arr) => {
    const counts = {};

    for (const num of arr) {
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }
    let countArr = [];
    Object.keys(counts).map((item) => {
      countArr.push({ key: item, count: counts[item] });
    });
    return countArr;
  };

  const getCountForKey = (key) => {
    let res;
    let found = countVotes(senti[qVal.id]).find((item) => item.key === key);
    if (found) {
      return found.count;
    } else return 0;
  };

  const totalVotes = () => {
    let sum = 0;
    countVotes(senti[qVal.id]).forEach((item) => {
      sum = sum + item.count;
    });
    return sum;
  };

  const averageSatisfaction = () => {
    let vhappy = (getCountForKey("1") / totalVotes()) * 100;
    let vneutral = (getCountForKey("0") / totalVotes()) * 100;
    let vangry = (getCountForKey("-1") / totalVotes()) * 100;
    let average;
    if (vhappy !== 0 && vneutral !== 0 && vangry !== 0) {
      average = (vhappy + vneutral + vangry) / 3;
    } else if (vhappy !== 0 && vneutral !== 0) {
      average = (vhappy + vneutral) / 2;
    } else if (vangry !== 0 && vneutral !== 0) {
      average = (vangry + vneutral) / 2;
    } else if (vhappy !== 0 && vangry !== 0) {
      average = (vhappy + vangry) / 2;
    } else if (vhappy !== 0) {
      average = vhappy;
    } else if (vneutral !== 0) {
      average = vneutral;
    } else if (vangry !== 0) {
      average = vangry;
    }
    return average;
  };

  return (
    <Box>
      {senti && (
        <Box>
          {wordCloud !== null && wordCloud[qVal.id] !== undefined ? (
            <img
              style={{ width: "100%", height: "300px", padding: "5px 10px" }}
              src={`data:png;base64,${wordCloud[qVal.id]}`}
              alt="wordcloud"
            />
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ width: "100%", height: "300px", padding: "5px 10px" }}
            >
              <Box sx={{ width: "100%", marginX: 5 }}>
                <LinearProgress color="primary" />
              </Box>
            </Box>
          )}
          <Box marginLeft="20px">
            <h5>Sentiment Analysis</h5>
            <Typography style={{ fontWeight: 500 }}>
              Overall Satisfactory level: {averageSatisfaction().toFixed(0)}%
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginTop="5px"
            marginX="25px"
            marginBottom={5}
          >
            <Doughnut
              trailColor="rgba(52, 199, 89, 0.2)"
              pathColor="rgba(52, 199, 89, 1)"
              emotion="happy"
              data={(getCountForKey("1") / totalVotes()).toFixed(2) * 100}
            />

            <Doughnut
              pathColor={"rgba(90, 200, 250, 1)"}
              trailColor={"rgba(90, 200, 250, 0.2)"}
              emotion="normal"
              data={(getCountForKey("0") / totalVotes()).toFixed(2) * 100}
            />

            <Doughnut
              pathColor={"rgba(255, 45, 85, 1)"}
              trailColor={"rgba(255, 45, 85, 0.2)"}
              emotion="angry"
              data={(getCountForKey("-1") / totalVotes()).toFixed(2) * 100}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const RangeSliderBody = ({ response, qVal }) => {
  const setArrayVal = () => {
    let p = [];
    response.forEach((res) => {
      res.response.forEach((el) => {
        if (el.id === qVal.id) {
          p.push(el.response.max);
          p.push(el.response.min);
        }
      });
    });
    return p;
  };

  const asc = (arr) => arr.sort((a, b) => a - b);

  const sum = (arr) => arr.reduce((a, b) => a + b, 0);

  const quantile = (arr, q) => {
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
      return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
      return sorted[base];
    }
  };

  const q25 = () => quantile(setArrayVal(), 0.25);

  const q50 = () => quantile(setArrayVal(), 0.5);

  const q75 = () => quantile(setArrayVal(), 0.75);

  const median = () => q50();

  var series = [
    {
      type: "boxPlot",
      data: [
        {
          x: "",
          y: [
            asc(setArrayVal())[0],
            q25(),
            median(),
            q75(),
            asc(setArrayVal())[asc(setArrayVal()).length - 1],
          ],
        },
      ],
    },
  ];

  return (
    <Chart
      options={boxplotOptions}
      series={series}
      type="boxPlot"
      width="500"
    />
  );
};
