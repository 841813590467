import React, { useState } from "react";
import { Grid, TextareaAutosize } from "@material-ui/core";
import { connect } from "react-redux";
import {
  addFormFields,
  onTitleChange,
  onDescChange,
  onCoverChange,
  addAttachment,
} from "../../redux/survey/SurveyActions";

import Cancel from "../../assets/images/icons/cancel.png";
import Reupload from "../../assets/images/icons/camera.png";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import imageToBase64 from "image-to-base64/browser";
import { toast } from "react-toastify";

const SectionForm = ({ page, onDescChange, onTitleChange, onCoverChange }) => {
  const [previewImg, setPreviewImg] = useState(page.coverImg);

  const fileClick = () => {
    document.getElementById(`selectedFile ${page.page}`).click();
  };

  const imgUpload = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        imageToBase64(reader.result) // Path to the image
          .then((response) => {
            setPreviewImg({ type: e.target.files[0].type, base64: response });
            onCoverChange({ type: e.target.files[0].type, base64: response }, page.page); // "cGF0aC90by9maWxlLmpwZw=="
          })
          .catch((error) => {
            toast.error("Upload failed, please try again"); // Logs an error if there was one
          });

        //addAttachment(attachmentObject);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleTitleChange = (e) => {
    onTitleChange(e, page.page);
  };
  const handleDescChange = (e) => {
    onDescChange(e, page.page);
  };

  return (
    <div className="form-cards">
      {previewImg ? (
        <div className="sv-block-image-container">
          <img
            className="preview-image"
            //src={previewImg}
            src={`data:${previewImg.type};base64,${previewImg.base64}`}
            alt="preview"
          />
          <div className="preview-image-actions">
            <div className="preview-reupload content-center" onClick={() => fileClick()}>
              <img src={Reupload} alt="preview-action" />
            </div>
            <div
              className="preview-cancel content-center"
              onClick={() => {
                setPreviewImg(null);
                onCoverChange(null, page.page);
              }}
            >
              <img src={Cancel} alt="preview-action" />
            </div>
          </div>
        </div>
      ) : (
        <Grid container direction="row" className="sv-block-image-selector">
          <Grid item lg={5} onClick={() => fileClick()}>
            <div className="image-selector">
              <CameraAltIcon />
            </div>
          </Grid>
          <Grid item lg={7}>
            <div className="image-selector-text">
              <p>Upload a image</p>
            </div>
          </Grid>
        </Grid>
      )}
      <div className="header-form-group">
        <TextareaAutosize
          className="survey-title"
          placeholder="Untitled Survey"
          defaultValue={page.title}
          style={{ minHeight: "50px" }}
          onChange={(e) => handleTitleChange(e)}
        />
        <div className="header-form">
          <TextareaAutosize
            className="survey-desc"
            placeholder="Add a description"
            style={{ minHeight: "35px" }}
            defaultValue={page.desc}
            onChange={(e) => handleDescChange(e)}
          />
        </div>
      </div>
      <input
        type="file"
        id={`selectedFile ${page.page}`}
        name="file-up"
        accept=".jpg,.jpeg,.png"
        style={{ display: "none" }}
        onChange={(e) => imgUpload(e)}
        onClick={(event) => {
          event.target.value = null;
        }}
      ></input>
    </div>
  );
};

const mapStateToProps = (state) => ({
  survey: state.survey,
});
export default connect(mapStateToProps, {
  addFormFields,
  onDescChange,
  onTitleChange,
  addAttachment,
  onCoverChange,
})(SectionForm);
