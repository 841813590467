import React, { useState } from "react";
import FormPreviewQs from "./FormPreviewQs";
import FormPreviewFlexQs from "./FormPreviewFlexQs";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Box, Grid, TextField, Slider, Select, MenuItem, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import NumberScaleRating from "../number-scale/NumberScaleRating";
import { addResponse } from "../../redux/response/ResponseAction";
import { useDispatch, useSelector } from "react-redux";

const DropdownPreview = ({ data, uId, required }) => {
  const dispatch = useDispatch();
  const [val, setVal] = useState("");

  const onDropdownChange = (e) => {
    setVal(e.target.value);
    dispatch(addResponse(uId, e.target.value, "Dropdown", required));
  };

  return (
    <Box margin="15px">
      <Select
        variant="outlined"
        value={val}
        //defaultValue="Select Value"
        color="primary"
        style={{ width: "50%", height: "20%" }}
        onChange={(e) => onDropdownChange(e)}
      >
        {data &&
          data.map((item, index) => {
            return (
              <MenuItem key={index} value={item.label ? item.label : "other"}>
                <div className="fc-menu-item">
                  <span className="menu-item-text">{item.label ? item.label : "other"}</span>
                </div>
              </MenuItem>
            );
          })}
      </Select>
      {val === "other" && (
        <Box margin="15px 0px">
          <TextField type="text" variant="standard" placeholder="Your Answer" fullWidth></TextField>
        </Box>
      )}
    </Box>
  );
};

const RatingScale = ({ data, uId, required }) => {
  const dispatch = useDispatch();
  const handleMouse = () => {};

  const handleClicked = (val) => {
    dispatch(addResponse(uId, val, "rating", required));
  };
  return (
    <Box margin="15px 25px">
      {data !== null ? (
        data.type === "star" ? (
          <Rating
            size="large"
            name="rating"
            defaultValue={2}
            emptyIcon={<StarBorderIcon fontSize="large" />}
            icon={<StarIcon fontSize="large" />}
            max={Number(data.number)}
            onChange={(e) => handleClicked(e.target.value)}
          />
        ) : (
          <NumberScaleRating
            start={1}
            end={Number(data.number)}
            current={1}
            mouseEnteredOn={handleMouse}
            clickedOn={handleClicked}
          />
        )
      ) : (
        <Typography>No rating specified</Typography>
      )}
    </Box>
  );
};

const FormPreviewCard = ({ type, uId, data, required }) => {
  const dispatch = useDispatch();

  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  const onDateChange = (val) => {
    setDate(val);
    let responseObject = {
      date: date,
      time: time,
    };
    dispatch(addResponse(uId, responseObject, "Date & Time", required));
  };

  const onTimeChange = (val) => {
    setTime(val);
    let responseObject = {
      date: date,
      time: time,
    };
    dispatch(addResponse(uId, responseObject, "Date & Time", required));
  };

  const onTextChange = (e) => {
    dispatch(addResponse(uId, e.target.value, "Text", required));
  };

  const onSliderChange = (e, val) => {
    let valueObject = {
      min: val[0],
      max: val[1],
    };
    dispatch(addResponse(uId, valueObject, "Slider", required));
  };

  const response = useSelector((state) => state.response.response);

  if (type === "Multiple Choice") {
    return (
      <Box margin="5px">
        <FormPreviewQs qtype="radio" uId={uId} data={data} required={required} />
      </Box>
    );
  } else if (type === "Checkbox") {
    return (
      <Box margin="5px">
        <FormPreviewQs qtype="checkbox" uId={uId} data={data} required={required} />
      </Box>
    );
  } else if (type === "Dropdown") {
    return <DropdownPreview data={data} uId={uId} required={required} />;
  } else if (type === "Range Slider") {
    return (
      <Box margin="5px 20px">
        <Slider
          defaultValue={[Number(data.min), Number(data.max)]}
          color="primary"
          onChange={onSliderChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
        />
      </Box>
    );
  } else if (type === "Rating Scale") {
    return <RatingScale data={data} uId={uId} required={required} />;
  } else if (type === "Text") {
    let defaultResponse = response.find((item) => item.id === uId);
    return (
      <Box margin="5px">
        <TextField
          placeholder="Your answer"
          variant="standard"
          defaultValue={defaultResponse ? defaultResponse.response : ""}
          onChange={(e) => onTextChange(e)}
          fullWidth
          style={{ marginLeft: "8px" }}
        ></TextField>
      </Box>
    );
  } else if (type === "Date & Time") {
    return (
      <Box margin="5px">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justifyContent="space-around">
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Date picker"
              format="MM/dd/yyyy"
              value={date}
              onChange={onDateChange}
            />
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Time picker"
              value={time}
              onChange={onTimeChange}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Box>
    );
  } else if (type === "M-Multiple choice") {
    return (
      <Box margin="5px">
        <FormPreviewFlexQs qtype="radio" uId={uId} data={data} required={required} />
      </Box>
    );
  } else if (type === "M-Checkbox") {
    return (
      <Box margin="5px">
        <FormPreviewFlexQs qtype="checkbox" uId={uId} data={data} required={required} />
      </Box>
    );
  } else {
    return <div>Unsupported</div>;
  }
};

export default FormPreviewCard;
