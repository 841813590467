import React, { useState } from "react";
import { connect } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FormCard from "../../components/form-card/FormCard";
import {
  onCoverChange,
  addQs,
  postSurvey,
  publishSurvey,
  addPage,
  postAttachment,
  setCurrentPage,
  postEditedSurvey,
  onMainTitleChange,
  postTemplate,
  deletePage,
} from "../../redux/survey/SurveyActions";
import { setTab } from "../../redux/response/ResponseAction";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import SurveyPreview from "../survey-preview/SurveyPreview";
import { Box, Grid, Button, TextField, Typography } from "@material-ui/core";
import SectionForm from "../../components/partials/SectionForms";
import AddQuestionButton from "../../components/partials/AddQuestionButton";
import SurveyNav from "../../layout/navbar/survey/SurveyNav";

import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Cancel from "@material-ui/icons/Cancel";

const Survey = ({
  addQs,
  addPage,
  setCurrentPage,
  survey,
  postSurvey,
  publishSurvey,
  onMainTitleChange,
  postTemplate,
  deletePage,
}) => {
  const [copy, setCopy] = useState(false);

  const addQuestion = (text, id, initial, page) => {
    let defaultData = survey.forms;
    let pageNumber = defaultData.find((item) => item.page === page);
    let addQ = {
      id: uuidv4(),
      selectedDropdown: text,
      formFields: null,
    };
    if (initial) {
      pageNumber.forms.splice(0, 0, addQ);
    } else {
      const index = pageNumber.forms.findIndex((item) => item.id === id);
      pageNumber.forms.splice(index + 1, 0, addQ);
    }
    addQs(defaultData);
  };

  const handleOnDragEnd = (result, page) => {
    if (!result.destination) return;
    else {
      let defaultData = survey.forms;
      let pageNumber = defaultData.find((item) => item.page === page);
      const items = Array.from(pageNumber.forms);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      pageNumber.forms = items;
      addQs(defaultData);
    }
  };

  const onDeleteCard = (id, page) => {
    let defaultData = survey.forms;
    let pageNumber = defaultData.find((item) => item.page === page);
    let filteredData = pageNumber.forms.filter((item) => item.id !== id);
    pageNumber.forms = filteredData;
    addQs(defaultData);
  };

  const onCopyForm = (id, page) => {
    let defaultData = survey.forms;
    let pageNumber = defaultData.find((item) => item.page === page);
    let set = pageNumber.forms.find((item) => item.id === id);
    if (
      set.selectedDropdown === "Checkbox" ||
      set.selectedDropdown === "Multiple Choice" ||
      set.selectedDropdown === "Dropdown"
    ) {
      let deafaultFormFields = set.formFields
        ? set.formFields.map((item) => {
            item.id = uuidv4();
            return item;
          })
        : null;

      let addQ = {
        id: uuidv4(),
        selectedDropdown: set.selectedDropdown,
        question: set.question ? set.question : null,
        formFields: deafaultFormFields,
      };
      pageNumber.forms = [...pageNumber.forms, addQ];
      addQs(defaultData);
    } else if (
      set.selectedDropdown === "M-Checkbox" ||
      set.selectedDropdown === "M-Multiple choice"
    ) {
      let list1 = set.formFields.list1
        ? set.formFields.list1.map((item) => {
            item.id = uuidv4();
            return item;
          })
        : null;

      let list2 = set.formFields.list2
        ? set.formFields.list2.map((item) => {
            item.id = uuidv4();
            return item;
          })
        : null;

      let addQ = {
        id: uuidv4(),
        selectedDropdown: set.selectedDropdown,
        question: set.question ? set.question : null,
        formFields: { list1, list2 },
      };
      pageNumber.forms = [...pageNumber.forms, addQ];
      addQs(defaultData);
    } else {
      let addQ = {
        id: uuidv4(),
        selectedDropdown: set.selectedDropdown,
        question: set.question ? set.question : null,
        formFields: set.formFields,
      };
      pageNumber.forms = [...pageNumber.forms, addQ];
      addQs(defaultData);
    }
  };

  return (
    <React.Fragment>
      <Box display={{ sm: "block", md: "none" }}>
        <SurveyNav />
      </Box>

      <Grid container>
        <Grid
          item
          component={Box}
          display={{ xs: "none", md: "none", lg: "block" }}
          lg={2}
          //className="aside-container"
        >
          <div className="aside-container">
            <Box padding={2}>
              <TextField
                variant="outlined"
                defaultValue={survey.mainTitle}
                color="primary"
                label="Form title"
                onChange={(e) => onMainTitleChange(e)}
              ></TextField>
            </Box>

            <React.Fragment>
              <div className="aside-block">
                <h5 className="aside-header-block">Questionnaire</h5>
                <div className="aside-grid">
                  {survey.forms &&
                    survey.forms.map((page, index) => (
                      <div
                        className={`aside-block-items page ${
                          survey.currentPage === page.page ? "at" : ""
                        }`}
                        key={index}
                        onClick={() => setCurrentPage(page.page)}
                      >
                        <Box display="flex" justifyContent="space-between">
                          <div className="aside-block-item-icon">
                            <InsertDriveFileOutlinedIcon />
                          </div>
                          <Typography style={{ marginTop: "5px" }}>
                            Page {index + 1}
                          </Typography>
                        </Box>
                        <div
                          className="page-cancel"
                          onClick={() => deletePage(page.page)}
                        >
                          <Cancel fontSize="2" />
                        </div>
                      </div>
                    ))}

                  <div
                    className="aside-block-items"
                    style={{ marginTop: "15px" }}
                    onClick={() => addPage()}
                  >
                    <div className="aside-block-item-icon">
                      <NoteAddOutlinedIcon />
                    </div>
                    <p>Create New Page</p>
                  </div>
                </div>
              </div>
              <div className="aside-block">
                <h5 className="aside-header-block">Express</h5>
                <div className="aside-grid">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "4px" }}
                    size="small"
                    disabled={survey.slug ? true : false}
                    onClick={() => {
                      if (window.location.pathname === "/create-form") {
                        postSurvey();
                        publishSurvey();
                      } else {
                        postTemplate(window.location.pathname);
                        publishSurvey();
                      }
                    }}
                  >
                    <span className="btn-text">
                      <CheckCircleOutlineOutlinedIcon /> Publish
                    </span>
                  </Button>
                </div>
              </div>
            </React.Fragment>

            <div className="aside-block">
              <div className="aside-grid">
                <Link to="/" className="links">
                  <div
                    className="aside-block-items"
                    style={{ padding: "10px" }}
                  >
                    <div className="aside-block-item-icon">
                      <ArrowBackOutlinedIcon />
                    </div>
                    <h5 style={{ margin: "5px 10px" }}>Exit Page</h5>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </Grid>

        <Grid
          item
          container
          display="column"
          alignItems="center"
          lg={10}
          md={12}
          className="sv-block"
        >
          {survey.slug && (
            <React.Fragment>
              <Box className="publish-response" marginTop="20px">
                <Grid container direction="row">
                  <Grid
                    item
                    container
                    xs={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CheckCircleIcon
                      color="primary"
                      style={{
                        transform: "scale(3)",
                        marginLeft: "30px",
                        marginTop: "27px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <div className="publish-response-text">
                      <h3>Success!</h3>
                      <p>Your survey is successfully published.</p>
                    </div>
                  </Grid>
                  <Grid item xs={5}></Grid>
                </Grid>
              </Box>
              <Box className="publish-link" textAlign="left" width="700px">
                <h4>Share your RevSurvey</h4>
                <Box className="publish-link-box">
                  <Grid container>
                    <Grid item container direction="row" justifyContent="flex-start">
                      <AttachFileIcon />
                      <h6>Get the Link</h6>
                    </Grid>
                    <Grid item container direction="row">
                      <CopyToClipboard
                        text={`${window.location.hostname}/${
                          window.location.pathname === "/create-form"
                            ? "form"
                            : "template"
                        }/${survey.slug}`}
                        onCopy={() => setCopy(true)}
                      >
                        <Box
                          className={`${
                            copy ? "copied-text" : "copy-text-btn"
                          }`}
                        >
                          {copy ? "Copied" : "Copy Text"}
                        </Box>
                      </CopyToClipboard>

                      <Box className="rev-link">
                        {window.location.origin}/
                        {window.location.pathname === "/create-form"
                          ? "form"
                          : "template"}
                        /{survey.slug}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </React.Fragment>
          )}

          {survey.tab === 1 ? (
            <React.Fragment>
              {survey.forms &&
                survey.forms.map((pages) => {
                  return (
                    <div className="fc-block-container" key={pages.page}>
                      <SectionForm page={pages} />
                      <AddQuestionButton
                        addQuestion={addQuestion}
                        page={pages.page}
                        initial={true}
                      />
                      <DragDropContext
                        onDragEnd={(result) =>
                          handleOnDragEnd(result, pages.page)
                        }
                      >
                        <Droppable droppableId="form-cards">
                          {(provided) => (
                            <div
                              className="form-cards"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {pages.forms.map((form, index) => {
                                return (
                                  <Draggable
                                    key={form.id}
                                    draggableId={form.id}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                      >
                                        <FormCard
                                          sr={index}
                                          key={form.id}
                                          uId={form.id}
                                          page={pages.page}
                                          form={form}
                                          onDeleteCard={onDeleteCard}
                                          onCopyForm={onCopyForm}
                                          handleDrag={provided.dragHandleProps}
                                          addQuestion={addQuestion}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  );
                })}
            </React.Fragment>
          ) : survey.tab === 2 ? (
            <SurveyPreview previewType="live" />
          ) : (
            <Box
              className="response-card-container"
              justifyContent="center"
              alignItems="center"
            >
              <Box padding={4} borderLeft="5px solid rgba(0, 133, 164, 0.9)">
                <h5 style={{ textDecoration: "italic" }}>
                  Please publish in order to get response
                </h5>
              </Box>
            </Box>
          )}

          <input
            type="file"
            id="selectedFile"
            name="file-up"
            style={{ display: "none" }}
            //onChange={(e) => imgUpload(e)}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
        </Grid>
      </Grid>

      <Box display={{ md: "block", lg: "none" }}>
        {survey.tab !== 3 && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            className="survey-aside-sm"
          >
            <Box display="flex" flexDirection="row">
              {survey.forms &&
                survey.forms.map((page, index) => (
                  <div
                    className={`aside-block-items ${
                      survey.currentPage === page.page ? "at" : ""
                    }`}
                    key={index}
                    onClick={() => setCurrentPage(page.page)}
                  >
                    <InsertDriveFileOutlinedIcon
                      color="primary"
                      style={{ fontSize: "32px", margin: "0px 5px" }}
                    />
                  </div>
                ))}

              <NoteAddOutlinedIcon
                style={{ fontSize: "35px", margin: " 5px", cursor: "pointer" }}
                onClick={() => addPage()}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "4px", textAlign: "right" }}
                size="small"
                onClick={() => {
                  if (window.location.pathname === "/create-form") {
                    postSurvey();
                    publishSurvey();
                  } else {
                    postTemplate(window.location.pathname);
                    publishSurvey();
                  }
                }}
              >
                <span className="btn-text">
                  <CheckCircleOutlineOutlinedIcon /> Publish
                </span>
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  survey: state.survey,
  response: state.response,
});
export default connect(mapStateToProps, {
  addQs,
  postSurvey,
  onCoverChange,
  addPage,
  setCurrentPage,
  postAttachment,
  publishSurvey,
  postEditedSurvey,
  onMainTitleChange,
  setTab,
  postTemplate,
  deletePage,
})(Survey);
