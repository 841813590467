import {
  ADD_RESPONSE,
  LOADING,
  SET_SLUG,
  SUBMIT_RESPONSE,
  SUBMIT_RESPONSE_FAILURE,
  GET_RESPONSE,
  GET_ALL_RESPONSE,
  SET_IP,
  SET_TAB,
  GET_WORD_CLOUD,
  GET_SENTI,
  RESET_RESPONSE,
} from "./ResponseType";

const INITIAL_STATE = {
  slug: "",
  ip: "",
  tab: 1,
  loading: false,
  submitted: false,
  senti: null,
  wordCloud: null,
  uResponse: [],
  response: [],
  allResponse: [],
};

export default function ResponseReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SLUG:
      return {
        ...state,
        slug: action.payload,
      };
    case GET_SENTI:
      return {
        ...state,
        senti: action.payload,
        loading: false,
      };
    case GET_WORD_CLOUD:
      return {
        ...state,
        wordCloud: action.payload,
        loading: false,
      };
    case GET_ALL_RESPONSE:
      return {
        ...state,
        allResponse: action.payload,
        loading: false,
      };
    case GET_RESPONSE:
      return {
        ...state,
        uResponse: action.payload,
        loading: false,
      };
    case ADD_RESPONSE:
      var defaultResponse = state.response;
      var returnObject = {
        id: action.payload.id,
        response: action.payload.response,
        type: action.payload.type,
        required: action.payload.req,
      };
      let item = defaultResponse.find((item) => item.id === action.payload.id);
      if (item !== undefined) {
        let index = defaultResponse.findIndex(
          (item) => item.id === action.payload.id
        );
        defaultResponse[index] = returnObject;
      } else {
        defaultResponse = [...state.response, returnObject];
      }
      return {
        ...state,
        response: defaultResponse,
      };
    case SUBMIT_RESPONSE:
      return {
        ...state,
        submitted: true,
        loading: false,
      };
    case SUBMIT_RESPONSE_FAILURE:
      return {
        ...state,
        submitted: false,
        loading: false,
      };
    case SET_IP:
      return {
        ...state,
        ip: action.payload,
      };
    case RESET_RESPONSE:
      return {
        ...state,
        uResponse: [],
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_TAB:
      return {
        ...state,
        tab: action.payload,
      };
    default:
      return state;
  }
}
