import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FormCard from "../../components/form-card/FormCard";
import {
  onCoverChange,
  addQs,
  postSurvey,
  publishSurvey,
  addPage,
  postAttachment,
  setCurrentPage,
  postEditedSurvey,
  onMainTitleChange,
  editSurvey,
  getTab,
  loadTemplateForSurvey,
  postEditedTemplate,
} from "../../redux/survey/SurveyActions";
import { Link, withRouter } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import SurveyPreview from "../survey-preview/SurveyPreview";
import { Box, Grid, Button, TextField, Typography } from "@material-ui/core";
import SectionForm from "../../components/partials/SectionForms";
import AddQuestionButton from "../../components/partials/AddQuestionButton";
import { setTab } from "../../redux/response/ResponseAction";
import { downloadCSV } from "../../utils/downloadCSV";

import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SurveyNav from "../../layout/navbar/survey/SurveyNav";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import StarsIcon from "@material-ui/icons/Stars";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import PanoramaHorizontalIcon from "@material-ui/icons/PanoramaHorizontal";
import { DownloadPdfLink } from "../../utils/downloadPDF";

const EditTemplate = ({
  addQs,
  setTab,
  getTab,
  postAttachment,
  addPage,
  setCurrentPage,
  response,
  survey,
  postSurvey,
  publishSurvey,
  postEditedSurvey,
  onMainTitleChange,
  editSurvey,
  loadTemplateForSurvey,
  postEditedTemplate,
  ...props
}) => {
  const [shareid] = useState(props.match.params.id);

  useEffect(() => {
    const path = window.location.pathname.split("/");
    const id = path[path.length - 1];
    loadTemplateForSurvey(id);
    getTab(1);
  }, [shareid]); // eslint-disable-line react-hooks/exhaustive-deps

  const addQuestion = (text, id, initial, page) => {
    let defaultData = survey.forms;
    let pageNumber = defaultData.find((item) => item.page === page);
    let addQ = {
      id: uuidv4(),
      selectedDropdown: text,
      formFields: null,
    };
    if (initial) {
      pageNumber.forms.splice(0, 0, addQ);
    } else {
      const index = pageNumber.forms.findIndex((item) => item.id === id);
      pageNumber.forms.splice(index + 1, 0, addQ);
    }
    addQs(defaultData);
  };

  const handleOnDragEnd = (result, page) => {
    if (!result.destination) return;
    else {
      let defaultData = survey.forms;
      let pageNumber = defaultData.find((item) => item.page === page);
      const items = Array.from(pageNumber.forms);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      pageNumber.forms = items;
      addQs(defaultData);
    }
  };

  const onDeleteCard = (id, page) => {
    let defaultData = survey.forms;
    let pageNumber = defaultData.find((item) => item.page === page);
    let filteredData = pageNumber.forms.filter((item) => item.id !== id);
    pageNumber.forms = filteredData;
    addQs(defaultData);
  };

  const onCopyForm = (id, page) => {
    let defaultData = survey.forms;
    let pageNumber = defaultData.find((item) => item.page === page);
    let set = pageNumber.forms.find((item) => item.id === id);
    if (
      set.selectedDropdown === "Checkbox" ||
      set.selectedDropdown === "Multiple Choice" ||
      set.selectedDropdown === "Dropdown"
    ) {
      let deafaultFormFields = set.formFields
        ? set.formFields.map((item) => {
            item.id = uuidv4();
            return item;
          })
        : null;

      let addQ = {
        id: uuidv4(),
        selectedDropdown: set.selectedDropdown,
        question: set.question ? set.question : null,
        formFields: deafaultFormFields,
      };
      pageNumber.forms = [...pageNumber.forms, addQ];
      addQs(defaultData);
    } else if (set.selectedDropdown === "M-Checkbox" || set.selectedDropdown === "M-Multiple choice") {
      let list1 = set.formFields.list1
        ? set.formFields.list1.map((item) => {
            item.id = uuidv4();
            return item;
          })
        : null;

      let list2 = set.formFields.list2
        ? set.formFields.list2.map((item) => {
            item.id = uuidv4();
            return item;
          })
        : null;

      let addQ = {
        id: uuidv4(),
        selectedDropdown: set.selectedDropdown,
        question: set.question ? set.question : null,
        formFields: { list1, list2 },
      };
      pageNumber.forms = [...pageNumber.forms, addQ];
      addQs(defaultData);
    } else {
      let addQ = {
        id: uuidv4(),
        selectedDropdown: set.selectedDropdown,
        question: set.question ? set.question : null,
        formFields: set.formFields,
      };
      pageNumber.forms = [...pageNumber.forms, addQ];
      addQs(defaultData);
    }
  };

  return (
    <React.Fragment>
      <Box display={{ xs: "block", md: "none" }}>
        <SurveyNav />
      </Box>

      {survey.editSlug && (
        <Grid container>
          <Grid item component={Box} display={{ xs: "none", lg: "block" }} lg={2}>
            <div className="aside-container">
              <Box padding={2}>
                {survey.tab === 3 ? (
                  <div className="workplace-sm-title">
                    <h5>Report</h5>
                  </div>
                ) : (
                  <TextField
                    variant="outlined"
                    defaultValue={survey.mainTitle}
                    color="primary"
                    label="Form title"
                    onChange={(e) => onMainTitleChange(e)}
                  ></TextField>
                )}
              </Box>
              {survey.tab === 3 ? (
                <React.Fragment>
                  <div className="aside-block">
                    <h5 className="aside-header-block">Total Response</h5>
                    <div className="aside-grid">
                      <div className="aside-block-items">
                        <div className="aside-block-item-icon">
                          <StarsIcon color="primary" fontSize="large" style={{ marginTop: "7px" }} />
                        </div>
                        <Typography color="secondary" style={{ fontSize: "24px" }}>
                          -
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div className="aside-block">
                    <h5 className="aside-header-block">Response by</h5>
                    <div className="aside-grid">
                      <div className={`aside-block-items ${response.tab === 1 ? "at" : ""}`} onClick={() => setTab(1)}>
                        <div className="aside-block-item-icon">
                          <CheckBoxOutlineBlankIcon />
                        </div>
                        <p>Overall</p>
                      </div>
                      <div className={`aside-block-items ${response.tab === 2 ? "at" : ""}`} onClick={() => setTab(2)}>
                        <div className="aside-block-item-icon">
                          <PermIdentityIcon />
                        </div>
                        <p>Individual</p>
                      </div>
                      <div className={`aside-block-items ${response.tab === 3 ? "at" : ""}`} onClick={() => setTab(3)}>
                        <div className="aside-block-item-icon">
                          <PanoramaHorizontalIcon />
                        </div>
                        <p>Question</p>
                      </div>
                    </div>
                  </div>
                  <div className="aside-block">
                    <h5 className="aside-header-block">Export</h5>
                    <div className="aside-grid">
                      <DownloadPdfLink data={response.uResponse} />
                      <div className="aside-block-items" onClick={() => downloadCSV(response.uResponse)}>
                        <div className="aside-block-item-icon">
                          <ExitToAppIcon />
                        </div>
                        <p>CSV</p>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="aside-block">
                    <h5 className="aside-header-block">Questionnaire</h5>
                    <div className="aside-grid">
                      {survey.forms &&
                        survey.forms.map((page, index) => (
                          <div
                            className={`aside-block-items ${survey.currentPage === page.page ? "at" : ""}`}
                            key={index}
                            onClick={() => setCurrentPage(page.page)}
                          >
                            <div className="aside-block-item-icon">
                              <InsertDriveFileOutlinedIcon />
                            </div>
                            <p>Page {page.page}</p>
                          </div>
                        ))}

                      <div className="aside-block-items" style={{ marginTop: "15px" }} onClick={() => addPage()}>
                        <div className="aside-block-item-icon">
                          <NoteAddOutlinedIcon />
                        </div>
                        <p>Create New Page</p>
                      </div>
                    </div>
                  </div>
                  <div className="aside-block">
                    <h5 className="aside-header-block">Express</h5>
                    <div className="aside-grid">
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: "4px" }}
                        size="small"
                        disabled={survey.slug ? true : false}
                        onClick={() => {
                          postEditedTemplate(window.location.pathname);
                          publishSurvey();
                        }}
                      >
                        <span className="btn-text">
                          <CheckCircleOutlineOutlinedIcon /> Publish
                        </span>
                      </Button>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="aside-block">
                <div className="aside-grid">
                  <Link to="/" className="links">
                    <div className="aside-block-items" style={{ padding: "10px" }}>
                      <div className="aside-block-item-icon">
                        <ArrowBackOutlinedIcon />
                      </div>
                      <h5 style={{ margin: "5px 10px" }}>Exit Page</h5>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item container display="column" alignItems="center" lg={10} md={12} className="sv-block">
            {survey.slug !== null && (
              <React.Fragment>
                <Box className="publish-response" marginTop="20px">
                  <Grid container direction="row">
                    <Grid item container xs={2} justifyContent="center" alignItems="center">
                      <CheckCircleIcon
                        color="primary"
                        style={{
                          transform: "scale(3)",
                          marginLeft: "30px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <div className="publish-response-text">
                        <h3>Success!</h3>
                        <p>Your template has been successfully updated.</p>
                      </div>
                    </Grid>
                    <Grid item xs={5}></Grid>
                  </Grid>
                </Box>
              </React.Fragment>
            )}

            {survey.tab === 1 ? (
              <React.Fragment>
                {survey.forms &&
                  survey.forms.map((pages) => {
                    return (
                      <div className="fc-block-container" key={pages.page}>
                        <SectionForm page={pages} />
                        <AddQuestionButton addQuestion={addQuestion} page={pages.page} initial={true} />
                        <DragDropContext onDragEnd={(result) => handleOnDragEnd(result, pages.page)}>
                          <Droppable droppableId="form-cards">
                            {(provided) => (
                              <div className="form-cards" {...provided.droppableProps} ref={provided.innerRef}>
                                {pages.forms.map((form, index) => {
                                  return (
                                    <Draggable key={form.id} draggableId={form.id} index={index}>
                                      {(provided) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps}>
                                          <FormCard
                                            sr={index}
                                            key={form.id}
                                            uId={form.id}
                                            page={pages.page}
                                            form={form}
                                            onDeleteCard={onDeleteCard}
                                            onCopyForm={onCopyForm}
                                            handleDrag={provided.dragHandleProps}
                                            addQuestion={addQuestion}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    );
                  })}
              </React.Fragment>
            ) : survey.tab === 2 ? (
              <SurveyPreview previewType="live" />
            ) : (
              <Box className="response-card-container" justifyContent="center" alignItems="center">
                <Box padding={4} borderLeft="5px solid rgba(0, 133, 164, 0.9)">
                  <h5 style={{ textDecoration: "italic" }}>Responses cannot be visible</h5>
                </Box>
              </Box>
            )}

            <input
              type="file"
              id="selectedFile"
              name="file-up"
              style={{ display: "none" }}
              //onChange={(e) => imgUpload(e)}
              onClick={(event) => {
                event.target.value = null;
              }}
            />
          </Grid>
        </Grid>
      )}

      {survey.tab === 3 ? (
        <Box display={{ xs: "block", md: "none" }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            className="survey-aside-sm"
          >
            <Box className="mini-survey-aside">
              <ExitToAppIcon fontSize="small" />
              <Typography style={{ fontSize: "10px" }}>Export</Typography>
            </Box>
            <Box className="mini-survey-aside" onClick={() => setTab(3)}>
              <PanoramaHorizontalIcon color={`${response.tab === 3 && "primary"}`} fontSize="small" />
              <Typography color={`${response.tab === 3 && "primary"}`} style={{ fontSize: "10px" }}>
                Question
              </Typography>
            </Box>
            <Box className="mini-survey-aside" onClick={() => setTab(2)}>
              <PermIdentityIcon fontSize="small" color={`${response.tab === 2 && "primary"}`} />
              <Typography color={`${response.tab === 2 && "primary"}`} style={{ fontSize: "10px" }}>
                Individual
              </Typography>
            </Box>
            <Box className="mini-survey-aside" onClick={() => setTab(1)}>
              <CheckBoxOutlineBlankIcon fontSize="small" color={`${response.tab === 1 && "primary"}`} />
              <Typography color={`${response.tab === 1 && "primary"}`} style={{ fontSize: "10px" }}>
                Overall
              </Typography>
            </Box>
            <Box className="mini-survey-aside">
              <StarsIcon fontSize="small" />
              <Typography style={{ fontSize: "10px" }}>Response</Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box display={{ xs: "block", md: "none" }}>
          <Grid container direction="row" justifyContent="space-between" className="survey-aside-sm">
            <Grid item xs={8} container direction="row">
              {survey.forms &&
                survey.forms.map((page, index) => (
                  <div
                    className={`aside-block-items ${survey.currentPage === page.page ? "at" : ""}`}
                    key={index}
                    onClick={() => setCurrentPage(page.page)}
                  >
                    <InsertDriveFileOutlinedIcon color="primary" style={{ fontSize: "35px", margin: "0px 5px" }} />
                  </div>
                ))}

              <NoteAddOutlinedIcon
                style={{ fontSize: "35px", margin: " 5px", cursor: "pointer" }}
                onClick={() => addPage()}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "4px", textAlign: "right" }}
                size="small"
                onClick={() => {
                  survey.editSlug ? postEditedTemplate() : publishSurvey();
                }}
              >
                <span className="btn-text">
                  <CheckCircleOutlineOutlinedIcon /> Publish
                </span>
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  survey: state.survey,
  response: state.response,
});
export default withRouter(
  connect(mapStateToProps, {
    addQs,
    postSurvey,
    onCoverChange,
    addPage,
    setCurrentPage,
    postAttachment,
    setTab,
    getTab,
    publishSurvey,
    postEditedSurvey,
    onMainTitleChange,
    editSurvey,
    loadTemplateForSurvey,
    postEditedTemplate,
  })(EditTemplate)
);
