import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Radio,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  FormControlLabel,
  TableHead,
} from "@material-ui/core";
import { addResponse } from "../../redux/response/ResponseAction";
import { useDispatch } from "react-redux";

const RadioQs = ({ data, rowIndex, rowLabel, addFLexRadioResponse }) => {
  const [value, setValue] = useState();

  const handleChange = (e, idx) => {
    setValue(idx);
    let responseObject = {
      row: rowLabel,
      column: e.target.value,
    };
    addFLexRadioResponse(responseObject);
  };

  return (
    <React.Fragment>
      {data &&
        data.list2 &&
        data.list2.map((column, idx) => {
          return (
            <TableCell align="center" key={idx} className="fc-flex-preview">
              <FormControlLabel
                value={column.label}
                name={idx + rowIndex}
                control={
                  <Radio
                    color="primary"
                    style={{
                      transform: "scale(0.8)",
                      marginRight: "5px",
                    }}
                    checked={value === idx}
                    onChange={(e) => handleChange(e, idx)}
                    size="small"
                  />
                }
              />
            </TableCell>
          );
        })}
    </React.Fragment>
  );
};

const CheckBoxQs = ({ data, addFlexCheckboxResponse, rowLabel }) => {
  const onCheckBoxChange = (e) => {
    const checked = e.target.checked;
    const value = e.target.value;
    addFlexCheckboxResponse({ row: rowLabel, column: value }, checked);
  };
  return (
    <React.Fragment>
      {data &&
        data.list2 &&
        data.list2.map((column, idx) => {
          return (
            <TableCell align="center" key={idx} className="fc-flex-preview">
              <Checkbox
                color="primary"
                value={column.label}
                style={{
                  transform: "scale(0.8)",
                  marginRight: "15px",
                }}
                onChange={onCheckBoxChange}
                size="small"
              />
            </TableCell>
          );
        })}
    </React.Fragment>
  );
};

const FormPreviewFlexQs = ({ qtype, uId, data, required }) => {
  const dispatch = useDispatch();

  const [response, setResponse] = useState("");

  const addFLexRadioResponse = (object) => {
    if (response.length > 0) {
      let defaultResponse = response;
      let rowItem = defaultResponse.find((item) => item.row === object.row);
      if (rowItem) {
        let index = defaultResponse.findIndex(
          (item) => item.row === object.row
        );
        defaultResponse[index] = object;
        setResponse([...defaultResponse]);
      } else {
        setResponse([...response, object]);
      }
    } else {
      setResponse([...response, object]);
    }
  };

  const addFlexCheckboxResponse = (object, checked) => {
    let defaultResponse = response;
    if (checked) {
      setResponse([...defaultResponse, object]);
    } else {
      let filteredResponse = defaultResponse.filter(
        (item) => item.row !== object.row || item.column !== object.column
      );
      if (filteredResponse.length === 0) {
        setResponse("");
      } else setResponse([...filteredResponse]);
    }
  };

  useEffect(() => {
    dispatch(addResponse(uId, response, qtype, required));
  }, [response]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {data &&
              data.list2 &&
              data.list2.map((column, idx) => {
                return (
                  <TableCell align="center" key={idx}>
                    {column.label}
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.list1 &&
            data.list1.map((row, index) => {
              return (
                <TableRow key={index} hover={false}>
                  <TableCell scope="row">{row.label}</TableCell>
                  {qtype === "radio" ? (
                    <RadioQs
                      rowIndex={index}
                      data={data}
                      rowLabel={row.label}
                      addFLexRadioResponse={addFLexRadioResponse}
                    />
                  ) : (
                    <CheckBoxQs
                      rowIndex={index}
                      data={data}
                      rowLabel={row.label}
                      addFlexCheckboxResponse={addFlexCheckboxResponse}
                    />
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default FormPreviewFlexQs;
