import React from "react";
import ResponsePreviewQs from "./ResponsePreviewQs";
import ResponsePreviewFlexQs from "./ResponsePreviewFlexQs";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Box, Grid, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import NumberScaleRating from "../number-scale/NumberScaleRating";
import { addResponse } from "../../redux/response/ResponseAction";
import { useDispatch } from "react-redux";

const RatingScale = ({ data, uId }) => {
  const dispatch = useDispatch();
  const handleMouse = () => {};

  const handleClicked = (val) => {
    dispatch(addResponse(uId, val, "rating"));
  };
  return (
    <Box margin="15px 25px">
      {data.type === "star" ? (
        <Rating
          size="large"
          name="rating"
          defaultValue={2}
          emptyIcon={<StarBorderIcon fontSize="large" />}
          icon={<StarIcon fontSize="large" />}
          max={Number(data.number)}
          onChange={(e) => handleClicked(e.target.value)}
        />
      ) : (
        <NumberScaleRating
          start={1}
          end={Number(data.number)}
          current={1}
          mouseEnteredOn={handleMouse}
          clickedOn={handleClicked}
        />
      )}
    </Box>
  );
};

const ResponsePreviewCard = ({ type, uId, data, qVal }) => {
  if (type === "Multiple Choice") {
    return (
      <Box margin="5px 20px" display="flex">
        <Typography color="primary" style={{ fontWeight: 500 }}>
          Selected value :
        </Typography>{" "}
        <Typography style={{ fontWeight: 500, marginLeft: "10px" }}>{data}</Typography>
      </Box>
    );
  } else if (type === "Checkbox") {
    return (
      <Box margin="5px">
        <ResponsePreviewQs qtype="checkbox" uId={uId} data={data} />
      </Box>
    );
  } else if (type === "Dropdown") {
    return (
      <Box margin="5px 20px" display="flex">
        <Typography color="primary" style={{ fontWeight: 500 }}>
          Dropdown selected value:{" "}
        </Typography>
        <Typography style={{ fontWeight: 500, marginLeft: "10px" }}>{data}</Typography>
      </Box>
    );
  } else if (type === "Range Slider") {
    return (
      <Box margin="5px 20px">
        <Grid container justifyContent="space-between">
          <Grid item display="flex">
            <Typography color="primary" style={{ fontWeight: 500 }}>
              Maximum value:{" "}
            </Typography>{" "}
            <Typography style={{ fontWeight: 500 }}> {data.max}</Typography>
          </Grid>
          <Grid item display="flex">
            <Typography color="primary" style={{ fontWeight: 500 }}>
              Minimum value:{" "}
            </Typography>{" "}
            <Typography style={{ fontWeight: 500 }}> {data.min}</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  } else if (type === "Rating Scale") {
    return <RatingScale data={data} uId={uId} />;
  } else if (type === "Text") {
    return (
      <Box margin="5px 15px" display="flex" flexDirection="row">
        <Typography color="primary" style={{ fontWeight: 500 }}>
          Answer:{" "}
        </Typography>{" "}
        <Typography style={{ fontWeight: 500, marginLeft: "10px" }}> {data}</Typography>
      </Box>
    );
  } else if (type === "Date & Time") {
    return (
      <Box margin="5px">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justifyContent="space-around">
            <Grid item>
              <span>Date : {data.date}</span>
            </Grid>
            <Grid item>
              <span>Time : {data.time}</span>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </Box>
    );
  } else if (type === "M-Multiple choice") {
    return (
      <Box margin="5px">
        <ResponsePreviewFlexQs qtype="radio" uId={uId} data={data} qVal={qVal} />
      </Box>
    );
  } else if (type === "M-Checkbox") {
    return (
      <Box margin="5px">
        <ResponsePreviewFlexQs qtype="checkbox" uId={uId} data={data} qVal={qVal} />
      </Box>
    );
  } else {
    return <div>Unsupported</div>;
  }
};

export default ResponsePreviewCard;
