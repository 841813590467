import React from "react";
import { Checkbox, Radio, Box, FormControlLabel } from "@material-ui/core";
import { connect } from "react-redux";

const ResponsePreviewFlexQs = ({ qtype, qVal, data }) => {
  return (
    <Box marginLeft={2}>
      {qVal.list1.map((row) => {
        return (
          <React.Fragment>
            <span>{row.label}</span>
            <Box marginLeft={1}>
              {data.map((item) => {
                if (item.row === row.label) {
                  if (qtype === "checkbox") {
                    return (
                      <FormControlLabel
                        value={item.column}
                        label={item.column}
                        control={
                          <Checkbox
                            color="primary"
                            value={item.column}
                            style={{
                              transform: "scale(0.8)",
                            }}
                            disabled
                            defaultChecked={true}
                            size="small"
                          />
                        }
                      />
                    );
                  } else {
                    return (
                      <FormControlLabel
                        value={item.column}
                        label={item.column}
                        control={
                          <Radio
                            color="primary"
                            style={{
                              transform: "scale(0.8)",
                            }}
                            defaultChecked={true}
                            disabled
                            size="small"
                          />
                        }
                      />
                    );
                  }
                } else {
                  <span>No response found</span>;
                }
              })}
            </Box>
          </React.Fragment>
        );
      })}
    </Box>
  );
};
const mapStateToProps = (state) => ({
  survey: state.survey,
});
export default connect(mapStateToProps)(ResponsePreviewFlexQs);
