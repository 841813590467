import React, { useEffect, useState } from "react";
import ResponseCard from "../../components/response-cards/ResponseCard";
import SliderComponent from "../../components/response-header/slider/Slider";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
import QuestionSelect from "../../components/response-header/question-select/QuestionSelect";
import IndividualSlider from "../../components/response-header/individual-slider/IndividualSlider";
import ResponsePreviewCard from "../../components/individual-response-card/ResponsePreviewCard";

const Response = ({ response, survey }) => {
  const [qVal, setQval] = useState();
  const [individualResponseId, setIndividualResponseId] = useState(0);

  useEffect(() => {
    concatForms();
    setQval(survey.forms[0].forms[0]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const concatForms = () => {
    let p = [];
    survey.forms.map((el, i) => {
      el.forms.map((element) => {
        p.push(element);
      });
    });
    return p;
  };

  const getQuestionIndex = () => {
    let ar = concatForms();
    let index = ar.findIndex((el) => el.id === qVal.id);
    return index;
  };

  const findQuestion = (id) => {
    let object;
    if (survey.forms.length === 1) {
      survey.forms.forEach((el, i) => {
        object = el.forms.find((item) => item.id === id);
      });
    } else {
      survey.forms.forEach((el, i) => {
        el.forms.forEach((item) => {
          if (item.id === id) {
            object = item;
          }
        });
      });
    }
    return object;
  };

  return (
    <div className="responseContainer">
      <Box
        style={{
          background: "white",
          height: "80px",
          padding: "15px",
          borderBottom: "10px solid #0085a4",
        }}
      >
        <Box style={{ padding: "0px 10px", margin: "0px 5px" }}>
          {response.tab === 1 ? (
            <SliderComponent qList={concatForms()} qVal={qVal} setQval={setQval} />
          ) : response.tab === 2 ? (
            <IndividualSlider
              individualResponseId={individualResponseId}
              setIndividualResponseId={setIndividualResponseId}
              response={response.uResponse}
            />
          ) : (
            <QuestionSelect qList={concatForms()} qVal={qVal} setQval={setQval} />
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {qVal &&
          (response.tab !== 2 ? (
            <ResponseCard
              type={qVal.selectedDropdown}
              response={response.uResponse}
              question={qVal.question}
              qVal={qVal}
              qindex={getQuestionIndex() + 1}
            />
          ) : (
            <Box className="form-cards">
              {response.uResponse.length > 0 ? (
                response.uResponse[individualResponseId].response.map((res, index) => {
                  return (
                    <Box className="fc-block-preview" key={index}>
                      <Box className="fc-block-header">
                        <h6 className="fc-preview-title">
                          Q{index + 1}.{" "}
                          {findQuestion(res.id).question === undefined
                            ? "Question to be added"
                            : findQuestion(res.id).question}
                        </h6>
                      </Box>
                      <Box marginX="20px">
                        <ResponsePreviewCard
                          type={findQuestion(res.id).selectedDropdown}
                          qVal={findQuestion(res.id).formFields}
                          data={res.response}
                        />
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <Box className="response-card-container" justifyContent="center" alignItems="center">
                  <Box padding={4} borderLeft="5px solid rgba(0, 133, 164, 0.9)">
                    <h5 style={{ textDecoration: "italic" }}>No responses to generate</h5>
                  </Box>
                </Box>
              )}
            </Box>
          ))}
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  response: state.response,
  survey: state.survey,
});
export default connect(mapStateToProps)(Response);
