import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { getSession } from "./redux/auth/AuthActions";
import { Switch } from "react-router-dom";
import ResetPass from "./pages/auth/ResetPass";
import ChangePassConfirm from "./pages/auth/ChangePassConfirm";
import ResetPassConfirm from "./pages/auth/ResetPassConfirm";
import PrivateRoute from "./layout/private-route/PrivateRoute";
import PublicRoute from "./layout/public-route/PublicRoute";
import VerifyEmail from "./pages/auth/VerifyEmail";
import RegisterPage from "./pages/auth/Register";
import { ThemeProvider } from "@material-ui/core";
import theme from "./utils/theme";
import EditSurvey from "./pages/edit-survey/EditSurvey";
import Survey from "./pages/survey/Survey";
import Workplace from "./pages/workplace/Workplace";
import LoginPage from "./pages/auth/Login";
import AuthNav from "./layout/navbar/auth/AuthNav";
import SurveyPreviewWithId from "./pages/survey-preview/SurveyPreviewWithId";
import ResponseSuccess from "./pages/response-success/ResponseSuccess";
import ResponseSurvey from "./pages/response-survey/ResponseSurvey";
import TemplatePreviewWithId from "./pages/template-preview/TemplatePreviewWithId";
import CreateTemplateForm from "./pages/create-template-form/CreateTemplateForm";
import EditTemplate from "./pages/edit-template/EditTemplate";
import Pricing from "./pages/pricing/Pricing";
import Payment from "./pages/payment/Payment";
import ProfilePage from "./pages/profile/ProfilePage";
import { getUser } from "./redux/user/UserActions";

const App = ({ getSession, getUser, auth }) => {
  useEffect(() => {
    getSession();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route
          exact
          path="/form/:id"
          render={(props) => (
            <div {...props}>
              <AuthNav />
              <SurveyPreviewWithId />
            </div>
          )}
        ></Route>
        <Route
          exact
          path="/template/:id"
          render={(props) => (
            <div {...props}>
              <AuthNav />
              <TemplatePreviewWithId />
            </div>
          )}
        ></Route>
        <Route
          exact
          path="/response-success"
          render={(props) => (
            <div {...props}>
              <AuthNav /> <ResponseSuccess />
            </div>
          )}
        ></Route>
        <PublicRoute exact path="/login" component={LoginPage} />
        <PublicRoute exact path="/register" component={RegisterPage}></PublicRoute>
        <PublicRoute exact path="/reset-password" component={ResetPass}></PublicRoute>
        <PublicRoute exact path="/reset-password-confirm/:token" component={ResetPassConfirm}></PublicRoute>
        <PublicRoute exact path="/verify-email" component={VerifyEmail}></PublicRoute>
        <PrivateRoute exact path="/create-form" component={Survey} surveyPage={true}></PrivateRoute>
        <PrivateRoute exact path={`/create-template/:name`} component={Survey} surveyPage={true}></PrivateRoute>
        <PrivateRoute exact path={`/template/edit/:id`} component={EditTemplate} surveyPage={true}></PrivateRoute>
        <PrivateRoute path="/template/create-form/:id" surveyPage={true} component={CreateTemplateForm}></PrivateRoute>
        <PrivateRoute path="/edit-form/:id" surveyPage={true} component={EditSurvey}></PrivateRoute>
        <PrivateRoute path="/response/:id" surveyPage={true} component={ResponseSurvey}></PrivateRoute>
        <PrivateRoute path="/pricing" component={Pricing}></PrivateRoute>
        <PrivateRoute path="/payment/:name" component={Payment}></PrivateRoute>
        <PrivateRoute path="/profile" component={ProfilePage}></PrivateRoute>
        <PrivateRoute exact path="/change-password" component={ChangePassConfirm}></PrivateRoute>
        <PrivateRoute exact path="/" component={Workplace}></PrivateRoute>
      </Switch>
      {/*<Route exact path="" component={Pages} container></Route>*/}
      <ToastContainer />
    </ThemeProvider>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { getSession, getUser })(App);
