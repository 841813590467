import { Typography } from "@material-ui/core";
import React from "react";
import Slider from "react-slick";

const SliderComponent = ({ qList, qVal, setQval }) => {
  const settings = {
    speed: 500,
    slidesToShow: qList.length > 5 ? 5 : qList.length,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <Slider {...settings}>
      {qList &&
        qList.map((item, index) => (
          <div key={index}>
            <div
              className={`slick-item ${
                qVal !== undefined && item.selectedDropdown === qVal.selectedDropdown ? "slick-clicked" : ""
              }`}
              onClick={() => setQval(item)}
            >
              <Typography style={{ color: "#0085a4" }}></Typography>
              <span className="slick-item-text">{item.selectedDropdown}</span>
            </div>
          </div>
        ))}
    </Slider>
  );
};

export default SliderComponent;
