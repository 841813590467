import { url } from "../Utils";
import {
  ADD_RESPONSE,
  LOADING,
  SET_IP,
  SET_SLUG,
  SET_TAB,
  SUBMIT_RESPONSE,
  GET_RESPONSE,
  GET_SENTI,
  GET_WORD_CLOUD,
  GET_ALL_RESPONSE,
  RESET_RESPONSE,
} from "./ResponseType";
import publicIp from "public-ip";
import { toast } from "react-toastify";

export const getPreviewSlug = (slug) => {
  return {
    type: SET_SLUG,
    payload: slug,
  };
};

export const addResponse = (id, response, type, req) => {
  return {
    type: ADD_RESPONSE,
    payload: { id, response, type, req },
  };
};

export const resetResponse = () => {
  return {
    type: RESET_RESPONSE,
  };
};

export const postResponse = (history) => (dispatch, getState) => {
  const state = getState();
  const { response, slug, ip } = state.response;

  let foundRequiredEmpty = response.find(
    (item) =>
      (item.required === true && item.response === []) ||
      (item.required === true && item.response === "") ||
      (item.required === true && item.response === null)
  );

  if (foundRequiredEmpty === undefined) {
    dispatch(loading);
    const postBody = {
      form: slug,
      user_ip: ip,
      response: response,
      survey_time: new Date(),
    };
    fetch(url + "survey_answer_collect/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postBody),
      credentials: "include",
    })
      .then((res) => {
        dispatch({
          type: SUBMIT_RESPONSE,
        });
        history.push("/response-success");
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    console.log(foundRequiredEmpty);
    toast.error("Questions marked with * must be answered");
  }
};

export const getAllResponse = () => (dispatch) => {
  dispatch(loading());
  fetch(url + "response/", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("accessToken")}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch({
        type: GET_ALL_RESPONSE,
        payload: res,
      });
    })
    .catch((err) => console.log(err));
};

export const getResponseById = (id) => (dispatch) => {
  dispatch(loading());
  fetch(url + `response?form=${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("accessToken")}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch({
        type: GET_RESPONSE,
        payload: res,
      });
    })
    .catch((err) => console.log(err));
};

export const getClientIp = () => {
  return async (dispatch) => {
    let ip = await publicIp.v4({
      fallbackUrls: ["https://ifconfig.co/ip"],
    });
    dispatch({
      type: SET_IP,
      payload: ip,
    });
  };
};

export const getSenti = (id) => (dispatch) => {
  dispatch(loading());
  fetch(url + `sentiment?form=${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("accessToken")}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch({
        type: GET_SENTI,
        payload: res,
      });
    })
    .catch((err) => console.log(err));
};

export const getWordCloud = (id) => (dispatch) => {
  dispatch(loading());
  fetch(url + `wordcloud?form=${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("accessToken")}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch({
        type: GET_WORD_CLOUD,
        payload: res,
      });
    })
    .catch((err) => console.log(err));
};

export const setTab = (number) => {
  return {
    type: SET_TAB,
    payload: number,
  };
};

export const loading = () => {
  return {
    type: LOADING,
  };
};
