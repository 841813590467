import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import AuthNav from "../navbar/auth/AuthNav";
import { isLoggedIn } from "../../redux/Utils";

const PublicRoute = ({ component: Component, auth, exact, ...rest }) => {
  return (
  <Route
    exact={exact ? true : false}
    {...rest}
    render={(props) =>
      !isLoggedIn() ? (
        <div>
        <AuthNav  {...props} {...rest}/>
        <Component {...props} {...rest}></Component>
      </div>
      ) : (
        <Redirect to="/"></Redirect>
      )
    }
  ></Route>
)}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(PublicRoute);
