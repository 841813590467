import { GET_USER } from "./UserTypes";
import { url } from "../Utils";
import { toast } from "react-toastify";
import axios from "axios";

export const getUser = () => (dispatch) => {
  fetch(url + "users/", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("accessToken")}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      dispatch({
        type: GET_USER,
        payload: res,
      });
    })
    .catch((err) => console.log(err));
};


export const deleteSubscription = (id) => (dispatch) => {
  axios.delete(url+`subscription/${id}/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("accessToken")}`,
    },
  })
  .then(res => toast.success("You have unsubscribed from a plan"))
  .catch(err => {
    console.log(err);
  })
}
