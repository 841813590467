import {
  LOADING,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOG_OUT,
  SIGN_UP_FAILED,
  SIGN_UP_SUCCESS,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAILED,
  RESET_PASS,
  RESET_ERROR,
  CHANGE_PASSWORD,
} from "./AuthTypes";
const INITIAL_STATE = {
  user: null,
  error: null,
  loading: false,
  isAuthenticated: null,
};
export default function AuthReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case SIGN_UP_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case AUTHENTICATED_FAILED:
    case AUTHENTICATED_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: action.payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_PASS:
      return {
        ...state,
        loading: false,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case RESET_ERROR:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        loading: false
      }
    case LOG_OUT:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
