import React from "react";
import {
  Box,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  TableBody,
  Typography,
} from "@material-ui/core";

const AnalyticsTable = ({ count, response, labels, noIndex }) => {
  const returnSum = () => {
    var sum = count.reduce(function (a, b) {
      return a + b;
    }, 0);
    return sum;
  };

  return (
    <Box
      style={{ border: "1px solid rgba(142, 142, 147, 0.4)", padding: "15px" }}
    >
      <Typography
        style={{ marginTop: "5px", fontWeight: "500", fontSize: "22px" }}
      >
        Analytics Table
      </Typography>
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell head">Option</TableCell>
              {labels.map((item, index) => (
                <TableCell className="tableCell head" align="center">
                  {!noIndex && index + 1 + "."}
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="tableCell" component="th" scope="row">
                Participants
              </TableCell>
              {labels.map((item, index) => {
                return (
                  <TableCell className="tableCell" align="center">
                    {count.map((el, i) => {
                      if (i === index) {
                        return el;
                      }
                    })}
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell className="tableCell" component="th" scope="row">
                % Responses
              </TableCell>
              {labels.map((item, index) => {
                return (
                  <TableCell className="tableCell" align="center">
                    {count.map((el, i) => {
                      if (i === index) {
                        return (Number(el) * (100 / returnSum())).toFixed(2);
                      }
                    })}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="flex-end">
        <Typography style={{ marginTop: "16px", fontWeight: "500" }}>
          Total Participants : {response.length}
        </Typography>
      </Box>
    </Box>
  );
};
export default AnalyticsTable;
