import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { connect } from "react-redux";
import {useStripe} from "@stripe/react-stripe-js";
import { Box, Typography } from "@material-ui/core";
import { deleteSubscription } from "../../redux/user/UserActions";
import { toast } from "react-toastify";

const boxStyle = {
  container: {
    background: "#FFFFFF",
    margin: "0px 15px",
    padding: "15px 30px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: "4px",
    minHeight: "520px",
    position: "relative",
  },
  price: {
    color: "#231D4F",
    fontSize: "36px",
    fontWeight: "bold",
  },
  heading: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "29px",
    color: "#231D4",
    marginTop: "20px",
  },
  desc: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#848199",
    margin: "15px 0px",
  },
  lists: {
    color: "#000000DE",
    fontSize: "18px",
    margin: "0px 7px",
  },
};

const PricingBox = ({ active, pricing, desc, heading, lists, deleteSubscription, subscriptionId, user, }) => {
  const stripe = useStripe();

  const onCheckout = async (priceId, mode) => {
    console.log(priceId);
    const {success, error} = await stripe.redirectToCheckout({
      lineItems : [{
        price : priceId,
        quantity: 1,
      }],
      mode: mode,
      clientReferenceId: user.id,
      customerEmail: user.email,
      successUrl: process.env.NODE_ENV === "development" ? "http://localhost:3000/" : "url",
      cancelUrl: process.env.NODE_ENV === "development" ? "http://localhost:3000/pricing" : "url"
    })
    if(error){
      toast.error("Payment Unsuccessful");
    }
  }

  return (
    <Box className="price-plan-container" border={active === heading ? "2px solid #0085a4" : ""}>
      <Typography>
        <span style={boxStyle.price}>${pricing.month}</span> 
      </Typography>
      <Typography style={boxStyle.heading}>{heading}</Typography>
      <Typography style={boxStyle.desc}>{desc}</Typography>
      <Box paddingTop="12px">
        {lists.map((item, key) => (
          <Box display="flex" marginY="15px" key={key}>
            <CheckCircleIcon color="primary" />
            <Typography style={boxStyle.lists}>{item}</Typography>
          </Box>
        ))}
      </Box>
      <Box marginX="15px">
        {
          heading === "Free" ? 
          <button 
            className="price-plan-btn active" 
            style={{ background : active === heading ? "#0085a4" : "", color: active === heading ? "#ffffff" : "" }}
            onClick={() => {deleteSubscription(subscriptionId)}}
            >
              Choose Free
            </button>
          :
          <a 
            href="#checkout" 
            target="_blank"
            className="links price-plan-btn" 
            onClick={(ev) => {ev.preventDefault(); onCheckout(pricing.id, pricing.mode);}} 
            style={{ background : active === heading ? "#0085a4" : "", color: active === heading ? "#ffffff" : "", cursor: "pointer" }}>
            {active === heading ? "Current plan" : `Choose ${heading}`}
          </a>
        }
        
      </Box>
    </Box>
  );
};

export default connect(null, {deleteSubscription})(PricingBox);
