import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import GG from "../../assets/images/socials/Google.png";
import LI from "../../assets/images/socials/Linkedin.png";
import FB from "../../assets/images/socials/facebook.png";
import Logo from "../../assets/images/icons/logo.png";
import Sally1 from "../../assets/images/svg/Saly-22.svg";
import Sally2 from "../../assets/images/svg/Saly-34.svg";
import Sally3 from "../../assets/images/svg/Saly-35.svg";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { LinkedIn } from "react-linkedin-login-oauth2";

const ResponseSuccess = () => {
  const media = useMediaQuery("(max-width:720px)");
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      marginTop={5}
    >
      <Box
        display="flex"
        flexDirection="row"
        width="45vw"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <img className="response-success-svg" src={Sally1} alt=""></img>
        </Box>
        <Box>
          <img className="response-success-svg" src={Sally2} alt=""></img>
        </Box>
        <Box>
          <img className="response-success-svg" src={Sally3} alt=""></img>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        style={{ margin: "25px 0px 10px" }}
      >
        <CheckBoxIcon
          color="primary"
          style={{
            height: `${media ? "2.5vw" : "35px"}`,
            width: `${media ? "2.5vw" : "35px"}`,
          }}
        />{" "}
        <p
          style={{
            fontWeight: "500",
            marginLeft: `${media ? "5px" : "15px"}`,
            fontSize: `${media ? "2.0vw" : "20px"}`,
          }}
        >
          Your response is successfully submitted.
        </p>
      </Box>
      <Typography
        color="#636366"
        style={{
          width: "40vw",
          textAlign: "center",
          fontSize: `${media ? "2.0vw" : "14px"}`,
        }}
      >
        Get the power to take your survey experience to the next level.
        RevSurvey is the best survey analytics you never experienced before.
      </Typography>
      <div className="social-container">
        <div
          className="social-login-subtile-container"
          style={{ width: "50vw", marginTop: "20px" }}
        >
          <div></div>
          <p style={{ fontSize: `${media ? "1.2vw" : "16px"}` }}>
            Or Signup with
          </p>
          <div></div>
        </div>
        <div className="social-icon-container">
          <div className="socials">
            <img className="socials-logo" src={FB} alt="socials"></img>
          </div>
          <div className="socials">
            <img className="socials-logo" src={GG} alt="socials" />
          </div>
          <div className="socials">
            <LinkedIn
              clientId="886tj59mak0ircn"
              //onFailure={onFailure}
              //onSuccess={onSuccess}
              //redirectUri="http://localhost:3000/"
            >
              <img className="socials-logo" src={LI} alt="socials" />
            </LinkedIn>
          </div>
        </div>
      </div>
      <div className="form-footer">
        <img src={Logo} alt="logo"></img>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="form-footer-grid"
        >
          <Grid item>
            <p className="footer-item">About RevSurvet</p>
          </Grid>
          <Grid item>
            <p className="footer-item">Privacy Notice</p>
          </Grid>
          <Grid item>
            <p className="footer-item">Condition of use</p>
          </Grid>
          <Grid item>
            <p className="footer-item">Need help?</p>
          </Grid>
        </Grid>
        <p className="footer-item"> Copyright &copy; 2021 RevSurvey</p>
      </div>
    </Box>
  );
};

export default ResponseSuccess;
