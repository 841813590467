import React, { useState } from "react";
import { Grid, Box } from "@material-ui/core";
import TabBar from "../../assets/images/icons/tab-bar.png";
import TabBarActive from "../../assets/images/icons/tab-bar-active.png";
import TabLine from "../../assets/images/icons/tab-line.png";
import TabLineActive from "../../assets/images/icons/tab-line-active.png";
import TabDoughnut from "../../assets/images/icons/tab-doughnut.png";
import TabDoughnutActive from "../../assets/images/icons/tab-dougnut-active.png";
import TabPie from "../../assets/images/icons/tab-pie.png";
import TabPieActive from "../../assets/images/icons/tab-pie-active.png";
import GreenTick from "../../assets/images/icons/green-tick-fill.png";

const RegistrationTab = () => {
  const [tab1, setTab1] = useState("1");
  return (
    <Box
      component={Grid}
      item
      lg={6}
      display={{ xs: "none", md: "none", lg: "block" }}
    >
      {tab1 === "1" && (
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          className="register-tab-1"
        >
          <div className="registration-tab-header">
            <h5>
              Get the power to take your survey experience to the next
              level.
            </h5>
          </div>
          <Grid container direction="row" className="tab-header-container">
            <div
              className={`tab-header ${tab1 === "1" ? "active" : ""}`}
              onClick={() => setTab1("1")}
            >
              <div className="tab-logo-container">
                <img alt="" src={tab1 === "1" ? TabBarActive : TabBar}></img>
              </div>
              <span>
                Build <br /> FeedBack
              </span>
            </div>
            <div
              className={`tab-header ${tab1 === "2" ? "active" : ""}`}
              onClick={() => setTab1("2")}
            >
              <div className="tab-logo-container">
                <img alt="" src={tab1 === "2" ? TabLineActive : TabLine}></img>
              </div>
              <span>
                Data <br /> Visualization
              </span>
            </div>
            <div
              className={`tab-header ${tab1 === "3" ? "active" : ""}`}
              onClick={() => setTab1("3")}
            >
              <div className="tab-logo-container">
                <img
                  alt=""
                  src={tab1 === "3" ? TabDoughnutActive : TabDoughnut}
                ></img>
              </div>
              <span>
                Report <br /> Analytics
              </span>
            </div>
            <div
              className={`tab-header ${tab1 === "4" ? "active" : ""}`}
              onClick={() => setTab1("4")}
            >
              <div className="tab-logo-container">
                <img alt="" src={tab1 === "4" ? TabPieActive : TabPie}></img>
              </div>
              <span>
                Pricing <br /> Offer
              </span>
            </div>
          </Grid>
          <div className="registration-tab-activity-list">
            <ul className="activity-list-container">
              <li className="activity-item">
                <div className="activity-logo-container">
                  <img alt="" src={GreenTick}></img>
                </div>
                <p className="activity-text">
                  Lorem Ipsum is simply dummy text of the printing.
                </p>
              </li>
              <li className="activity-item">
                <div className="activity-logo-container">
                  <img alt="" src={GreenTick}></img>
                </div>
                <p className="activity-text">
                  Lorem Ipsum is simply dummy text of the printing.
                </p>
              </li>
              <li className="activity-item">
                <div className="activity-logo-container">
                  <img alt="" src={GreenTick}></img>
                </div>
                <p className="activity-text">
                  Lorem Ipsum is simply dummy text of the printing.
                </p>
              </li>
              <li className="activity-item">
                <div className="activity-logo-container">
                  <img alt="" src={GreenTick}></img>
                </div>
                <p className="activity-text">
                  Lorem Ipsum is simply dummy text of the printing.
                </p>
              </li>
            </ul>
          </div>
        </Grid>
      )}
      {tab1 === "2" && (
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          className="register-tab-2"
        >
          <div className="registration-tab-header">
            <h5>
              Get the power to take your survey experience to the <br /> next
              level.
            </h5>
          </div>
          <Grid container direction="row" className="tab-header-container">
            <div
              className={`tab-header ${tab1 === "1" ? "active" : ""}`}
              onClick={() => setTab1("1")}
            >
              <div className="tab-logo-container">
                <img alt="" src={tab1 === "1" ? TabBarActive : TabBar}></img>
              </div>
              <span>
                Build <br /> FeedBack
              </span>
            </div>
            <div
              className={`tab-header ${tab1 === "2" ? "active" : ""}`}
              onClick={() => setTab1("2")}
            >
              <div className="tab-logo-container">
                <img alt="" src={tab1 === "2" ? TabLineActive : TabLine}></img>
              </div>
              <span>
                Data <br /> Visualization
              </span>
            </div>
            <div className="tab-header" onClick={() => setTab1("3")}>
              <div className="tab-logo-container">
                <img
                  alt=""
                  src={tab1 === "3" ? TabDoughnutActive : TabDoughnut}
                ></img>
              </div>
              <span>
                Report <br /> Analytics
              </span>
            </div>
            <div
              className={`tab-header ${tab1 === "4" ? "active" : ""}`}
              onClick={() => setTab1("4")}
            >
              <div className="tab-logo-container">
                <img alt="" src={tab1 === "4" ? TabPieActive : TabPie}></img>
              </div>
              <span>
                Pricing <br /> Offer
              </span>
            </div>
          </Grid>
          <div className="registration-tab-activity-list">
            <ul className="activity-list-container">
              <li className="activity-item">
                <div className="activity-logo-container">
                  <img alt="" src={GreenTick}></img>
                </div>
                <p className="activity-text">
                  Lorem Ipsum is simply dummy text of the printing.
                </p>
              </li>
              <li className="activity-item">
                <div className="activity-logo-container">
                  <img alt="" src={GreenTick}></img>
                </div>
                <p className="activity-text">
                  Lorem Ipsum is simply dummy text of the printing.
                </p>
              </li>
              <li className="activity-item">
                <div className="activity-logo-container">
                  <img alt="" src={GreenTick}></img>
                </div>
                <p className="activity-text">
                  Lorem Ipsum is simply dummy text of the printing.
                </p>
              </li>
              <li className="activity-item">
                <div className="activity-logo-container">
                  <img alt="" src={GreenTick}></img>
                </div>
                <p className="activity-text">
                  Lorem Ipsum is simply dummy text of the printing.
                </p>
              </li>
            </ul>
          </div>
        </Grid>
      )}
      {tab1 === "3" && (
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          className="register-tab-3"
        >
          <div className="registration-tab-header">
            <h5>
              Get the power to take your survey experience to the <br /> next
              level.
            </h5>
          </div>
          <Grid container direction="row" className="tab-header-container">
            <div
              className={`tab-header ${tab1 === "1" ? "active" : ""}`}
              onClick={() => setTab1("1")}
            >
              <div className="tab-logo-container">
                <img alt="" src={tab1 === "1" ? TabBarActive : TabBar}></img>
              </div>
              <span>
                Build <br /> FeedBack
              </span>
            </div>
            <div
              className={`tab-header ${tab1 === "2" ? "active" : ""}`}
              onClick={() => setTab1("2")}
            >
              <div className="tab-logo-container">
                <img alt="" src={tab1 === "2" ? TabLineActive : TabLine}></img>
              </div>
              <span>
                Data <br /> Visualization
              </span>
            </div>
            <div
              className={`tab-header ${tab1 === "3" ? "active" : ""}`}
              onClick={() => setTab1("3")}
            >
              <div className="tab-logo-container">
                <img
                  alt=""
                  src={tab1 === "3" ? TabDoughnutActive : TabDoughnut}
                ></img>
              </div>
              <span>
                Report <br /> Analytics
              </span>
            </div>
            <div
              className={`tab-header ${tab1 === "4" ? "active" : ""}`}
              onClick={() => setTab1("4")}
            >
              <div className="tab-logo-container">
                <img alt="" src={tab1 === "4" ? TabPieActive : TabPie}></img>
              </div>
              <span>
                Pricing <br /> Offer
              </span>
            </div>
          </Grid>
          <div className="registration-tab-activity-list">
            <ul className="activity-list-container">
              <li className="activity-item">
                <div className="activity-logo-container">
                  <img alt="" src={GreenTick}></img>
                </div>
                <p className="activity-text">
                  Lorem Ipsum is simply dummy text of the printing.
                </p>
              </li>
              <li className="activity-item">
                <div className="activity-logo-container">
                  <img alt="" src={GreenTick}></img>
                </div>
                <p className="activity-text">
                  Lorem Ipsum is simply dummy text of the printing.
                </p>
              </li>
              <li className="activity-item">
                <div className="activity-logo-container">
                  <img alt="" src={GreenTick}></img>
                </div>
                <p className="activity-text">
                  Lorem Ipsum is simply dummy text of the printing.
                </p>
              </li>
              <li className="activity-item">
                <div className="activity-logo-container">
                  <img alt="" src={GreenTick}></img>
                </div>
                <p className="activity-text">
                  Lorem Ipsum is simply dummy text of the printing.
                </p>
              </li>
            </ul>
          </div>
        </Grid>
      )}
      {tab1 === "4" && (
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          className="register-tab-4"
        >
          <div className="registration-tab-header">
            <h5>
              Get the power to take your survey experience to the <br /> next
              level.
            </h5>
          </div>
          <Grid container direction="row" className="tab-header-container">
            <div
              className={`tab-header ${tab1 === "1" ? "active" : ""}`}
              onClick={() => setTab1("1")}
            >
              <div className="tab-logo-container">
                <img alt="" src={tab1 === "1" ? TabBarActive : TabBar}></img>
              </div>
              <span>
                Build <br /> FeedBack
              </span>
            </div>
            <div
              className={`tab-header ${tab1 === "2" ? "active" : ""}`}
              onClick={() => setTab1("2")}
            >
              <div className="tab-logo-container">
                <img alt="" src={tab1 === "2" ? TabLineActive : TabLine}></img>
              </div>
              <span>
                Data <br /> Visualization
              </span>
            </div>
            <div
              className={`tab-header ${tab1 === "3" ? "active" : ""}`}
              onClick={() => setTab1("3")}
            >
              <div className="tab-logo-container">
                <img
                  alt=""
                  src={tab1 === "3" ? TabDoughnutActive : TabDoughnut}
                ></img>
              </div>
              <span>
                Report <br /> Analytics
              </span>
            </div>
            <div
              className={`tab-header ${tab1 === "4" ? "active" : ""}`}
              onClick={() => setTab1("4")}
            >
              <div className="tab-logo-container">
                <img alt="" src={tab1 === "4" ? TabPieActive : TabPie}></img>
              </div>
              <span>
                Pricing <br /> Offer
              </span>
            </div>
          </Grid>
          <div className="registration-tab-activity-list">
            <ul className="activity-list-container">
              <li className="activity-item">
                <div className="activity-logo-container">
                  <img alt="" src={GreenTick}></img>
                </div>
                <p className="activity-text">
                  Lorem Ipsum is simply dummy text of the printing.
                </p>
              </li>
              <li className="activity-item">
                <div className="activity-logo-container">
                  <img alt="" src={GreenTick}></img>
                </div>
                <p className="activity-text">
                  Lorem Ipsum is simply dummy text of the printing.
                </p>
              </li>
              <li className="activity-item">
                <div className="activity-logo-container">
                  <img alt="" src={GreenTick}></img>
                </div>
                <p className="activity-text">
                  Lorem Ipsum is simply dummy text of the printing.
                </p>
              </li>
              <li className="activity-item">
                <div className="activity-logo-container">
                  <img alt="" src={GreenTick}></img>
                </div>
                <p className="activity-text">
                  Lorem Ipsum is simply dummy text of the printing.
                </p>
              </li>
            </ul>
          </div>
        </Grid>
      )}
    </Box>
  );
};
export default RegistrationTab;
