import React from "react";
import { Checkbox, Radio, Box } from "@material-ui/core";
import { connect } from "react-redux";
import { addResponse } from "../../redux/response/ResponseAction";

const ResponsePreviewQs = ({ qtype, data }) => {
  return (
    <React.Fragment>
      {data.map((item) => {
        return (
          <Box key={item.id}>
            {qtype === "checkbox" ? (
              <Box display="row">
                <Checkbox
                  name="checkedA"
                  color="primary"
                  style={{
                    transform: "scale(0.8)",
                  }}
                  size="small"
                  defaultChecked={true}
                  disabled
                  value={item}
                />
                <span className="qs-preview-label">{item}</span>
              </Box>
            ) : (
              <Box display="row">
                <Radio
                  color="primary"
                  style={{
                    transform: "scale(0.8)",
                  }}
                  value={item.label}
                  checked={true}
                  disabled={true}
                  size="small"
                />
                <span className="qs-preview-label">{item.label}</span>
              </Box>
            )}
          </Box>
        );
      })}
    </React.Fragment>
  );
};
export default connect(null, { addResponse })(ResponsePreviewQs);
