import { combineReducers } from "redux";
import AuthReducer from "./auth/AuthReducer";
import ResponseReducer from "./response/ResponseReducer";
import SurveyReducer from "./survey/SurveyReducer";
import TemplateReducer from "./template/templateReducer";
import UserReducer from "./user/UserReducer";

export default combineReducers({
  auth: AuthReducer,
  survey: SurveyReducer,
  response: ResponseReducer,
  template: TemplateReducer,
  user: UserReducer,
});
