import React from "react";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import {
  CheckBoxBody,
  MutipleChoiceBody,
  DropdownBody,
  RangeSliderBody,
  TextBody,
  RatingScaleBody,
  MCheckBoxBody,
  MMultipleBody,
} from "./BodyComponents";

const ResponseCardBody = ({ type, chartTab, response, qVal }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  if (type === "Checkbox") {
    return <CheckBoxBody chartTab={chartTab} qVal={qVal} response={response} />;
  } else if (type === "Multiple Choice") {
    return (
      <MutipleChoiceBody chartTab={chartTab} qVal={qVal} response={response} />
    );
  } else if (type === "Dropdown") {
    return <DropdownBody chartTab={chartTab} qVal={qVal} response={response} />;
  } else if (type === "Range Slider") {
    return <RangeSliderBody response={response} qVal={qVal} />;
  } else if (type === "Text") {
    return <TextBody qVal={qVal} />;
  } else if (type === "Rating Scale") {
    return (
      <RatingScaleBody
        chartTab={chartTab}
        matches={matches}
        qVal={qVal}
        response={response}
      />
    );
  } else if (type === "M-Checkbox") {
    return (
      <MCheckBoxBody chartTab={chartTab} qVal={qVal} response={response} />
    );
  } else if (type === "M-Multiple choice") {
    return (
      <MMultipleBody chartTab={chartTab} qVal={qVal} response={response} />
    );
  } else if (type === "Date & Time") {
    return (
      <Box margin={2} padding={2}>
        <Typography
          style={{ color: "rgba(153, 153, 153, 1)", fontSize: "24px" }}
        >
          This data is available for individual response
        </Typography>
      </Box>
    );
  }
};

export default ResponseCardBody;
