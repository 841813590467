import React from "react";
import { Box, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { getTab } from "../../../redux/survey/SurveyActions";

const SurveyNav = ({ getTab, survey }) => {
  return (
    <React.Fragment>
      <Box display={{ xs: "block", md: "none" }}>
        <Grid
          container
          direction="row"
          className="survey-nav-tabs-sm"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box component={Grid} item>
            <div
              onClick={() => {
                getTab(1);
              }}
              className={`survey-tabs-item ${
                survey.tab === 1 ? "survey-tabs-active" : ""
              }`}
            >
              <HelpOutlineIcon /> <span>Questionnaire</span>
            </div>
          </Box>
          <Box component={Grid} item>
            <div
              onClick={() => {
                getTab(2);
              }}
              className={`survey-tabs-item ${
                survey.tab === 2 ? "survey-tabs-active" : ""
              }`}
            >
              <VisibilityIcon /> <span>Preview</span>
            </div>
          </Box>
          <Box component={Grid} item>
            <div
              onClick={() => {
                getTab(3);
              }}
              className={`survey-tabs-item ${
                survey.tab === 3 ? "survey-tabs-active" : ""
              }`}
            >
              <AssessmentIcon /> <span>Response</span>
            </div>
          </Box>
        </Grid>
      </Box>
      <Box display={{ xs: "none", md: "block" }}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Box component={Grid} item>
            <div
              onClick={() => getTab(1)}
              className={`survey-tabs-item ${
                survey.tab === 1 ? "survey-tabs-active" : ""
              }`}
            >
              <HelpOutlineIcon /> <span>Questionnaire</span>
            </div>
          </Box>
          <Box component={Grid} item>
            <div
              onClick={() => getTab(2)}
              className={`survey-tabs-item ${
                survey.tab === 2 ? "survey-tabs-active" : ""
              }`}
            >
              <VisibilityIcon /> <span>Preview</span>
            </div>
          </Box>
          <Box component={Grid} item>
            <div
              onClick={() => getTab(3)}
              className={`survey-tabs-item ${
                survey.tab === 3 ? "survey-tabs-active" : ""
              }`}
            >
              <AssessmentIcon /> <span>Response</span>
            </div>
          </Box>
        </Grid>
      </Box>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  survey: state.survey,
});
export default connect(mapStateToProps, { getTab })(SurveyNav);
