import React, { useEffect } from "react";
import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { connect } from "react-redux";
import PricingBox from "../../components/pricing-box/PricingBox";
import { pricingData } from "../../utils/pricingData";
import { getUser } from "../../redux/user/UserActions";

const Pricing = ({ user, getUser }) => {
  const matches = useMediaQuery("(min-width:1500px)");

  useEffect(() => {
    getUser();
  },[])

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Box textAlign="center" marginTop="25px">
        <Typography style={{ fontWeight: "bold", fontSize: "28px" }}>Pricing Plans</Typography>
        <Typography style={{ fontWeight: "400", fontSize: "20px", color: "#00000099", margin: "10px" }}>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        </Typography>
        <br />
      </Box>

      <Box marginX={matches ? 20 : 2} className="pricing-wrapper">
        <Grid container>
          {user.user &&
            pricingData.map((item, key) => (
              <Grid item sm={12} md={6} xxl={3} key={item.id}>
                <PricingBox
                  active={user.user[0].subscriptions_user === null ? "Free" : 
                    user.user[0].subscriptions_user.is_monthly_sub_active ? "Monthly" : 
                    user.user[0].subscriptions_user.is_one_time_sub_active ? "One Time Payment" : 
                    "Yearly"
                  }
                  user={user.user[0]}
                  subscriptionId={user.user[0].subscriptions_user.subscription_id}
                  heading={item.heading}
                  desc={item.desc}
                  pricing={item.pricing}
                  //stripe={item.stripeLink}
                  lists={item.lists}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {getUser})(Pricing);
