import axios from "axios";
import {
  LOADING,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOG_OUT,
  RESET_PASS,
  SIGN_UP_FAILED,
  SIGN_UP_SUCCESS,
  AUTHENTICATED_FAILED,
  AUTHENTICATED_SUCCESS,
  RESET_ERROR,
  CHANGE_PASSWORD,
} from "./AuthTypes";
import { url } from "../Utils";
import { toast } from "react-toastify";

export const registerAction = (newUser) => (dispatch) => {
  dispatch(loading());
  axios
    .post(url + "rest-auth/registration/", newUser)
    .then((res) => {
      dispatch({
        type: SIGN_UP_SUCCESS,
        payload: res.data,
      });
      toast.success(
        "Registration successfully completed, An email has been sent to verify your email account"
      );
    })
    .catch((err) => {
      dispatch({
        type: SIGN_UP_FAILED,
        payload: err.response.data,
      });
    });
};

export const loginAction = (formData, history) => (dispatch) => {
  dispatch(loading());
  fetch(url + "rest-auth/login/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      username: formData.email,
      email: formData.email,
      password: formData.password,
    }),
  })
    .then((res) => isResponseOk(res))
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      localStorage.setItem("accessToken", res.key);
      toast.success("Login successful");
      history.push("/");
    })
    .catch((err) => {
      dispatch({
        type: LOGIN_FAILED,
      });
    });
};

export const resetPass = (email) => (dispatch) => {
  dispatch(loading());
  axios
    .post(url + "rest-auth/password_reset/", email)
    .then((res) => {
      dispatch({
        type: RESET_PASS,
      });
      toast.info("An email has been sent");
    })
    .catch((err) => toast.error("Unable to send email"));
};

export const changePassword = (value, history) => dispatch => {
  dispatch(loading());
  fetch(url + "rest-auth/password/change/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("accessToken")}`,
    },
    credentials: "include",
    body: JSON.stringify(value),
  })
    .then((res) => {
      dispatch({
        type: CHANGE_PASSWORD,
      });    
      if(res.status !== 400){
        toast.success("Successfully changed password");
        history.push("/profile");
      } else{
        toast.error("Invalid current password");
      }
    })
    .catch((err) => {
      console.log(err);
     toast.error("Unable to change password")
    });
}

export const loading = () => {
  return {
    type: LOADING,
  };
};

export const logoutAction = (history) => (dispatch) => {
  localStorage.removeItem("accessToken");

  fetch(url + "rest-auth/logout/", {
    credentials: "include",
  })
    .then((res) => isResponseOk(res))
    .then((res) => {
      dispatch({
        type: LOG_OUT,
      });
    })
    .catch((err) => toast.error(err));

  //getCSRF()
};

export const getSession = () => (dispatch) => {
  dispatch(loading());
  if (localStorage.getItem("accessToken")) {
    dispatch({
      type: AUTHENTICATED_SUCCESS,
      payload: true,
    });
  } else {
    dispatch({
      type: AUTHENTICATED_FAILED,
      payload: false,
    });
    // dispatch(getCSRF());
  }
};

const isResponseOk = (response) => {
  if (response.status >= 200 && response.status <= 299) {
    return response.json();
  } else {
    response.json().then((res) => {
      if (res.non_field_errors[0] === "E-mail is not verified.") {
        toast.error(() => {
          return (
            <div>
              Email is not verified{" "}
              <a className="toastLink links" href="/verify-email">
                Click here
              </a>
            </div>
          );
        });
      } else toast.error(res.non_field_errors[0]);
    });
    //throw Error(response.statusText);
  }
};

export const resetError = () => {
  return {
    type: RESET_ERROR,
  };
};

export const socialLogin = (accessToken, history) => (dispatch) => {
  dispatch(loading());
  const formData = new FormData();
  formData.append("access_token", accessToken);
  fetch(url + "rest-auth/google/", {
    method: "POST",
    body: formData,
  })
    .then((res) => isResponseOk(res))
    .then((res) => {
      localStorage.setItem("accessToken", res.key);
      dispatch({
        type: LOGIN_SUCCESS,
      });
      toast.success("Login successful");
      history.push("/");
    })
    .catch((err) => {
      dispatch({ type: LOGIN_FAILED });
    });
  /*
  if (accessToken) {
    dispatch({
      type: LOGIN_SUCCESS,
    });
    localStorage.setItem("accessToken", accessToken);
    toast.success("Successfully Logged in");
  } else {
    dispatch({
      type: LOGIN_FAILED,
    });
    toast.error("Social Login Failed");
  }
  */
};

/*
export const getCSRF = () => (dispatch) => {
  fetch(url + "get-csrf/", {
    credentials: "include",
  })
    .then((res) => {
      let csrfToken = res.headers.get("X-CSRFToken");
      localStorage.setItem("csrfToken", csrfToken);
    })
    .catch((err) => {
      console.log(err);
    });
};
*/
